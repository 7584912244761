import React, { useState } from "react";
import Logo from "../assests/Logo.jpg";
import { MenuIcon } from "lucide-react";
import AdminDashboardNav from "./AdminDashboardNav";
import AdminMobileNavBar from "./AdminMobileNavbar";

export default function AdminDashboardLayout({ children }) {
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const adminDetailsString = localStorage.getItem("userDetails");
  const adminDetails = adminDetailsString
    ? JSON.parse(adminDetailsString)
    : null;
  const adminEmail = adminDetails ? adminDetails.email : null;
  const adminName = adminDetails
    ? adminDetails.name
    : adminDetails?.email.split("@")[0];
  const adminImgUrl = adminDetails ? adminDetails.picture : "";

  return (
    <div className="relative flex flex-col lg:flex-row  min-h-screen w-full ">
      {/* Dashboard Side Bar  */}

      <div className="z-30">
        <AdminDashboardNav
          isExpanded={isExpanded}
          toggleExpand={toggleExpand}
          adminEmail={adminEmail}
          adminName={adminName}
          adminImgUrl={adminImgUrl}
        />
      </div>

      <div className="sticky top-0 z-50 lg:hidden flex space-x-4 h-[60px] md:h-[90px]  bg-black border-b-[1px] border-[#454646]/60 px-4 ">
        <div
          onClick={toggleMobileNav}
          className=" flex items-center  lg:hidden text-white  cursor-pointer"
        >
          <MenuIcon className="h-10 w-10 text-white " />
        </div>

        {/* <div className="flex items-center space-x-2">
          <div className="w-full flex flex-row justify-center ">
            <img src={Logo} alt="Logo" width={35} className="rounded-md" />
          </div>
          <div className="text-[20px] font-medium font-poppins text-[#f2f2f2] leading-[20px] ">
            {whiteLabelText}
          </div>
        </div> */}

        <div className="flex items-center space-x-2">
          <div className="flex flex-row justify-center ">
            <img
              src={Logo}
              alt="Logo"
              className="rounded-md w-[35px] h-[35px]"
            />
          </div>
          <div className="text-[18px] font-medium font-poppins text-[#f2f2f2] leading-[20px] ">
            {whiteLabelText}
          </div>
        </div>

        {isMobileNavOpen && (
          <AdminMobileNavBar
            isOpen={isMobileNavOpen}
            onClose={toggleMobileNav}
            adminEmail={adminEmail}
            adminName={adminName}
            adminImgUrl={adminImgUrl}
          />
        )}
      </div>

      {/* Main Dashboard  */}
      <main className="  flex flex-1   bg-black overflow-hidden  transition duration-500 ease-in">
        {children}
      </main>
    </div>
  );
}
