import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment";
import { TableRow } from "./TableRow";
import { TableHeader } from "./TableHeader";
import SendNotificationModal from "./SendNotificationModal";
import server from "../../utils/serverConfig";
import PreviousAdviceLogs from "./previousAdviceLogs/previousAdviceLogs";
import { encryptApiKey } from "../../utils/cryptoUtils";

const AdminAdviceDetails = ({ advisorSpecifier, adminEmail }) => {
  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const [notificationModal, setNotificationModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [activeTab, setActiveTab] = useState("send-previous");
  const [loadingAdvice, setLoadingAdvice] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingAdviceId, setLoadingAdviceId] = useState(null);

  const [alertModal, setAlertModal] = useState(false);
  const [tabData, setTabData] = useState({
    currentPage: 1,
    totalPages: 1,
    limit: 20,
  });
  const startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
  const endDate = moment().format("YYYY-MM-DD");

  const getAllHistoryData = async (pageNumber = 1, limit = 20) => {
    try {
      const response = await axios.post(
        `${server.ccxtServer.baseUrl}comms/send-reco-history/${pageNumber}/${limit}`,
        {
          advisorName: advisorTag,
          startDate: startDate,
          endDate: endDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      const { metrics, recoLogs, emails, pagination } = response?.data || {};

      const mappedData = Object.entries(metrics)
        .filter(([adviseId, metric]) => metric && metric.length > 0)
        .map(([adviseId, metricArray]) => {
          const metric = metricArray[0]; // Assuming we take the first metric entry
          const recoLogData = recoLogs?.[adviseId] || [];
          const emailData = emails?.[adviseId] || [];

          return recoLogData.map((log) => {
            const formattedSymbol = {
              advisedRangeHigher: log.advised_range_higher,
              advisedRangeLower: log.advised_range_lower,
              exchange: log.exchange,
              orderType: log.order_type,
              price: log.Price,
              quantity: log.quantity,
              segment: log.Segment,
              productType: log.ProductType,
              symbol: log.symbol,
              type: log.Type,
              rationale: log.rationale,
              trade_given_by: log.trade_given_by,
              adviceRecoId: log.advice_reco_id,
              price_when_send_advice: log.price_when_send_advice,
              comments: log?.comments || "",
              comment2: log?.comment2 || "",
            };

            const whatsappProgress =
              (metric.sent_whatsapp_count / metric.reco_save_count) * 100;
            const emailProgress =
              (metric.total_sent_mails / metric.reco_save_count) * 100;
            const platformProgress =
              (metric.reco_save_count / metric.reco_save_count) * 100;

            const isComplete =
              metric.unsent_whatsapp_count === 0 &&
              metric.unsupported_email_count === 0;

            return {
              date: new Date(metric.created_datetime),
              Symbol: formattedSymbol.symbol,
              action: isComplete,
              progress: whatsappProgress,
              emailProgress: emailProgress,
              platformProgress: platformProgress,
              unsentEmails: metric.unsent_whatsapp_emails || [],
              adviseId: adviseId,
              metricDetails: metric,
              symbolDetails: formattedSymbol,
              emailRecipients: emailData, // Adding email data
            };
          });
        })
        .flat();

      setTableData(mappedData);
      setTabData({
        currentPage: pagination?.currentPage || pageNumber,
        totalPages: pagination?.totalPages || 1,
        limit: pagination?.rowsPerPage,
      });
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleResendAdvice = (unsentEmails, symbolDetails, metricDetails) => {
    setLoading(true);
    setLoadingAdviceId(metricDetails?.advise_id);

    const adviceData = unsentEmails.map((email) => {
      const baseData = {
        email: email,
        userName: metricDetails?.name ? metricDetails?.name : "",
        phoneNumber: metricDetails?.phone,
        country_code: metricDetails?.country_code,
        advisor_name: metricDetails?.advisor_name,
        Symbol: symbolDetails.symbol,
        Exchange: symbolDetails.exchange,
        Type: symbolDetails.type,
        OrderType: symbolDetails.orderType,
        ProductType: symbolDetails.productType,
        Segment: symbolDetails.segment,
        Price: symbolDetails.orderType === "MARKET" ? 0 : symbolDetails.price,
        date: metricDetails.created_datetime,
        Advised_Range_Lower: symbolDetails.advisedRangeLower,
        Advised_Range_Higher: symbolDetails.advisedRangeHigher,
        rationale: symbolDetails?.rationale,
        comments: symbolDetails?.comments,
        comment2: symbolDetails?.comment2,
        trade_given_by: symbolDetails?.trade_given_by,
        advisorType: advisorSpecifier,
        advise_id: symbolDetails?.adviceRecoId,
      };

      // Only add quantity if advisorSpecifier is RIA
      if (advisorSpecifier === "RIA") {
        baseData.Quantity = symbolDetails.quantity;
      }

      return baseData;
    });

    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}comms/send-reco`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
      data: adviceData,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        setLoadingAdviceId(null);
        toast.success("Advice has been resent successfully", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        getAllHistoryData();
        setNotificationModal(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingAdviceId(null);
        toast.error("Error in resending Advice!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllHistoryData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingAdvice(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const getPageNumbers = () => {
    const pages = [];
    const { currentPage, totalPages } = tabData;
    const totalVisible = 10;

    if (totalPages <= totalVisible) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pages.push(1, "...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < totalPages) pages.push("...", totalPages);
    }

    return pages;
  };

  // Function to navigate to a different page
  const goToPage = (page) => {
    if (page > 0 && page <= tabData.totalPages) {
      getAllHistoryData(page, tabData.limit);
    }
  };

  return (
    <div className="w-full h-screen overflow-auto bg-white">
      <div className="min-w-full">
        {/* Header */}
        <div className="flex flex-row bg-[#2056DF] text-white px-6 py-4 w-full justify-between">
          <div>
            <h2 className="text-xl font-semibold mb-2">
              Investment Recommendation Management System
            </h2>
            <p className="text-blue-100 text-sm">
              Track and manage investment recommendations across multiple
              channels. Monitor delivery status, resend communications, and
              ensure all clients receive timely updates.
            </p>
          </div>
          {/*<button*/}
          {/*  onClick={() => setAlertModal(true)}*/}
          {/*  className="px-4 py-1 bg-yellow-500 h-[40px] text-white rounded-md  hover:bg-yellow-600"*/}
          {/*>*/}
          {/*  Alert Me*/}
          {/*</button>*/}
        </div>
        {/* Instructions Panel */}
        <div className="bg-white px-6 py-4 border-b w-full">
          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-gray-900 mb-2">
              Quick Instructions
            </h3>
            <ul className="text-sm text-gray-600 space-y-1">
              <li>
                • View delivery status for Platform, WhatsApp, and Email
                channels
              </li>
              <li>
                • Click on status icons to see detailed delivery information
              </li>
              <li>• Use "Send Again" to retry failed deliveries</li>
              <li>
                • Switch tabs to monitor historical recommendation records
              </li>
            </ul>
          </div>
        </div>

        {/* Tabs */}
        <div className="bg-white px-6 py-4 border-b w-full">
          <div className="flex space-x-2">
            <button
              onClick={() => setActiveTab("advice-status")}
              className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors ${
                activeTab === "advice-status"
                  ? "bg-[#2056DF] text-white shadow-sm"
                  : "bg-white text-gray-700 hover:bg-gray-50 border border-gray-200"
              }`}
            >
              Recommendation Status
            </button>
            <button
              onClick={() => setActiveTab("send-previous")}
              className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors ${
                activeTab === "send-previous"
                  ? "bg-[#2056DF] text-white shadow-sm"
                  : "bg-white text-gray-700 hover:bg-gray-50 border border-gray-200"
              }`}
            >
              Close Open Advice
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 w-full overflow-auto">
          {activeTab === "advice-status" ? (
            <div className="w-full">
              {loadingAdvice ? (
                <div className="flex items-center justify-center p-12">
                  <div className="w-10 h-10 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
                </div>
              ) : tableData?.length > 0 ? (
                <div className="w-full relative">
                  {/* Sticky Header */}

                  {/* Table Content */}
                  <div className="min-w-max">
                    <table className="w-full">
                      <TableHeader advisorSpecifier={advisorSpecifier} />
                      <tbody className="divide-y divide-gray-200">
                        {tableData?.map((item, i) => (
                          <TableRow
                            key={i}
                            item={item}
                            advisorSpecifier={advisorSpecifier}
                            setNotificationModal={setNotificationModal}
                            setSelectedItem={setSelectedItem}
                            handleResendAdvice={handleResendAdvice}
                            loading={loading}
                            loadingAdviceId={loadingAdviceId}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="flex justify-center mt-4 space-x-2">
                    <button
                      onClick={() => goToPage(tabData.currentPage - 1)}
                      disabled={tabData.currentPage === 1}
                      className="px-3 py-1 border rounded disabled:opacity-50"
                    >
                      Previous
                    </button>

                    {getPageNumbers().map((page, index) =>
                      page === "..." ? (
                        <span key={index} className="px-3 py-1">
                          ...
                        </span>
                      ) : (
                        <button
                          key={index}
                          onClick={() => goToPage(page)}
                          className={`px-3 py-1 border rounded ${
                            tabData.currentPage === page
                              ? "bg-blue-500 text-white"
                              : ""
                          }`}
                        >
                          {page}
                        </button>
                      )
                    )}

                    <button
                      onClick={() => goToPage(tabData.currentPage + 1)}
                      disabled={tabData.currentPage === tabData.totalPages}
                      className="px-3 py-1 border rounded disabled:opacity-50"
                    >
                      Next
                    </button>
                  </div>
                </div>
              ) : (
                <div className="text-center py-12">
                  <h3 className="text-lg font-medium text-gray-900">
                    No Recommended Stocks
                  </h3>
                  <p className="mt-2 text-sm text-gray-500 max-w-md mx-auto">
                    You haven't recommended any stocks yet. Explore and send
                    stock recommendations to populate this list.
                  </p>
                </div>
              )}
            </div>
          ) : (
            <PreviousAdviceLogs />
          )}
        </div>
      </div>

      {notificationModal && (
        <SendNotificationModal
          setNotificationModal={setNotificationModal}
          selectedItem={selectedItem}
          handleResendAdvice={handleResendAdvice}
          loading={loading}
        />
      )}

      {alertModal && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative bg-white p-6 rounded-md shadow-md w-96 max-w-full mx-4">
            <h2 className="text-xl font-semibold mb-4">Set Alert</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Infy goes above
              </label>
              <input
                type="number"
                className="border rounded px-2 py-1 w-full"
                placeholder="Enter price"
              />
              <button className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 w-full">
                Alert
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Infy goes below
              </label>
              <input
                type="number"
                className="border rounded px-2 py-1 w-full"
                placeholder="Enter price"
              />
              <button className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 w-full">
                Alert
              </button>
            </div>
            <button
              onClick={() => setAlertModal(false)}
              className="mt-4 px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 w-full"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminAdviceDetails;
