"use client";

import { useState, useEffect } from "react";
import { PlusIcon, XIcon } from "lucide-react";
import toast from "react-hot-toast";
import axios from "axios";
import server from "../../utils/serverConfig";
import DatePicker from "../ui/date-picker";
import LoadingSpinner from "../../components/LoadingSpinner";
import Tooltip from "../../components/Tooltip";
import { encryptApiKey } from "../../utils/cryptoUtils";

import {
  getStrategyDetails,
  updateStrategySubscription,
  userInsertDoc,
} from "../../services/ModelPFServices";

import {
  normalWhatsAppNotification,
  normalEmailNotification,
  getWhatsAppRebalanceNotification,
} from "../../services/WhatsAppAndEmailService";
const AddClientForm = ({
  existingGroups = [],
  onClose,
  userId,
  setIsDataFetching,
  getAllClientsData,
}) => {
  const [formData, setFormData] = useState({
    clientName: "",
    email: "",
    countryCode: "",
    phone: "",
    groups: ["All Client"],
    location: "",
    telegram: "",
    pan: "",
    creationDate: new Date().toISOString(),
    subscriptions: [],
    investAmount: "",
  });

  const [dateOfBirth, setDateOfBirth] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [creationDate, setCreationDate] = useState(new Date());
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [showGroupSelector, setShowGroupSelector] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [loading, setLoading] = useState(false);
  const [groupTypes, setGroupTypes] = useState({});

  const kycStatusOptions = [
    { value: "Verified", label: "Verified" },
    { value: "Unverified", label: "Unverified" },
  ];

  // Function to get group type for each selected group
  const getGroupType = async (groupName) => {
    try {
      const response = await axios.get(
        `${server.server.baseUrl}api/all-groups`,
        {
          params: {
            advisor: process.env.REACT_APP_ADVISOR_SPECIFIC_TAG,
            groupName: groupName,
          },
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      return response?.data?.data?.groupType || "";
    } catch (error) {
      console.log("Error fetching group type:", error);
      return "";
    }
  };

  // Fetch group types for all selected groups
  useEffect(() => {
    const fetchGroupTypes = async () => {
      const types = {};

      for (const group of selectedGroups) {
        if (group !== "All Client") {
          const groupType = await getGroupType(group);
          types[group] = groupType;
        }
      }

      setGroupTypes(types);
    };

    if (selectedGroups.length > 0) {
      fetchGroupTypes();
    }
  }, [selectedGroups]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePanChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, "")
      .slice(0, 10);

    setFormData((prevData) => ({
      ...prevData,
      [name]: sanitizedValue,
    }));
  };
  const handleGroupSelect = (group) => {
    if (group === "All Client") return; // Prevent modifying All Client

    if (!selectedGroups.includes(group)) {
      // Add group
      setSelectedGroups((prev) => [...prev, group]);

      // Create a new subscription for this group
      const newSubscription = {
        startDate: new Date().toISOString(),
        plan: group,
        capital: formData.investAmount || "",
        charges: 0,
        invoice: "",
        expiry: expiryDate
          ? new Date(expiryDate).toISOString()
          : new Date(
              new Date().setMonth(new Date().getMonth() + 1)
            ).toISOString(),
      };

      setFormData((prev) => ({
        ...prev,
        subscriptions: [...prev.subscriptions, newSubscription],
      }));
    }
  };

  const handleGroupRemove = (groupToRemove) => {
    if (groupToRemove === "All Client") return; // Prevent removing All Client

    setSelectedGroups((prev) =>
      prev.filter((group) => group !== groupToRemove)
    );

    // Remove subscription for the removed group
    setFormData((prev) => ({
      ...prev,
      subscriptions: prev.subscriptions.filter(
        (sub) => sub.plan !== groupToRemove
      ),
    }));
  };

  const validateForm = () => {
    if (!formData.email || !formData.clientName || !formData.phone) {
      setValidationError("Please fill in all required fields");
      return false;
    }
    if (selectedGroups.length === 0) {
      setValidationError("Please select at least one group");
      return false;
    }
    if (!expiryDate) {
      setValidationError("Please select a plan expiry date");
      return false;
    }

    // Check if expiry date is in the past
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Reset time to start of day for fair comparison
    const selectedExpiryDate = new Date(expiryDate);
    selectedExpiryDate.setHours(0, 0, 0, 0);

    if (selectedExpiryDate < currentDate) {
      setValidationError("Plan expiry date cannot be in the past");
      return false;
    }

    setValidationError("");
    return true;
  };

  const isFormValid = () => {
    return (
      formData.email &&
      formData.clientName &&
      formData.phone &&
      expiryDate &&
      formData.investAmount &&
      selectedGroups.length > 0
    );
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    setLoading(true);

    // Update all subscriptions to use the current expiry date
    const updatedSubscriptions = formData.subscriptions.map((sub) => ({
      ...sub,
      capital: formData.investAmount || "",
      expiry: expiryDate ? new Date(expiryDate).toISOString() : sub.expiry,
    }));

    const clientData = {
      clientName: formData.clientName,
      email: formData.email,
      countryCode: formData.countryCode,
      phone: formData.phone,
      groups: ["All Client", ...selectedGroups],
      location: formData.location,
      telegram: formData.telegram,
      pan: formData.pan,
      creationDate: creationDate
        ? new Date(creationDate).toISOString()
        : new Date().toISOString(),
      subscriptions: updatedSubscriptions,
    };

    try {
      const response = await fetch(
        `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
          body: JSON.stringify({
            userId: userId,
            clientData: clientData,
            DateofBirth: dateOfBirth || "",
            advisorName: process.env.REACT_APP_ADVISOR_SPECIFIC_TAG,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        // Handle model portfolio groups
        for (const group of selectedGroups) {
          if (groupTypes[group] === "model portfolio") {
            try {
              // Get strategy details
              const strategyResponse = await getStrategyDetails(group);
              const strategyDetails = strategyResponse?.data[0].originalData;

              // Subscribe user to strategy
              await updateStrategySubscription(
                formData.email,
                "subscribe",
                strategyDetails
              );

              // Insert user document
              await userInsertDoc(
                formData.email,
                strategyDetails,
                formData.investAmount
              );

              // Send notifications
              await normalWhatsAppNotification(
                formData.phone,
                formData.clientName,
                new Date(),
                expiryDate ? new Date(expiryDate) : new Date(),
                formData.countryCode
              );

              await normalEmailNotification(
                formData.clientName,
                new Date(),
                expiryDate ? new Date(expiryDate) : new Date(),
                formData.email,
                group,
                formData.pan
              );

              await getWhatsAppRebalanceNotification(
                strategyDetails,
                formData.email,
                formData.phone,
                formData.countryCode,
                formData.clientName
              );
            } catch (error) {
              console.error(
                `Error processing model portfolio ${group}:`,
                error
              );
            }
          }
        }

        setIsDataFetching(true);
        getAllClientsData(userId);
        onClose();
        toast.success(
          `New Client ${clientData.clientName} added successfully to ${selectedGroups.length} groups!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        throw new Error(result.message || "Failed to add client");
      }
    } catch (error) {
      console.error("Error adding client:", error);
      toast.error(`Error adding new client: ${error.message}`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Add New Client</h2>
          <button onClick={onClose} className="p-1 hover:bg-gray-100 rounded">
            <XIcon className="h-5 w-5" />
          </button>
        </div>

        {validationError && (
          <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
            {validationError}
          </div>
        )}

        <div className="grid grid-cols-2 gap-4 mb-6">
          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="border rounded p-2"
              placeholder="Enter email"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              name="clientName"
              value={formData.clientName}
              onChange={handleInputChange}
              className="border rounded p-2"
              placeholder="Enter name"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              Country Code <span className="text-red-500">*</span>
            </label>
            <input
              name="countryCode"
              value={formData.countryCode}
              onChange={handleInputChange}
              className="border rounded p-2"
              placeholder="e.g. 91"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              Phone <span className="text-red-500">*</span>
            </label>
            <input
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="border rounded p-2"
              placeholder="Enter phone number"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              Date of Birth <span className="text-red-500">*</span>
            </label>

            <DatePicker
              date={dateOfBirth}
              setDate={setDateOfBirth}
              placeholder="Choose Birth Date"
              className="w-full text-black"
              expirySet={true}
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              Location <span className="text-red-500">*</span>
            </label>
            <input
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              className="border rounded p-2"
              placeholder="Enter location"
            />
          </div>

          {process.env.REACT_APP_TELEGRAM_REQUIRED === "true" ? (
            <div className="flex flex-col">
              <label className="text-sm font-medium mb-1">
                Telegram ID <span className="text-red-500">*</span>
              </label>
              <Tooltip
                content={
                  <ul className="list-disc pl-4">
                    <li>
                      Open the Telegram app and go to the search icon on the top
                      right.
                    </li>
                    <li>Type "@userinfobot" in the search bar.</li>
                    <li>
                      Click or tap on the "@userinfobot" profile that appears in
                      the search results.
                    </li>
                    <li>
                      In the chat with @userinfobot, select "Start" at the
                      bottom of the chat.
                    </li>
                    <li>The bot will then display your User ID.</li>
                  </ul>
                }
              >
                <input
                  name="telegram"
                  value={formData.telegram}
                  onChange={handleInputChange}
                  className="w-full border rounded p-2"
                  placeholder="Enter Telegram ID"
                />
              </Tooltip>
            </div>
          ) : null}

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              PAN <span className="text-red-500">*</span>
            </label>
            <Tooltip content="PAN Card number is compulsory as per SEBI Regulations. It is mandatory for us to upload it on SEBI Portal.">
              <input
                name="pan"
                value={formData.pan}
                onChange={handlePanChange}
                className="w-full border rounded p-2"
                placeholder="Enter PAN number"
              />
            </Tooltip>
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              KYC Validation Status<span className="text-red-500">*</span>
            </label>
            <select
              name="kycStatus"
              value={formData.kycStatus}
              onChange={handleInputChange}
              className="border rounded p-2"
            >
              <option value="">
                Select KYC status<span className="text-red-500">*</span>
              </option>
              {kycStatusOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              Investment Amount <span className="text-red-500">*</span>
            </label>
            <input
              name="investAmount"
              value={formData.investAmount}
              onChange={handleInputChange}
              className="border rounded p-2"
              placeholder="Enter investment amount"
              type="number"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              Plan Expiry Date <span className="text-red-500">*</span>
            </label>
            <DatePicker
              date={expiryDate}
              setDate={setExpiryDate}
              placeholder="Choose Plan Expiry Date"
              className="w-full text-black"
              expirySet={true}
              minDate={new Date()} // Set minimum date to today
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              Creation Date<span className="text-red-500">*</span>
            </label>
            <DatePicker
              date={creationDate}
              setDate={setCreationDate}
              placeholder="Choose Creation Date"
              className="w-full text-black"
            />
          </div>
        </div>

        <div className="mb-6">
          <div className="flex items-center justify-between mb-2">
            <label className="text-sm font-medium">
              Selected Groups<span className="text-red-500">*</span>
            </label>
            <button
              onClick={() => setShowGroupSelector(true)}
              className="flex items-center text-sm text-blue-600 hover:text-blue-800"
            >
              <PlusIcon className="h-4 w-4 mr-1" />
              Add Group
            </button>
          </div>

          <div className="flex flex-wrap gap-2">
            {selectedGroups.map((group) => (
              <div
                key={group}
                className="flex items-center bg-gray-100 rounded-full px-3 py-1"
              >
                <span className="text-sm">{group}</span>
                {groupTypes[group] && (
                  <span className="ml-1 text-xs text-gray-500">
                    ({groupTypes[group]})
                  </span>
                )}
                <button
                  onClick={() => handleGroupRemove(group)}
                  className="ml-2 text-gray-500 hover:text-gray-700"
                >
                  <XIcon className="h-3 w-3" />
                </button>
              </div>
            ))}
          </div>
        </div>

        {showGroupSelector && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-4 w-80">
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-medium">Select Groups</h3>
                <button
                  onClick={() => setShowGroupSelector(false)}
                  className="p-1 hover:bg-gray-100 rounded"
                >
                  <XIcon className="h-4 w-4" />
                </button>
              </div>
              <div className="space-y-2 max-h-60 overflow-y-auto">
                {existingGroups.map((group) => (
                  <button
                    key={group}
                    onClick={() => {
                      handleGroupSelect(group);
                      setShowGroupSelector(false);
                    }}
                    className="w-full text-left px-3 py-2 hover:bg-gray-100 rounded"
                    disabled={selectedGroups.includes(group)}
                  >
                    {group}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-end gap-3 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 border rounded hover:bg-gray-50"
            disabled={loading}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={loading || !isFormValid()}
            className="px-4 py-2 bg-black text-white rounded hover:bg-black/90 disabled:bg-gray-400"
          >
            {loading ? <LoadingSpinner /> : "Add Client"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddClientForm;
