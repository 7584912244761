import React from "react";
import { Eye } from "lucide-react";
import EmailPopUp from "../previousAdviceLogs/EquityAdvice/EmailPopUp";

const showQuantityColumn = process.env.REACT_APP_ADVISOR_SPECIFIER;

const TableRow = ({
  combinedAdviceData,
  handleCheckboxChange,
  selectedAdvices,
  handleClosePosition,
  emailPopupOpen,
  handleCloseEmailPopup,
  handleOpenEmailPopup,
  exchange,
  getEmailsByAdviceId,
}) => {
  return (
    <tbody className="bg-white divide-y divide-gray-200">
      {combinedAdviceData?.map((item, rowIndex) => (
        <React.Fragment key={item.id}>
          {item?.advices
            ?.filter((advice) => exchange.includes(advice?.exchange))
            ?.sort(
              (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
            )
            ?.map((advice, adviceIndex) => {
              return (
                <tr
                  key={`${item.id}-${adviceIndex}`}
                  className={` ${
                    advice.closurestatus === "fullClose" ||
                    advice.closurestatus === "expired"
                      ? "bg-gray-300 cursor-not-allowed" // Grey background for fully closed advices
                      : rowIndex % 2 === 0
                      ? "bg-gray-50 hover:bg-gray-50 cursor-pointer"
                      : "bg-white hover:bg-gray-50 cursor-pointer"
                  }`}
                >
                  <td className="py-3 px-4 border-b">
                    <input
                      type="checkbox"
                      checked={selectedAdvices?.has(
                        `${item.id}-${adviceIndex}`
                      )}
                      onChange={() =>
                        handleCheckboxChange(item.id, adviceIndex)
                      }
                      className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                    />
                  </td>
                  <td className="py-3 px-4 border-b">
                    <div className="flex space-x-2">
                      <button
                        onClick={() =>
                          handleClosePosition(item.id, adviceIndex, "full")
                        }
                        disabled={
                          advice?.closurestatus === "fullClose" ||
                          advice?.closurestatus === "expired"
                        }
                        className="px-1 py-2 w-[7rem] bg-[#2584D6] text-white hover:shadow-md rounded-md  text-sm disabled:cursor-not-allowed"
                      >
                        Close Full
                      </button>
                      <button
                        onClick={() =>
                          handleClosePosition(item.id, adviceIndex, "partial")
                        }
                        disabled={
                          advice?.closurestatus === "fullClose" ||
                          advice?.closurestatus === "expired"
                        }
                        className="px-1 py-2 w-[7rem] bg-[#2563EB] text-white rounded-md hover:shadow-md text-sm disabled:cursor-not-allowed"
                      >
                        Close Partial
                      </button>
                    </div>
                  </td>
                  <td className="py-3 px-4 border-b font-poppins">
                    {advice.symbol}
                  </td>
                  <td className="py-3 px-4 border-b font-poppins">
                    {advice?.closurestatus === null
                      ? "NA"
                      : advice?.closurestatus === "fullClose"
                      ? "Closed"
                      : advice?.closurestatus}
                  </td>
                  <td className="py-3 px-4 border-b">{advice?.type}</td>
                  <td className="py-3 px-4 border-b font-poppins">
                    {advice?.exchange}
                  </td>
                  <td className="py-3 px-4 border-b font-poppins">
                    {advice.price_when_send_advice}
                  </td>
                  {showQuantityColumn === "RIA" && (
                    <td className="py-3 px-4 border-b font-poppins">
                      {advice?.quantity}
                    </td>
                  )}

                  <td className="py-3 px-4 border-b font-poppins">
                    {advice?.order_type}
                  </td>
                  <td className="py-3 px-4 border-b font-poppins">
                    {advice?.price !== "0.00" ? advice?.price : "-"}
                  </td>
                  <td className="py-3 px-4 border-b font-poppins">
                    {advice.product_type}
                  </td>
                  <td className="py-3 px-4 border-b font-poppins">
                    {advice.segment}
                  </td>
                  {adviceIndex === 0 && (
                    <td
                      rowSpan={item.advices.length}
                      className="py-3 px-4 border-b"
                    >
                      <div className="flex items-center justify-between">
                        {(() => {
                          return (
                            <>
                              <span className="font-poppins pr-2">
                                View/Add
                              </span>
                              <button
                                onClick={() => handleOpenEmailPopup(item.id)}
                                className="text-gray-500 hover:text-gray-700"
                              >
                                <Eye className="h-5 w-5" />
                              </button>
                            </>
                          );
                        })()}
                      </div>
                      <EmailPopUp
                        isOpen={emailPopupOpen === item.id}
                        onClose={handleCloseEmailPopup}
                        adviceId={item.id}
                        emails={getEmailsByAdviceId(item.id)} // Use function to fetch emails
                      />
                    </td>
                  )}
                  <td className="py-3 px-4 border-b font-poppins">
                    {advice.group}
                  </td>
                  <td className="py-3 px-4 border-b font-poppins border rounded text-md w-80 h-24 resize-y">
                    <div className="flex items-center space-x-2">
                      <textarea
                        value={advice.rationale}
                        readOnly
                        className="bg-none border rounded px-2 py-1 font-poppins text-md w-80 h-24 resize-y"
                      />
                    </div>
                  </td>
                  <td className="py-3 px-4 border-b font-poppins border rounded text-md w-80 h-24 resize-y">
                    <div className="flex items-center space-x-2">
                      <textarea
                        value={advice?.comments}
                        readOnly
                        className="border rounded px-2 py-1 font-poppins text-md w-80 h-24 resize-y"
                      />
                    </div>
                  </td>
                  <td className="py-3 px-4 border-b font-poppins border rounded text-md w-80 h-24 resize-y">
                    <div className="flex items-center space-x-2">
                      <textarea
                        value={advice?.comment2}
                        readOnly
                        className="border rounded px-2 py-1 font-poppins text-md w-80 h-24 resize-y"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </React.Fragment>
      ))}
    </tbody>
  );
};

export default TableRow;
