import React, { useState,useEffect } from "react";
import { ChevronUp, ChevronDown } from "lucide-react";
import { encryptApiKey, decryptApiKey } from "../utils/cryptoUtils";

const encryptedKey = encryptApiKey(process.env.REACT_APP_AQ_KEYS,
    process.env.REACT_APP_AQ_SECRET);

const FaqSection = () => {
  const [selectedLeft, setSelectedLeft] = useState(null);
  const [selectedRight, setSelectedRight] = useState(null);
  const [openSection, setOpenSection] = useState(null);
  const [advisorText, setAdvisorText] = useState('advisor');

  useEffect(() => {
    if (process.env.REACT_APP_ADVISOR_SPECIFIER === 'RA') {
      setAdvisorText('Research Analyst');
    } else {
      setAdvisorText('Advisor'); // Default or alternative value
    }
  }, []);


  const handleClickLeft = (index) => {
    setOpenSection("left");
    setSelectedLeft((prevSelected) => (prevSelected === index ? null : index));
    if (openSection === "right") {
      setSelectedRight(null);
    }
  };

  const handleClickRight = (index) => {
    setOpenSection("right");
    setSelectedRight((prevSelected) => (prevSelected === index ? null : index));
    if (openSection === "left") {
      setSelectedLeft(null);
    }
  };

  const faqsLeft = [
    {
      question: "What services will I receive?",
      answer:
        "Our platform provides stock recommendations aimed at medium to long-term growth, typically held for 6 - 12 months. In addition, you'll receive high-conviction swing trading calls occasionally, with a shorter holding period of 7 - 30 days. This gives you a mix of stable growth and quick opportunities. You will also be informed about the correct time to sell your investments and book profits.",
    },
    {
      question: "Other than stock recommendations, is there anything else I will receive?",
      answer:
      " Absolutely! In addition to stock recommendations, you’ll gain access to timely market updates, insights into trending sectors, and our in-house research reports. You’ll also be invited to exclusive webinars, where we explain the reasoning behind our stock picks, when to buy or sell, and highlight key sectors to watch. These resources will give you a deeper understanding of market trends and help you make more informed investment decisions.",
    },
    {
      question: "How will I receive the information?",
      answer:
      "All information will be delivered directly to your phone via WhatsApp messages under our branding. Stock recommendations will come in a standardized format, allowing you to easily approve the transaction. You’ll be automatically redirected to your brokerage app (e.g., Zerodha, Angel One) to place the trade seamlessly. Additionally, you’ll receive regular updates, sector insights, and educational content through the same WhatsApp chat, ensuring everything is conveniently in one place.",
    },
    {
      question: `How can I contact the ${advisorText} if I face any issues?`,
      answer:
      "Once you have purchased a plan, please log in to the investor portal. On the bottom-right side, you will find a chat icon. You can leave your contact information, and our customer care team will get back to you within 24-48 hours to resolve the issue.",
    },

  ];

  const faqsRight = [
    {
      question: "How much capital is required to begin?",
      answer:
      " You can start with as little as ₹50,000, and gradually increase your investment over time. However, we recommend starting with ₹2,00,000 or more to effectively build a diversified portfolio of high-quality stocks through our recommendations.",
    },
    {
      question: " What is your investment philosophy?",
      answer:
      " Our investment philosophy is centered around identifying high-quality, high-growth stocks with strong sectoral tailwinds, and recommending them at the most opportune time, giving you the advantage of early entry. We focus on fundamentally sound companies, potential turnarounds, and special situations that have the potential to deliver significant returns. Our approach is designed to provide you with the best possible chance to grow your wealth while minimizing risks, through well-researched and timely stock picks.",
    },
 
    {
      question: "How will I know when to renew my plan? ",
      answer:
      "You will receive an email notification before your plan expires so that you can renew. You can also opt for auto renewal if you take a recurring subscription payment.",
    },

    {
      question: "Would I lose my current plan if I upgrade to a longer term plan?",
      answer:
      "No worries! Purchase the longer term plan now, and your remaining subscription time will be added at the end, so you don’t waste a single rupee.",
    },

  ];

  return (
    <div className="bg-[#FFFFFF] " id="faqSection" >
      <h1 className="text-[28px] md:text-[40px] lg:text-[40px] text-center font-bold text-black  pt-14 ">
        Frequently Asked Questions
      </h1>
<div className="h-[103vh] md:h-[530px] lg-h[560px] ">
      <div className="grid grid-cols-1 mx-2  md:grid-cols-2  md:gap-x-8  ">
        
        {/* Left Section */}
        <div className="flex justify-end h-80%">
          <div className="container w-full md:w-[80%] lg:w-[70%]">
            <section className="overflow-visible">
              <div className="mt-8 space-y-3 lg:mt-12">
                {faqsLeft.map((faq, index) => (
                        <div
                        key={index}
                        className={`p-4 md:p-8 rounded-xl bg-white border border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] overflow-hidden ${
                          selectedLeft === index 
                            ? "h-auto border-t-4 border-t-[#55C2C3]" 
                            : "h-20"
                        } flex flex-col transition-height duration-300 ease-in-out w-full sm:w-[90%] md:w-[90%] lg:w-[100%] xl:w-[100%]`}
                      >
                        <div className="flex items-center h-full">
                          <button
                            className="flex items-center justify-between w-full"
                            onClick={() => handleClickLeft(index)}
                          > 
                      <h1
                        className={`font-Montserrat  text-left text-black text-sm md:text-base flex-grow ${
                          selectedLeft === index
                            ? "font-bold"
                            : "font-semibold"
                        }`}
                      >
                        {" "}
                        {faq.question}
                      </h1>
                      {selectedLeft === index ? (
                        <ChevronUp className="text-black" />
                      ) : (
                        <ChevronDown className="text-black" />
                      )}
                    </button>
                    </div>

                    {selectedLeft === index && (
                      <p className="mt-5 text-[13px] text-gray-500 font-poppins">
                        {faq.answer}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>

        {/* Right Section */}
        <div className="container w-full  md:w-[80%] lg:w-[70%]">
          <section className="overflow-visible">
            <div className="mt-2 space-y-3 lg:mt-12">
              {faqsRight.map((faq, index) => (
           <div
  key={index}
  className={`p-4 md:p-8 rounded-xl bg-white border border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] overflow-hidden ${
    selectedRight === index 
      ? "h-auto border-t-4 border-t-[#55C2C3]" 
      : "h-20"
  } flex flex-col transition-height duration-300 ease-in-out w-full sm:w-[90%] md:w-[90%] lg:w-[100%] xl:w-[100%]`}
>
  <div className="flex items-center h-full">
    <button
      className="flex items-center justify-between w-full"
      onClick={() => handleClickRight(index)}
    > 
                    <h1
                      className={`text-left text-black text-sm md:text-base flex-grow ${
                        selectedRight === index
                          ? "font-bold"
                          : "font-semibold"
                      }`}
                    >
                      {" "}
                      {faq.question}
                    </h1>
                    {selectedRight === index ? (
                      <ChevronUp className="text-black" />
                    ) : (
                      <ChevronDown className="text-black" />
                    )}
                  </button>
                  </div>
                  {selectedRight === index && (
                    <p className="mt-6 text-sm text-gray-500 font-poppins">
                      {faq.answer}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
      </div>
    </div>
  );
};

export default FaqSection;
