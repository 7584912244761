"use client"

import { useState } from "react"
import { Trash2, MinusIcon, PlusIcon, RefreshCw } from "lucide-react"
import { Dialog, DialogContent } from "../../components/ui/dialog"

import LoadingSpinner from "../../components/LoadingSpinner"
import axios from "axios"
import server from "../../utils/serverConfig"

import { encryptApiKey } from "../../utils/cryptoUtils"

const ReviewTradeModel = ({
  calculateTotalAmount,
  stockDetails,
  getLTPForSymbol,
  setStockDetails,
  setOpenReviewTrade,
  placeOrder,
  loading,
  funds,
  openReviewTrade,
  getCartAllStocks,
  broker,
}) => {
  const [selectedOption, setSelectedOption] = useState("")
  const [inputFixSizeValue, setInputFixValue] = useState("")
  const handleDecreaseStockQty = (symbol, tradeId) => {
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: Math.max(stock.quantity - 1, 0) }
        : stock,
    )
    setStockDetails(newData)
  }

  const handleIncreaseStockQty = (symbol, tradeId) => {
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId ? { ...stock, quantity: stock.quantity + 1 } : stock,
    )
    setStockDetails(newData)
  }

  const handleQuantityInputChange = (symbol, value, tradeId) => {
    const newQuantity = Number.parseInt(value) || 0
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId ? { ...stock, quantity: newQuantity } : stock,
    )
    setStockDetails(newData)
  }

  // const handleDecreaseStockQty = (symbol, tradeId) => {
  //   const newData = stockDetails.map((stock) => {
  //     if (stock.tradingSymbol === symbol && stock.tradeId === tradeId && stock.quantity > 1) {
  //       const newQuantity = stock.quantity - 1;
  //       return { ...stock, quantity: newQuantity };
  //     }
  //     return stock;
  //   });
  //   setStockDetails(newData);
  //   updateFixedSizeIfNeeded(newData, symbol, tradeId);
  // };

  // const handleIncreaseStockQty = (symbol, tradeId) => {
  //   const newData = stockDetails.map((stock) => {
  //     if (stock.tradingSymbol === symbol && stock.tradeId === tradeId) {
  //       const newQuantity = stock.quantity + 1;
  //       return { ...stock, quantity: newQuantity };
  //     }
  //     return stock;
  //   });

  //   setStockDetails(newData);
  //   updateFixedSizeIfNeeded(newData, symbol, tradeId);
  // };

  const handleSelectStock = async (symbol, tradeId) => {
    const isSelected = stockDetails.some(
      (selectedStock) => selectedStock.tradingSymbol === symbol && selectedStock.tradeId === tradeId,
    )

    if (isSelected) {
      // If the stock is already selected, remove it from the cart
      try {
        await axios.post(
          `${server.server.baseUrl}api/cart/cart-items/remove/remove-from-cart`,
          { tradeId },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(process.env.REACT_APP_AQ_KEYS, process.env.REACT_APP_AQ_SECRET),
            },
          },
        )

        setStockDetails(
          stockDetails.filter(
            (selectedStock) => selectedStock.tradingSymbol !== symbol || selectedStock.tradeId !== tradeId,
          ),
        )
      } catch (error) {
        console.error("Error removing stock from cart", error)
      }
    } else {
      // If the stock is not selected, add it to the cart
      const updatedStock = stockDetails.find((item) => item.Symbol === symbol && item.tradeId === tradeId)
      if (updatedStock) {
        const newStock = {
          user_email: updatedStock.user_email,
          trade_given_by: updatedStock.trade_given_by,
          tradingSymbol: updatedStock.Symbol,
          transactionType: updatedStock.Type,
          exchange: updatedStock.Exchange,
          segment: updatedStock.Segment,
          productType: updatedStock.ProductType,
          orderType: updatedStock.OrderType,
          price: updatedStock.Price,
          quantity: updatedStock.Quantity,
          priority: updatedStock.Priority,
          tradeId: updatedStock.tradeId,
          user_broker: broker,
        }
        setStockDetails([...stockDetails, newStock])

        try {
          await axios.post(`${server.server.baseUrl}api/cart/add/add-to-cart`, newStock, {
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(process.env.REACT_APP_AQ_KEYS, process.env.REACT_APP_AQ_SECRET),
            },
          })
          getCartAllStocks()
        } catch (error) {
          console.error("Error adding stock to cart", error)
        }
      }
    }
  }

  const handleFixSize = () => {
    if (selectedOption === "fix-size" && inputFixSizeValue) {
      const fixedSize = Number.parseFloat(inputFixSizeValue)
      const updatedStockDetails = stockDetails.map((stock) => {
        const currentPrice = Number.parseFloat(getLTPForSymbol(stock.tradingSymbol))
        const newQuantity = Math.floor(fixedSize / currentPrice)
        return { ...stock, quantity: newQuantity }
      })
      setStockDetails(updatedStockDetails)
    }
  }

  const handleReset = () => {
    setSelectedOption("")
    setInputFixValue("")
  }
  console.log("stockDetails", stockDetails)

  const hasZeroQuantity = stockDetails.some((stock) => stock.quantity === 0)

  // Helper function to check if symbol ends with CE or PE
  const isOptionSymbol = (symbol) => {
    return symbol.endsWith("CE") || symbol.endsWith("PE")
  }

  // Add these helper functions after the existing isOptionSymbol function

  // Helper function to check if symbol ends with EQ
  const isEquitySymbol = (symbol) => {
    return symbol.endsWith("EQ")
  }

  // Function to determine the scaling text based on trading symbols
  const getScalingText = () => {
    if (stockDetails.length === 0) return "Scale Quantity by"

    const hasOptions = stockDetails.some((stock) => isOptionSymbol(stock.tradingSymbol))
    const hasEquity = stockDetails.some((stock) => isEquitySymbol(stock.tradingSymbol))

    if (hasOptions && hasEquity) {
      return "Scale quantity & lot by"
    } else if (hasOptions) {
      return "Scale lot by"
    } else {
      return "Scale quantity by"
    }
  }


  const renderContent = () => (
    <div className="flex flex-col w-full h-full bg-white rounded-[8px] ">
      <div className="px-4 sm:px-6 py-4 border-b border-gray-200">
        <h2 className="text-xl font-poppins font-semibold text-gray-900">Review Trade Details</h2>
        {hasZeroQuantity && (
          <p className="mt-2 text-sm text-[#EA2D3F] font-semibold">Please select quantity to place order</p>
        )}
        {Number.parseFloat(calculateTotalAmount()) > Number.parseFloat(funds) && (
          <p className="mt-2 text-sm font-poppins text-amber-600">
            Note: Orders may be rejected due to insufficient broker balance of ₹{Number.parseFloat(funds).toFixed(2)}.
          </p>
        )}
      </div>

      <div className="flex-1 overflow-auto custom-scroll min-h-[300px] sm:min-w-[600px] sm:max-h-[400px] px-4 sm:px-6 py-4">

        <div className="mt-3 mb-2 hidden sm:flex items-center px-3 py-2 bg-blue-50 border border-blue-100 rounded-md">
          <div className="w-1 h-10 bg-blue-400 rounded-full mr-3"></div>
          <p className="text-[12px] font-poppins text-gray-700 leading-tight">
            <span className="font-semibold">Note:</span> Your cart can include both equity and derivatives.
             Please enter the quantity in shares for equity and in lots for derivatives.
          </p>
        </div>

        {stockDetails.map((ele) => (
          <div
            key={ele.tradeId}
            className="flex items-center justify-between py-3 border-b border-gray-200 last:border-b-0"
          >
            <div className="flex-1 sm:mr-4 max-w-[30%] min-w-[30%]">
              <p className="text-[14px] sm:text-sm font-medium  text-[#000000]/80 font-poppins text-gray-900 truncate">
                {ele.tradingSymbol}
              </p>
              <span
                className={`inline-flex items-center px-1 py-0.5 rounded text-sm font-semibold ${
                  ele.transactionType?.toUpperCase() === "BUY"
                    ? "text-[#16A085] text-[14px] font-poppins font-medium"
                    : " text-[#EA2D3F] text-[14px] font-poppins font-medium"
                }`}
              >
                {ele.transactionType?.toUpperCase()}
              </span>
            </div>
            <div className="flex flex-col items-center min-w-[5%] sm:min-w-[10%]">
          
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => handleDecreaseStockQty(ele.tradingSymbol, ele.tradeId)}
                  disabled={ele.quantity <= 1}
                  className="p-1 rounded-full hover:bg-gray-200 disabled:opacity-50"
                >
                  <MinusIcon className="h-3 w-3 sm:h-4 sm:w-4" />
                </button>
                <div className="relative">
                  <input
                    type="text"
                    value={ele.quantity}
                    onChange={(e) => handleQuantityInputChange(ele.tradingSymbol, e.target.value, ele.tradeId)}
                    className="w-14 sm:w-20 text-center font-poppins border border-gray-300 rounded-md selection:bg-transparent pr-1"
                  />
                  <div className="absolute right-1 top-1/2 transform -translate-y-1/2 text-[10px] font-semibold text-black pointer-events-none">
                    {isOptionSymbol(ele.tradingSymbol) ? "| Lot" : "| Qty"}
                  </div>
                </div>
                <button
                  onClick={() => handleIncreaseStockQty(ele.tradingSymbol, ele.tradeId)}
                  className="p-1 rounded-full hover:bg-gray-200"
                >
                  <PlusIcon className="h-3 w-3 sm:h-4 sm:w-4 " />
                </button>
              </div>
            </div>

            <div className=" w-[10%] text-right ">
              <p className="text-sm font-medium  text-[#000000]/80 font-poppins text-gray-900">
                ₹{getLTPForSymbol(ele.tradingSymbol)}
              </p>
              <p className="text-xs sm:ml-0 text-gray-500  font-poppins">
                {ele.orderType?.toLowerCase()}{" "}
                {ele.orderType === "LIMIT" || ele.orderType === "STOP" ? `(${ele.price})` : null}
              </p>
            </div>
            <button
              onClick={() => handleSelectStock(ele.tradingSymbol, ele.tradeId)}
              className="ml-10 sm:ml-4 p-2  text-[#000000]/80  text-[15px] cursor-pointer hover:text-red-500"
            >
              <Trash2 size={18} className="h-4 w-4 sm:h-5 sm:w-5" />
            </button>
          </div>
        ))}
      </div>

      <div className="px-3 sm:px-6 border-t border-gray-200">
        <div className="mt-3 mb-2 hidden sm:flex items-center px-3 py-2 bg-blue-50 border border-blue-100 rounded-md">
          <div className="w-1 h-10 bg-blue-400 rounded-full mr-3"></div>
          <p className="text-[12px] font-poppins text-gray-700 leading-tight">
            <span className="font-semibold">Note:</span> Scale by quantity adjusts the quantity of each stock based on
            the given amount, ensuring the total investment stays within the specified budget.
          </p>
        </div>
        <h3 className="sm:hidden text-base font-medium font-poppins text-gray-900 pt-3 mb-1"></h3>
        <div className="space-y-4">
          <div className="flex items-center space-x-4 pb-3 pt-3 min-h-[40px] max-h-[40px]">
            <input
              type="radio"
              id="fix-size"
              name="options"
              value="fix-size"
              checked={selectedOption === "fix-size"}
              onChange={(e) => setSelectedOption(e.target.value)}
              className="h-4 w-4"
              style={{ accentColor: "#20A48A" }}
            />
            <h3 className="hidden sm:block text-base font-medium font-poppins text-gray-900">{getScalingText()}</h3>

            {selectedOption === "fix-size" && (
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  value={inputFixSizeValue}
                  onChange={(e) => setInputFixValue(e.target.value)}
                  className="w-[97px] sm:w-24 px-2 py-1 text-sm border border-gray-300 rounded-md"
                  placeholder="Enter value"
                />
                <button
                  onClick={handleFixSize}
                  className={`px-2 sm:px-3 py-1 text-sm font-medium text-white rounded-md ${
                    !inputFixSizeValue ? "bg-gray-400 cursor-not-allowed" : "bg-black "
                  }`}
                >
                  Update
                </button>
                <button onClick={handleReset} className="p-1 text-gray-600 hover:text-gray-900 focus:outline-none">
                  <RefreshCw className="h-5 w-5" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )

  const renderFooter = () => (
    <div className="px-4 sm:px-6 py-4 bg-gray-50 border-t rounded-[8px] border-gray-200">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-gray-700">Total Amount:</p>
          <p className="text-2xl font-semibold text-gray-900">₹ {calculateTotalAmount()}</p>
        </div>
        <button
          className={` px-6 py-2 text-[18px] font-medium rounded-md font-poppins text-white
        w-[160px] h-[40px] flex items-center justify-center  ${
          hasZeroQuantity ? "bg-gray-300 cursor-not-allowed" : "bg-black "
        } text-white `}
          onClick={placeOrder}
          disabled={hasZeroQuantity}
        >
          {loading ? <LoadingSpinner /> : "Place Order"}
        </button>
      </div>
    </div>
  )

  return (
    <Dialog open={openReviewTrade} onOpenChange={setOpenReviewTrade}>
      <DialogContent className="sm:max-w-[600px] p-0">
        {renderContent()}
        {renderFooter()}
      </DialogContent>
    </Dialog>
  )
}

export default ReviewTradeModel

