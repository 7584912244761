import React, { useState } from "react";
import { X, Send } from "lucide-react";
import server from "../../../../utils/serverConfig";
import axios from "axios";
import toast from "react-hot-toast";
import ClosePositionPopup from "./ClosePositionPopUp";
import ClosureAdvicePopup from "./ClosureAdvicePopup";

import TableHeader from "../../TableComponent/TableHeader";
import TableRow from "../../TableComponent/TableRow";
import useWebSocketCurrentPrice from "../../../../FunctionCall/useWebSocketCurrentPrice";

import { encryptApiKey } from "../../../../utils/cryptoUtils";

const StickyFooter = ({
  selectedAdvices = new Set(),
  handleSendClosureAdvice,
}) => {
  return (
    <div className="px-6 py-4 w-full border-t bg-gray-50 sticky bottom-0 z-10 flex items-center">
      <div className="flex items-center space-x-4">
        <button
          onClick={handleSendClosureAdvice}
          className={`px-4 py-2 text-white rounded-md transition-colors flex items-center ${
            selectedAdvices.size === 0
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-600 hover:bg-blue-700"
          }`}
          disabled={selectedAdvices.size === 0}
        >
          <Send className="w-4 h-4 mr-2" />
          Send closure advice
        </button>
        <span className="text-md text-gray-600 font-medium">
          {selectedAdvices.size} advice{selectedAdvices.size !== 1 ? "s" : ""}{" "}
          selected
        </span>
      </div>
    </div>
  );
};

const EquityAdvice = ({
  onClose,
  combinedAdviceData,
  emailData,
  fetchAdviceData,
  isLoading,
  errorMessage,
  setCombinedAdviceData,
  exchange,
  getPageNumbers,
  goToPage,
  tabData,
}) => {
  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const advisorSpecifier = process.env.REACT_APP_ADVISOR_SPECIFIER;

  const [selectedAdvices, setSelectedAdvices] = useState(new Set());
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [emailPopupOpen, setEmailPopupOpen] = useState(null);
  const [mergedData, setMergedData] = useState({});
  const [loading, setLoading] = useState(false);

  const [closePositionPopup, setClosePositionPopup] = useState({
    isOpen: false,
    type: null,
    advice: null,
  });
  const [isClosureAdvicePopupOpen, setIsClosureAdvicePopupOpen] =
    useState(false);
  const handleSendClosureAdvice = () => {
    setIsClosureAdvicePopupOpen(true);
  };

  const mergeEmailData = (emailData) => {
    const mergedData = {};

    Object.keys(emailData).forEach((adviceId) => {
      if (!mergedData[adviceId]) {
        mergedData[adviceId] = [];
      }
      mergedData[adviceId] = [...mergedData[adviceId], ...emailData[adviceId]];
    });

    return mergedData;
  };
  const mergedEmails = mergeEmailData(emailData);

  // Function to get emails by adviceId
  const getEmailsByAdviceId = (adviceId) => {
    return mergedEmails[adviceId] || []; // Return emails for the given ID, or an empty array if not found
  };

  const handleCheckboxChange = (id, adviceIndex) => {
    setSelectedAdvices((prevSelected) => {
      const newSelected = new Set(prevSelected);
      const key = `${id}-${adviceIndex}`;

      // Find the related advice data
      const item = combinedAdviceData.find((item) => item.id === id);
      if (!item) return prevSelected;

      const advice = item.advices[adviceIndex];
      if (!advice || advice.closurestatus === "fullClose") {
        return prevSelected; // Skip selection
      }

      if (newSelected.has(key)) {
        newSelected.delete(key);
        // Remove merged data if the checkbox is unchecked
        setMergedData((prevMergedData) => {
          const newMergedData = { ...prevMergedData };
          delete newMergedData[key];
          return newMergedData;
        });
      } else {
        newSelected.add(key);

        const emailData = item?.emails?.find(
          (email) => email?.advice_reco_id === id
        );

        // Merge the advice data with the email data
        const mergedAdviceData = {
          ...advice,
          ...emailData,
          symbol: advice.symbol,
          type: advice.type,
          price: advice.price,
          exchange: advice.exchange,
          date: advice.date,
          quantity: advice.quantity,
          order_type: advice.order_type,
          product_type: advice.product_type || advice.productType,
          rationale: advice.rationale,
          comments: advice.comments,
          comment2: advice.comment2,
        };

        // Remove old `orderType` and `productType` keys
        delete mergedAdviceData.orderType;
        delete mergedAdviceData.productType;

        // Store the merged data
        setMergedData((prevMergedData) => ({
          ...prevMergedData,
          [key]: mergedAdviceData,
        }));
      }

      setIsAllSelected(
        newSelected.size ===
          combinedAdviceData.reduce((sum, item) => sum + item.advices.length, 0)
      );

      return newSelected;
    });
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedAdvices(new Set());
    } else {
      const allKeys = new Set(
        combinedAdviceData.flatMap(
          (item) =>
            item.advices
              .map((advice, index) =>
                advice?.closurestatus !== "fullClose"
                  ? `${item?.id}-${index}`
                  : null
              )
              .filter(Boolean) // Remove null values (i.e., fullClose advices)
        )
      );
      setSelectedAdvices(allKeys);
    }
    setIsAllSelected(!isAllSelected);
  };

  const { getLTPForSymbol } = useWebSocketCurrentPrice(
    closePositionPopup?.selectedAdvices
  );
  const handleClosePosition = (id, adviceIndex, type) => {
    const key = `${id}-${adviceIndex}`;
    setSelectedAdvices((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (!newSelected.has(key)) {
        newSelected.add(key);
      }
      return newSelected;
    });

    // Find the advice data
    const item = combinedAdviceData.find((item) => item.id === id);
    const advice = item.advices[adviceIndex];

    // Merge the advice data
    const mergedAdviceData = {
      ...advice,
      symbol: advice.symbol,
      type: advice.type,
      price: advice.price,
      exchange: advice.exchange,
      date: advice.date,
      quantity: advice.quantity,
      order_type: advice.order_type,
      product_type: advice.product_type || advice.productType,
      rationale: advice.rationale,
      comments: advice.comments,
      comment2: advice.comment2,
    };

    setMergedData((prevMergedData) => ({
      ...prevMergedData,
      [key]: mergedAdviceData,
    }));

    setClosePositionPopup({
      isOpen: true,
      type,
      selectedAdvices: [mergedAdviceData],
    });
  };

  const handleConfirmClosePosition = async (type, updatedAdvices) => {
    try {
      // Create a new Map to store the updated values
      const updatedMergedData = { ...mergedData };

      updatedAdvices.forEach((advice) => {
        const key = `${advice.advice_reco_id}-0`;

        // Update mergedData with the new values
        updatedMergedData[key] = {
          ...advice,
          order_type: advice.order_type,
          OrderType: advice.order_type,
          price: advice.order_type === "LIMIT" ? advice.price : "0.00",
          type: advice.type,
          quantity: advice.quantity,
          rationale: advice.rationale,
          comments: advice.comments,
          comment2: advice.comment2,
        };
      });

      // Update the mergedData state with new values
      setMergedData(updatedMergedData);

      // Format selected advices for the API call
      const formattedSelectedAdvices = updatedAdvices.map(
        (advice) => `${advice.advice_reco_id}-0`
      );

      // Call handleSendAdvices with the updated data
      await handleSendAdvices({
        closurestatus: type === "full" ? "fullClose" : "partialClose",
        selectedAdvices: formattedSelectedAdvices,
        updatedMergedData: updatedMergedData, // Pass the updated data
      });

      setClosePositionPopup({ isOpen: false, type: null, selectedAdvices: [] });
    } catch (error) {
      console.error("Error closing positions:", error);
    }
  };

  const toggleType = (originalType) => {
    return originalType === "BUY" ? "SELL" : "BUY";
  };

  const handleSendAdvices = async (options = {}) => {
    setLoading(true);

    try {
      const selectedAdvicesArray = Array.from(
        options.selectedAdvices || selectedAdvices
      );

      // Use the updated merged data if available, otherwise use the existing mergedData
      const currentMergedData = options.updatedMergedData || mergedData;

      const selectedAdvicesData = selectedAdvicesArray
        .map((key) => {
          const advice = currentMergedData[key];
          if (!advice) {
            console.error(`Advice not found for ID: ${key}`);
            return null;
          }

          const emails = getEmailsByAdviceId(advice.advice_reco_id) || [];

          const baseAdvice = {
            email: "",
            Symbol: advice.symbol,
            Exchange: advice.exchange,
            Type: toggleType(advice.type),
            OrderType: advice.order_type, // This will now use the updated order_type
            ProductType: advice.product_type,
            Segment: advice.segment,
            Price: advice.order_type === "LIMIT" ? advice.price : "0.00",
            date: advice.date,
            Quantity: advice.quantity,
            Advised_Range_Lower: advice.Advised_Range_Lower || 0,
            Advised_Range_Higher: advice.Advised_Range_Higher || 0,
            rationale: advice.rationale || "",
            comments: advice.comments || "",
            comment2: advice.comment2 || "",
            advisorType: advisorSpecifier || "",
            price_when_send_advice: getLTPForSymbol(advice?.symbol),
            group: advice.group,
            advice_id: advice.advice_reco_id,
            closurestatus: options.closurestatus || null,
          };

          return emails.length === 0
            ? [baseAdvice]
            : emails.map((email) => ({
                ...baseAdvice,
                email: email?.email,
                userName: email?.clientName || "",
                phoneNumber: email?.phone || "",
                country_code: email?.country_code || "91",
                advisor_name: advisorTag,
              }));
        })
        .flat()
        .filter(Boolean);

      // Rest of your code remains the same...
      const config = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}comms/send-reco`,
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
        data: selectedAdvicesData,
      };

      await axios.request(config);
      setLoading(false);
      fetchAdviceData();

      // Success handling...
      toast.success("Advice has been sent successfully", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#4CAF50",
          secondary: "#FFFAEE",
        },
      });

      setTimeout(() => {
        setIsClosureAdvicePopupOpen(false);
        setSelectedAdvices(new Set());
      }, 2000);
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Error in sending Advice!", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      throw error;
    }
  };

  const selectedAdvicesData = Array.from(selectedAdvices).map((key) => {
    return mergedData[key];
  });

  const handleOpenEmailPopup = (id) => {
    setEmailPopupOpen(id);
  };

  const handleCloseEmailPopup = () => {
    setEmailPopupOpen(null);
  };

  const handleAdviceFieldUpdate = (id, adviceIndex, field, value) => {
    const key = `${id}-${adviceIndex}`;

    setMergedData((prevMergedData) => ({
      ...prevMergedData,
      [key]: {
        ...prevMergedData[key],
        [field]: value,
        // Only initialize price with LTP if switching to LIMIT and no price exists
        ...(field === "order_type" &&
          value === "LIMIT" &&
          !prevMergedData[key].price && {
            price: getLTPForSymbol(prevMergedData[key].symbol),
          }),
        // Clear price if switching away from LIMIT
        ...(field === "order_type" &&
          value !== "LIMIT" && {
            price: "",
          }),
      },
    }));
  };

  const handlePriceChange = (id, adviceIndex, value) => {
    const key = `${id}-${adviceIndex}`;

    setMergedData((prevMergedData) => ({
      ...prevMergedData,
      [key]: {
        ...prevMergedData[key],
        price: value,
      },
    }));
  };

  const handleOrderTypeChange = (id, adviceIndex, newOrderType) => {
    handleAdviceFieldUpdate(id, adviceIndex, "order_type", newOrderType);
  };

  const handleQuantityChange = (id, adviceIndex, newQuantity) => {
    handleAdviceFieldUpdate(id, adviceIndex, "quantity", newQuantity);
  };

  const handleTypeChange = (id, adviceIndex, newType) => {
    handleAdviceFieldUpdate(id, adviceIndex, "type", newType);
  };

  const handleRationaleChange = (id, adviceIndex, newRationale) => {
    handleAdviceFieldUpdate(id, adviceIndex, "rationale", newRationale);
  };

  const handleCommentChange = (id, adviceIndex, newComment) => {
    handleAdviceFieldUpdate(id, adviceIndex, "comments", newComment);
  };

  const handleComment2Change = (id, adviceIndex, newComment2) => {
    handleAdviceFieldUpdate(id, adviceIndex, "comment2", newComment2);
  };

  // Generic reset handler
  const handleResetField = (id, adviceIndex, field) => {
    const item = combinedAdviceData.find((item) => item.id === id);
    const originalAdvice = item?.advices[adviceIndex];

    if (!originalAdvice) return;

    const originalValue = originalAdvice[field];
    handleAdviceFieldUpdate(id, adviceIndex, field, originalValue);
  };

  // Specific reset handlers
  const handleResetOrderType = (id, adviceIndex) => {
    handleResetField(id, adviceIndex, "order_type");
  };

  const handleResetQuantity = (id, adviceIndex) => {
    handleResetField(id, adviceIndex, "quantity");
  };

  const handleResetType = (id, adviceIndex) => {
    handleResetField(id, adviceIndex, "type");
  };

  const handleResetRationale = (id, adviceIndex) => {
    handleResetField(id, adviceIndex, "rationale");
  };

  const handleResetComment = (id, adviceIndex) => {
    handleResetField(id, adviceIndex, "comments");
  };

  const handleResetComment2 = (id, adviceIndex) => {
    handleResetField(id, adviceIndex, "comment2");
  };

  return (
    <div className="w-full h-screen bg-white rounded-xl shadow-xl overflow-hidden flex flex-col font-sans">
      <div className="flex items-center justify-between px-6 py-4 border-b bg-gray-50 sticky top-0 z-10">
        <h2 className="text-xl font-bold text-gray-400 ">
          Close any of your open equity advices
        </h2>
        <button
          onClick={onClose}
          className="p-2 text-gray-600 hover:text-gray-800 hover:bg-gray-200 rounded-full transition-colors "
        >
          <X className="w-6 h-6" />
        </button>
      </div>

      <div className="flex-1 overflow-auto">
        {isLoading ? (
          <div className="w-full flex text-center justify-center py-12">
            <div className="w-10 h-10 border-4 border-blue-[#2056DF] border-t-transparent rounded-full animate-spin" />
          </div>
        ) : combinedAdviceData?.length !== 0 ? (
          <table className="w-full">
            <TableHeader
              isAllSelected={isAllSelected}
              handleSelectAllChange={handleSelectAllChange}
            />
            <TableRow
              combinedAdviceData={combinedAdviceData}
              handleCheckboxChange={handleCheckboxChange}
              selectedAdvices={selectedAdvices}
              handleClosePosition={handleClosePosition}
              handleTypeChange={handleTypeChange}
              handleQuantityChange={handleQuantityChange}
              handleOrderTypeChange={handleOrderTypeChange}
              handleResetOrderType={handleResetOrderType}
              emailPopupOpen={emailPopupOpen}
              handleCloseEmailPopup={handleCloseEmailPopup}
              handleOpenEmailPopup={handleOpenEmailPopup}
              handleResetQuantity={handleResetQuantity}
              handleResetType={handleResetType}
              getEmailsByAdviceId={getEmailsByAdviceId}
              exchange={["NSE", "BSE"]}
            />
          </table>
        ) : (
          <div className="text-center font-bold text-xl py-12">
            No Data Available
          </div>
        )}

        {errorMessage && (
          <div className="flex items-center justify-center h-full  text-3xl text-gray-400">
            {errorMessage}
          </div>
        )}
      </div>
      <div className="flex justify-center mt-2 space-x-2 pb-3">
        <button
          onClick={() => goToPage(tabData.currentPage - 1)}
          disabled={tabData.currentPage === 1}
          className="px-3 py-1 border rounded disabled:opacity-50"
        >
          Previous
        </button>

        {getPageNumbers().map((page, index) =>
          page === "..." ? (
            <span key={index} className="px-3 py-1">
              ...
            </span>
          ) : (
            <button
              key={index}
              onClick={() => goToPage(page)}
              className={`px-3 py-1 border rounded ${
                tabData.currentPage === page ? "bg-blue-500 text-white" : ""
              }`}
            >
              {page}
            </button>
          )
        )}

        <button
          onClick={() => goToPage(tabData.currentPage + 1)}
          disabled={tabData.currentPage === tabData.totalPages}
          className="px-3 py-1 border rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
      <ClosePositionPopup
        isOpen={closePositionPopup.isOpen}
        onClose={() =>
          setClosePositionPopup({
            isOpen: false,
            type: null,
            selectedAdvices: [],
          })
        }
        onConfirm={handleConfirmClosePosition}
        type={closePositionPopup.type}
        selectedAdvices={closePositionPopup.selectedAdvices}
        loading={loading}
        handleTypeChange={handleTypeChange}
        handleResetType={handleResetType}
        handleOrderTypeChange={handleOrderTypeChange}
        handleResetOrderType={handleResetOrderType}
        handleRationaleChange={handleRationaleChange}
        handleResetRationale={handleResetRationale}
        handleCommentChange={handleCommentChange}
        handleResetComment={handleResetComment}
        handleComment2Change={handleComment2Change}
        handleResetComment2={handleResetComment2}
        getLTPForSymbol={getLTPForSymbol}
        handlePriceChange={handlePriceChange}
      />
      <ClosureAdvicePopup
        isOpen={isClosureAdvicePopupOpen}
        onClose={() => setIsClosureAdvicePopupOpen(false)}
        selectedAdvices={selectedAdvicesData}
        onSend={handleSendAdvices}
        getLTPForSymbol={getLTPForSymbol}
      />
      <StickyFooter
        selectedAdvices={selectedAdvices}
        onSendAdvices={() => handleSendAdvices()}
        handleSendClosureAdvice={handleSendClosureAdvice}
      />
    </div>
  );
};

export default EquityAdvice;
