import moment from "moment";
import React from "react";
import HistoryIcon from "../../assests/history_icon.svg";

const TradeHistoryTable = ({ data, broker }) => {
  return (
    <div className="w-full border-t-[1px] mt-10   border-[#000000]/10 h-[calc(100vh-260px)]   lg:h-[calc(100vh-200px)] overflow-auto custom-scroll">
      {data?.filter(
        (stock) =>
          stock.hasOwnProperty("exited_quantity") &&
          stock?.user_broker === broker
      ).length !== 0 ? (
        <table className={`w-full `}>
          <thead className="bg-[#f5f5f5]  text-sm text-left sticky top-0">
            <tr className="border-b-[1px]   border-[#000000]/10">
              <th className=" min-w-[150px] text-[13px] border-r-[1px]  border-[#000000]/10 text-gray-900  font-medium py-3 px-4 lg:px-5">
                Date of Exit
              </th>
              <th className=" min-w-[130px] text-[13px] border-r-[1px]  border-[#000000]/10 text-gray-900  font-medium text-left py-3 px-4 lg:px-8">
                Stock Symbol
              </th>
              <th className=" min-w-[140px] text-[13px] border-r-[1px]  border-[#000000]/10 text-gray-900 text-center  font-medium py-3 lg:px-5">
                <div className="flex flex-col ">
                  Entry Qty
                  <span className="text-[12px]">(Actual | Advised)</span>
                </div>
              </th>
              <th className=" min-w-[140px]  text-[13px] border-r-[1px]  border-[#000000]/10 text-gray-900 text-center  font-medium py-3 lg:px-5">
                <div className="flex flex-col ">
                  Entry Price
                  <span className="text-[12px]">(Actual | Advised)</span>
                </div>
              </th>
              <th className=" min-w-[140px]   text-[13px] border-r-[1px]  border-[#000000]/10 text-gray-900 text-center  font-medium py-3 lg:px-5">
                <div className="flex flex-col ">
                  Exit Qty
                  <span className="text-[12px]">(Actual | Advised)</span>
                </div>
              </th>
              <th className=" min-w-[140px]   text-[13px] border-r-[1px]  border-[#000000]/10 text-gray-900 text-center  font-medium py-3 lg:px-5">
                <div className="flex flex-col ">
                  Exit Price
                  <span className="text-[12px]">(Actual | Advised)</span>
                </div>
              </th>
              <th className=" min-w-[130px] text-[13px] border-r-[1px]  border-[#000000]/10 text-gray-900 text-center  font-medium py-3 lg:px-5">
                Realized Profit{" "}
              </th>
              <th className="min-w-[150px] text-[13px] border-r-[1px]  border-[#000000]/10 text-gray-900 text-center  font-medium py-3 lg:px-5">
                Date of Purchase
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data
                ?.filter(
                  (stock) =>
                    stock.hasOwnProperty("exited_quantity") &&
                    stock?.user_broker === broker
                )
                ?.sort((a, b) => {
                  // Sort by the sell exitDate or buy purchaseDate in descending order
                  const dateA = new Date(a.sell[0]?.exitDate);
                  const dateB = new Date(b.sell[0]?.exitDate);
                  return dateB - dateA; // For descending order (latest date first)
                })
                ?.map((ele, i) => {
                  return (
                    <tr key={i} className="border-b-[1px] border-[#000000]/10">
                      <td className="text-[15px] border-r-[1px] border-[#000000]/10 text-gray-900  font-medium text-center py-4 px-5">
                        {ele?.sell[0]?.exitDate
                          ? moment(ele?.sell[0]?.exitDate).format("Do MMM YYYY")
                          : "-"}
                      </td>
                      <td className="text-[15px] border-r-[1px] border-[#000000]/10 text-gray-900  font-medium text-left py-4 px-8">
                        {ele.Symbol}
                      </td>
                      <td className="text-[15px] border-r-[1px] border-[#000000]/10 text-gray-900  font-medium text-center py-4 px-5">
                        {ele.buy[0]?.Quantity
                          ? `${ele.buy[0].Adviced_Quantity} / ${ele.buy[0].Quantity}`
                          : "-"}
                      </td>
                      <td className="text-[15px] border-r-[1px] border-[#000000]/10 text-gray-900  font-medium text-center py-4 px-5">
                        {ele.buy[0]?.tradedPrice || ele.buy[0]?.tradedPrice
                          ? `${ele.buy[0]?.tradedPrice || "-"} / ${
                              ele.buy[0]?.tradedPrice || "-"
                            }`
                          : "-"}
                      </td>
                      <td className="text-[15px] border-r-[1px] border-[#000000]/10 text-gray-900  font-medium text-center py-4 px-5">
                        {ele.sell[0]?.Quantity
                          ? `${ele.sell[0].Adviced_Quantity} / ${ele.sell[0].Quantity}`
                          : "-"}
                      </td>
                      <td className="text-[15px] border-r-[1px] border-[#000000]/10 text-gray-900  font-medium text-center py-4 px-5">
                        {ele.sell[0]?.exitPrice
                          ? `${ele.sell[0].exitPrice} / ${ele.sell[0].exitPrice}`
                          : "-"}
                      </td>
                      <td className="text-[15px] border-r-[1px] border-[#000000]/10 text-gray-900  font-medium text-center py-4 px-5">
                        {ele?.pnl > 0 ? (
                          <span className="text-[#16A085] font-medium">
                            +₹{ele?.pnl ? ele?.pnl.toFixed(2) : "-"}
                          </span>
                        ) : ele?.pnl < 0 ? (
                          <span className="text-[#E43D3D] font-medium">
                            -₹{Math.abs(ele?.pnl)}
                          </span>
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                      <td className="text-[15px] border-r-[1px] border-[#000000]/10 text-gray-900  font-medium text-center py-4 px-5">
                        {ele?.buy[0]?.purchaseDate
                          ? moment(ele?.buy[0]?.purchaseDate).format(
                              "Do MMM YYYY"
                            )
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      ) : (
        <div className="w-full  h-[calc(100vh-220px)]  lg:h-[calc(100vh-200px)] overflow-auto custom-scroll">
          <table className={`w-full `}>
            <thead className="">
              <tr className="border-b-[1px] border-t-[1px]  border-[#000000]/10">
                <th className=" min-w-[130px] text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/60  font-medium py-3 lg:px-5">
                  Date of Exit
                </th>
                <th className=" min-w-[130px] lg: text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/60  font-medium text-left py-3 px-4 lg:px-8">
                  Stock Name
                </th>
                <th className=" min-w-[130px] text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/60  font-medium py-3 lg:px-5">
                  <div className="flex flex-col ">
                    Entry Qty
                    <span className="text-[12px]">(Actual | Advised)</span>
                  </div>
                </th>
                <th className=" min-w-[130px]  text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/60  font-medium py-3 lg:px-5">
                  <div className="flex flex-col ">
                    Entry Price
                    <span className="text-[12px]">(Actual | Advised)</span>
                  </div>
                </th>
                <th className=" min-w-[130px]   text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/60  font-medium py-3 lg:px-5">
                  <div className="flex flex-col ">
                    Exit Qty
                    <span className="text-[12px]">(Actual | Advised)</span>
                  </div>
                </th>
                <th className=" min-w-[130px]   text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/60  font-medium py-3 lg:px-5">
                  <div className="flex flex-col ">
                    Exit Price
                    <span className="text-[12px]">(Actual | Advised)</span>
                  </div>
                </th>
                <th className=" min-w-[130px] text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/60  font-medium py-3 lg:px-5">
                  Realized Profit{" "}
                </th>
                <th className="min-w-[130px] text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/60  font-medium py-3 lg:px-5">
                  Date of Purchase
                </th>
              </tr>
            </thead>
          </table>
          <div className="mt-16 w-full flex flex-col items-center justify-center">
            <div className="flex pl-[8px] items-center justify-center w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70 ">
              <img
                src={HistoryIcon}
                alt="History Icon"
                className="w-[60px] lg:w-[80px]"
              />
            </div>
            <div className="flex flex-col space-y-3 lg:space-y-5 items-center px-6 lg:w-[800px]">
              <div className="text-black text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
                No Trade History
              </div>
              <div className="md:max-w-[650px] text-center text-[14px] lg:text-[18px] text-[#000000]/60 lg:leading-[24px] font-normal  px-[10px] lg:px-[60px]">
                No Trades have been recorded yet, according to your filter
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TradeHistoryTable;
