// All global variables and configuration setting

const appConfig = {
  // UI colors
  colors: {
    headerBlueTheme: "bg-gradient-to-r from-blue-600 to-blue-700",
  },

  // Text content  for footer
  footerText: {
    company: "",
    address: "",
    contactNumber: "",
    email: "hello@alphaquark.in",
  },
};

export default appConfig;
