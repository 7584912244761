import React, { useState } from "react";
import Fade1 from "./assests/process_section/1.png";
import Fade2 from "./assests/process_section/2.png";
import Fade3 from "./assests/process_section/3.png";
import Fade4 from "./assests/process_section/4.png";
import Fade5 from "./assests/process_section/5.png";

import RightSection1 from "./assests/process_section/rightSection1.png";
import RightSection2 from "./assests/process_section/rightSection2.png";
import RightSection3 from "./assests/process_section/rightSection3.png";
import RightSection4 from "./assests/process_section/rightSection4.png";
import RightSection5 from "./assests/process_section/rightSection5.png";

import Model1 from "./assests/process_section/Modern_plan/Model1.png";
import Model2 from "./assests/process_section/Modern_plan/Model2.png";
import Model3 from "./assests/process_section/Modern_plan/Model3.png";

const ProcessSection = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabSwitch = (index) => {
    setActiveTab(index);
  };

  const modelPortfolioSteps = [
    {
      icon: Fade1,
      title: "SUBSCRIBE TO A MODEL PORTFOLIO",
      description: "Choose a model portfolio aligned with your investment goals. Subscribing provides access to expertly curated stock strategies tailored for growth, stability, or income.",
      image: RightSection1,
    },
    {
      icon: Fade2,
      title: "CONNECT YOUR BROKER",
      description: "Link your brokerage account securely to execute trades directly. This ensures a seamless trading experience and real-time updates on portfolio actions.",
      image: RightSection2,
    },
    {
      icon: Fade3,
      title: "REVIEW & PLACE TRADES",
      description: "Preview the recommended trades before confirming. This allows you to understand the changes being made and align them with your preferences.",
      image: RightSection3,
    },
    {
      icon: Fade4,
      title: "ORDER STATUS CONFIRMATION",
      description: "Preview the recommended trades before confirming. This allows you to understand the changes being made and align them with your preferences.",
      image: RightSection4,
    },
    {
      icon: Fade5,
      title: "REBALANCE ALERTS",
      description: "Rebalancing keeps your portfolio in line with the strategy, optimizing performance and managing risk by buying new stocks and selling old ones.",
      image: RightSection5,
    }
  ];

  const stockTipsSteps = [
    {
      icon: Fade1,
      title: "Invest in a curated stock recommendation plan",
      description: "Choose and invest in stocks that suits your strategy.",
      image: Model1,
    },
    {
      icon: Fade2,
      title: "Invest in the click of a button",
      description: "Monitor the performance of your model portfolio on your dashboard.",
      image: Model2,
    },
    {
      icon: Fade3,
      title: "Track your portfolio",
      description: "Accept periodic rebalancing requests. If not, a repair request will be sent by your advisor to improve portfolio health.",
      image: Model3,
    }
  ];

  return (
    <div className="py-20 bg-gradient-to-b from-[#f8fafc] to-white" id="modelPortfolio">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl md:text-5xl font-bold text-center text-gray-900 mb-12">
          HOW TO INVEST
        </h2>

        {/* Tabs */}
        <div className="flex justify-center mb-16">
          <div className="inline-flex rounded-lg p-1 bg-gray-100">
            <button
              className={`px-6 py-3 text-sm md:text-base font-medium rounded-md transition-all duration-200 ${
                activeTab === 0
                  ? "bg-[#4682B4] text-white shadow-md"
                  : "text-gray-700 hover:bg-[#4682B4]/10"
              }`}
              onClick={() => handleTabSwitch(0)}
            >
              Model Portfolio Plan
            </button>

            <button
              className={`px-6 py-3 text-sm md:text-base font-medium rounded-md transition-all duration-200 ${
                activeTab === 1
                  ? "bg-[#4682B4] text-white shadow-md"
                  : "text-gray-700 hover:bg-[#4682B4]/10"
              }`}
              onClick={() => handleTabSwitch(1)}
            >
              Stock Tips Plan
            </button>
          </div>
        </div>

        {/* Model Portfolio Tab Content */}
        {activeTab === 0 && (
          <div className="space-y-8 md:space-y-0">
            {modelPortfolioSteps.map((step, index) => (
              <ProcessStep
                key={index}
                step={step}
                index={index}
                total={modelPortfolioSteps.length}
                isActive={index === 0}
              />
            ))}
          </div>
        )}

        {/* Stock Tips Tab Content */}
        {activeTab === 1 && (
          <div className="space-y-8 md:space-y-0">
            {stockTipsSteps.map((step, index) => (
              <ProcessStep
                key={index}
                step={step}
                index={index}
                total={stockTipsSteps.length}
                isActive={index === 0}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const ProcessStep = ({ step, index, total, isActive }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`relative transition-all duration-300 p-6 rounded-xl mb-12 ${
        isHovered ? 'bg-[#4682B4]/5' : 'bg-transparent'
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Timeline */}
      <div className="absolute left-8 top-0 h-full flex flex-col items-center">
        <div className={`w-10 h-10 rounded-full flex items-center justify-center z-10 ${
          isActive || isHovered ? 'bg-[#4682B4]' : 'bg-white border-2 border-gray-300'
        }`}>
          <span className={`${
            isActive || isHovered ? 'text-white' : 'text-gray-500'
          } font-bold`}>{index + 1}</span>
        </div>

        {index < total - 1 && (
          <div className={`w-1 flex-grow ${
            isActive || isHovered ? 'bg-[#4682B4]' : 'bg-gray-200'
          } mt-2`}></div>
        )}
      </div>

      {/* Content */}
      <div className="ml-24 flex flex-col md:flex-row items-start gap-8">
        <div className="md:w-2/3">
          <div className="flex items-center mb-4">
            <img src={step.icon} alt="Icon" className="w-16 h-16 mr-4" />
            <h3 className={`text-xl md:text-2xl font-bold ${
              isHovered ? 'text-[#4682B4]' : 'text-gray-900'
            } transition-colors duration-300`}>{step.title}</h3>
          </div>

          <p className="text-gray-600 leading-relaxed">{step.description}</p>

          {isHovered && (
            <button className="mt-6 bg-[#4682B4] text-white px-5 py-2 rounded-md font-medium hover:bg-[#3a6d94] transition-colors duration-200">

            </button>
          )}
        </div>

        <div className="md:w-1/3 flex justify-center md:justify-end">
          <img
            src={step.image}
            alt={step.title}
            className="w-full max-w-xs object-contain transform transition-transform duration-300 hover:scale-105"
          />
        </div>
      </div>
    </div>
  );
};

export default ProcessSection;