import React, { useState, useEffect } from "react";
import { X, RotateCcw } from "lucide-react";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import TableHeader from "../../TableComponent/TableHeader";

const ClosePositionPopup = ({
  isOpen,
  onClose,
  loading,
  onConfirm,
  type,
  selectedAdvices,
  getLTPForSymbol,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [updatedAdvices, setUpdatedAdvices] = useState([]);

  useEffect(() => {
    if (selectedAdvices?.length > 0) {
      setUpdatedAdvices(
        selectedAdvices.map((advice) => ({
          ...advice,
          id: advice.advice_reco_id,
          quantity: advice.quantity,
          type: advice.type === "BUY" ? "SELL" : "BUY", // Default to opposite type
          order_type: advice.order_type || "MARKET",
          product_type: advice.product_type || advice.productType,
          rationale: advice.rationale || "",
          comments: advice.comments || "",
          comment2: advice.comment2 || "",
          price: advice.price || "",
        }))
      );
    }
  }, [selectedAdvices]);

  if (!isOpen) return null;

  const handleInitialConfirm = () => {
    setShowConfirmation(false);
    setShowTable(true);
  };

  // Generic update function for any field
  const handleFieldUpdate = (id, field, value) => {
    setUpdatedAdvices((prev) =>
      prev.map((advice) => {
        if (advice.id === id || advice.advice_reco_id === id) {
          const updatedAdvice = { ...advice, [field]: value };

          // Special handling for LIMIT order type
          if (field === "order_type") {
            if (value === "LIMIT" && !updatedAdvice.price) {
              updatedAdvice.price = getLTPForSymbol(advice.symbol);
            } else if (value === "MARKET") {
              updatedAdvice.price = "0.00";
            }
          }

          return updatedAdvice;
        }
        return advice;
      })
    );
  };

  const handleFinalConfirm = () => {
    // Ensure all required fields are properly set before sending
    const finalizedAdvices = updatedAdvices.map((advice) => ({
      ...advice,
      OrderType: advice.order_type, // Ensure OrderType is set correctly
      price: advice.order_type === "LIMIT" ? advice.price : "0.00",
      comments: advice.comments || "", // Ensure comments are included
      comment2: advice.comment2 || "",
      rationale: advice.rationale || "",
    }));

    onConfirm(type, finalizedAdvices);
  };

  // Reset function for any field
  const handleFieldReset = (id, field) => {
    const originalAdvice = selectedAdvices.find((a) => a.id === id);
    if (originalAdvice) {
      let value = originalAdvice[field];
      if (field === "type") {
        value = originalAdvice.type === "BUY" ? "SELL" : "BUY";
      }
      handleFieldUpdate(id, field, value);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center">
      <div
        className={`bg-white rounded-lg p-6 w-full ${
          showTable ? "max-w-7xl" : "max-w-2xl"
        } max-h-[90vh] overflow-auto`}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">
            {type === "full"
              ? "Close Positions Fully"
              : "Close Positions Partially"}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {showConfirmation ? (
          <div>
            <p className="mb-4">
              Are you sure you want to {type === "full" ? "fully" : "partially"}{" "}
              close the selected positions?
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleInitialConfirm}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Confirm
              </button>
            </div>
          </div>
        ) : (
          showTable && (
            <>
              <div className="flex-grow overflow-auto">
                <table className="w-full mb-4">
                  <TableHeader closePositionPopUp={false} />
                  <tbody>
                    {updatedAdvices.map((advice) => {
                      return (
                        <tr key={advice.id}>
                          <td className="py-2 px-4 w-40 font-poppins">
                            {advice.symbol}
                          </td>

                          {/* Type Selection */}
                          <td className="py-2 px-4 font-poppins">
                            <div className="flex items-center space-x-2">
                              <select
                                value={advice.type}
                                onChange={(e) =>
                                  handleFieldUpdate(
                                    advice.id,
                                    "type",
                                    e.target.value
                                  )
                                }
                                className="border rounded px-2 py-1 font-poppins text-md w-24"
                              >
                                <option value="BUY">BUY</option>
                                <option value="SELL">SELL</option>
                              </select>
                              <button
                                onClick={() =>
                                  handleFieldReset(advice.id, "type")
                                }
                                className="p-1 text-gray-500 hover:text-gray-700"
                              >
                                <RotateCcw className="h-4 w-4" />
                              </button>
                            </div>
                          </td>

                          <td className="py-2 px-4 font-poppins">
                            {advice.exchange}
                          </td>
                          <td className="py-2 px-4 font-poppins">
                            {getLTPForSymbol(advice?.symbol)}
                          </td>

                          {/* Quantity Input */}
                          {process.env.REACT_APP_ADVISOR_SPECIFIER ===
                            "RIA" && (
                            <td className="py-2 px-4 font-poppins">
                              <input
                                type="number"
                                value={advice.quantity}
                                onChange={(e) =>
                                  handleFieldUpdate(
                                    advice.id,
                                    "quantity",
                                    e.target.value
                                  )
                                }
                                className="border rounded px-2 py-1 w-20"
                                max={advice.quantity}
                                disabled={type === "full"}
                              />
                            </td>
                          )}

                          {/* Order Type Selection */}
                          <td className="py-2 px-4 font-poppins">
                            <div className="flex items-center space-x-2">
                              <select
                                value={advice.order_type}
                                onChange={(e) =>
                                  handleFieldUpdate(
                                    advice.id,
                                    "order_type",
                                    e.target.value
                                  )
                                }
                                className="border rounded px-2 py-1 font-poppins text-md w-32"
                              >
                                <option value="MARKET">MARKET</option>
                                <option value="LIMIT">LIMIT</option>
                                <option value="STOP">STOP</option>
                              </select>
                              <button
                                onClick={() =>
                                  handleFieldReset(advice.id, "order_type")
                                }
                                className="p-1 text-gray-500 hover:text-gray-700"
                              >
                                <RotateCcw className="h-4 w-4" />
                              </button>
                            </div>
                          </td>

                          <td>
                            {advice.order_type === "LIMIT" ||
                            advice.order_type === "STOP" ? (
                              <div className="flex items-center space-x-2">
                                <input
                                  type="number"
                                  value={advice.price || ""}
                                  onChange={(e) =>
                                    handleFieldUpdate(
                                      advice.id,
                                      "price",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter price"
                                  className="border rounded px-2 py-1 font-poppins text-md w-32"
                                />
                              </div>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="py-2 px-4 font-poppins">
                            {advice.product_type}
                          </td>
                          <td className="py-2 px-4 font-poppins">
                            {advice.segment}
                          </td>

                          {/* Rationale */}
                          <td className="py-2 px-4 w-96 h-24">
                            <div className="flex items-center space-x-2">
                              <textarea
                                value={advice.rationale || ""}
                                onChange={(e) =>
                                  handleFieldUpdate(
                                    advice.id,
                                    "rationale",
                                    e.target.value
                                  )
                                }
                                className="border rounded px-2 py-1 font-poppins text-md w-80 h-24 resize-y"
                              />
                              <button
                                onClick={() =>
                                  handleFieldReset(advice.id, "rationale")
                                }
                                className="p-1 text-gray-500 hover:text-gray-700"
                              >
                                <RotateCcw className="h-4 w-4" />
                              </button>
                            </div>
                          </td>

                          {/* Comments */}
                          <td className="py-2 px-4 w-96 h-24">
                            <div className="flex items-center space-x-2">
                              <textarea
                                value={advice.comments || ""}
                                onChange={(e) =>
                                  handleFieldUpdate(
                                    advice.id,
                                    "comments",
                                    e.target.value
                                  )
                                }
                                className="border rounded px-2 py-1 font-poppins text-md w-80 h-24 resize-y"
                              />
                              <button
                                onClick={() =>
                                  handleFieldReset(advice.id, "comments")
                                }
                                className="p-1 text-gray-500 hover:text-gray-700"
                              >
                                <RotateCcw className="h-4 w-4" />
                              </button>
                            </div>
                          </td>

                          {/* Comment2 */}
                          <td className="py-2 px-4 w-96 h-24">
                            <div className="flex items-center space-x-2">
                              <textarea
                                value={advice.comment2 || ""}
                                onChange={(e) =>
                                  handleFieldUpdate(
                                    advice.id,
                                    "comment2",
                                    e.target.value
                                  )
                                }
                                className="border rounded px-2 py-1 font-poppins text-md w-80 h-24 resize-y"
                              />
                              <button
                                onClick={() =>
                                  handleFieldReset(advice.id, "comment2")
                                }
                                className="p-1 text-gray-500 hover:text-gray-700"
                              >
                                <RotateCcw className="h-4 w-4" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="flex justify-end space-x-2 mt-4 pt-4 border-t">
                <button
                  onClick={onClose}
                  className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                >
                  Cancel
                </button>
                <button
                  onClick={handleFinalConfirm}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  {loading ? <LoadingSpinner /> : "Confirm"}
                </button>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default ClosePositionPopup;
