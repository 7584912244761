"use client";

import React, { useEffect, useState, useRef } from "react";
import { CircleCheck } from "lucide-react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { encryptApiKey, decryptApiKey } from "../utils/cryptoUtils";

const encryptedKey = encryptApiKey(
  process.env.REACT_APP_AQ_KEYS,
  process.env.REACT_APP_AQ_SECRET
);

const SubscriptionSection = ({
  allPricingPlan = [],
  handlePricingCardClick,
}) => {
  const [pricingPlans, setPricingPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const rowRefs = useRef([]);

  const [activeCycle, setActiveCycle] = useState("Monthly");

  const billingCycles = ["Monthly", "Quarterly", "Half-yearly", "Yearly"];

  const sortPricingPlans = (plans) => {
    return plans.sort((a, b) => {
      const aPrice =
        a.amount ||
        (a.pricing &&
          (a.pricing.monthly ||
            a.pricing.quarterly ||
            a.pricing.halfYearly ||
            a.pricing.yearly)) ||
        0;
      const bPrice =
        b.amount ||
        (b.pricing &&
          (b.pricing.monthly ||
            b.pricing.quarterly ||
            b.pricing.halfYearly ||
            b.pricing.yearly)) ||
        0;
      return bPrice - aPrice;
    });
  };

  useEffect(() => {
    if (allPricingPlan.length > 0) {
      setPricingPlans(sortPricingPlans(allPricingPlan));
      setIsLoading(false);
      setShowSkeleton(false); // Stop showing the skeleton when data is available
    } else {
      const timer = setTimeout(() => {
        setShowSkeleton(false); // Hide skeleton after 3 seconds
      }, 3000);

      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }
  }, [allPricingPlan]);

  useEffect(() => {
    if (!isLoading) {
      adjustCardHeights();
    }
  }, [isLoading, pricingPlans]);

  useEffect(() => {
    const h1 = document.querySelector("#subscriptionSection h1");
    if (h1) {
      h1.style.fontSize = "28px";
      h1.style.fontWeight = "bold";
      h1.style.textAlign = "center";
      h1.style.marginTop = "1rem";
      h1.style.paddingLeft = "1rem";
      h1.style.paddingRight = "1rem";
      h1.style.lineHeight = "1.2";

      const updateH1Style = () => {
        if (window.innerWidth >= 640) {
          h1.style.fontSize = "30px";
          h1.style.marginTop = "1.5rem";
        }
        if (window.innerWidth >= 768) {
          h1.style.fontSize = "36px";
          h1.style.marginTop = "2rem";
        }
      };

      updateH1Style();
      window.addEventListener("resize", updateH1Style);

      return () => {
        window.removeEventListener("resize", updateH1Style);
      };
    }
  }, []);

  const adjustCardHeights = () => {
    const rows = [];
    let currentRow = [];
    rowRefs.current.forEach((card, index) => {
      if (card) {
        currentRow.push(card);
        if ((index + 1) % 3 === 0 || index === rowRefs.current.length - 1) {
          rows.push(currentRow);
          currentRow = [];
        }
      }
    });

    rows.forEach((row) => {
      const descriptionContainers = row.map((card) =>
        card.querySelector(".description-container")
      );
      const hasDescription = descriptionContainers.some(
        (container) => container?.textContent?.trim().length > 0
      );

      if (hasDescription) {
        const maxHeight = Math.max(
          ...descriptionContainers.map(
            (container) => container?.scrollHeight || 0
          )
        );
        descriptionContainers.forEach((container) => {
          if (container) {
            container.style.height = `${maxHeight}px`;
          }
        });
      } else {
        descriptionContainers.forEach((container) => {
          if (container) {
            container.style.height = "auto";
          }
        });
      }
    });
  };
  const formatDescription = (description) => {
    if (!description) return ["No description available."];
    return description
      .split(".")
      .filter((sentence) => sentence.trim() !== "")
      .map((sentence) => sentence.trim() + ".");
  };

  const SkeletonCard = () => (
    <div className="w-full sm:w-[calc(50%-12px)] lg:w-[calc(33.333%-16px)] max-w-[350px] relative font-poppins min-h-[200px] rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4">
      <div className="flex items-start pl-7 pt-2 pb-7 md:items-center">
        <div className="overflow-hidden rounded-lg">
          <Skeleton width={128} height={25} />
          <Skeleton width={100} height={30} />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="w-[80%] border-t-[1px] border-b-[1px] border-[#000000]/10"></div>
      </div>
      <div className="flex flex-col items-center pt-7 p-4 space-y-4 w-90">
        <div className="font-poppins text-[12.5px] min-h-[220px] flex flex-col items-left justify-start space-y-2 text-[#666666]">
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
        </div>
      </div>
      <div className="p-4">
        <div className="flex justify-center align">
          <Skeleton width={280} height={44} />
        </div>
      </div>
    </div>
  );

  if (!showSkeleton && allPricingPlan.length === 0) {
    return null; // Return null after 3 seconds if no data
  }

  const filteredPlans = pricingPlans.filter((ele) => {
    if (!ele?.frequency || !Array.isArray(ele.frequency)) return false;

    // Check if the selected cycle exists inside the frequency array
    if (ele.frequency.includes("all")) return true; // Show in all tabs
    if (ele.frequency.includes("monthly") && activeCycle === "Monthly")
      return true;
    if (ele.frequency.includes("quarterly") && activeCycle === "Quarterly")
      return true;
    if (ele.frequency.includes("halfYearly") && activeCycle === "Half-yearly")
      return true;
    if (ele.frequency.includes("yearly") && activeCycle === "Yearly")
      return true;

    // If `duration` exists, map it accordingly
    if (ele.duration) {
      if (ele.duration === 30 && activeCycle === "Monthly") return true;
      if (ele.duration === 90 && activeCycle === "Quarterly") return true;
      if (ele.duration === 180 && activeCycle === "Half-yearly") return true;
      if (ele.duration === 365 && activeCycle === "Yearly") return true;
    }

    return false;
  });

  return (
    <div className="pt-12 pb-32" id="subscriptionSection">
      <h1>Stock Recommendations To Elevate Your Portfolio</h1>

      <div className="group relative flex flex-row pt-8 items-center gap-4 justify-center overflow-x-auto overflow-y-hidden border-gray-200 dark:border-gray-700 border-b-[1px] sm:border-b-[2px] sm:border-gray-400 dark:sm:border-gray-200 lg:gap-10 max-w-screen-md mx-auto">
        <div className="absolute inset-0 border-black opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none z-20"></div>
        {billingCycles.map((cycle) => (
          <button
            key={cycle}
            onClick={() => setActiveCycle(cycle)}
            className={`relative font-poppins text-[15px] lg:text-lg inline-flex items-center pb-2 font-bold h-30 px-4 -mb-px text-center border-b-[3px] sm:text-base ${
              activeCycle === cycle
                ? "text-black border-black z-10"
                : "text-[#808080] border-transparent hover:text-[#000000] hover:border-[#000000] z-10"
            }`}
          >
            {cycle}
            {activeCycle === cycle && (
              <div className="absolute bottom-0 left-0 w-full h-px bg-white z-10" />
            )}
          </button>
        ))}
      </div>

      <div className="container mx-auto px-4 sm:px-20 pt-10 md:pt-14 lg:pt-14">
        <div className="flex flex-wrap justify-center gap-6">
          {isLoading ? (
            Array(3)
              .fill(0)
              .map((_, i) => <SkeletonCard key={i} />)
          ) : filteredPlans.length > 0 ? (
            filteredPlans
              ?.filter((ele) => ele?.name !== "priorRecommendationPlan")
              ?.map((ele, i) => (
                <div
                  key={i}
                  ref={(el) => (rowRefs.current[i] = el)}
                  className="relative w-[350px] font-poppins rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4 cursor-pointer overflow-hidden"
                  onClick={
                    ele?.subscription
                      ? undefined
                      : () => handlePricingCardClick(ele)
                  }
                >
                  <div className="flex items-start pl-7 pt-2 pb-7 md:items-center">
                    <div className="overflow-hidden rounded-lg">
                      <h2 className="font-poppins  text-[22px] font-bold leading-[35px] text-left text-[#333333]">
                        {ele?.name}
                      </h2>
                      <div className="flex items-baseline">
                        <h2 className="text-3xl font-bold text-gray-900">
                          ₹
                          {ele?.amount ||
                            (ele?.pricing &&
                              (ele.pricing.monthly ||
                                ele.pricing.quarterly ||
                                ele.pricing.halfYearly ||
                                ele.pricing.yearly)) ||
                            "N/A"}
                        </h2>
                        <p className="text-[16px] text-[#95989C] font-normal ml-1">
                          /{activeCycle}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start items-start">
                    {" "}
                    {/* Update 1 */}
                    <div className="w-[80%] border-t-[1px] border-b-[1px] border-[#000000]/10"></div>
                  </div>
                  <div className="flex flex-col items-start pt-7 p-4 pb-16 space-y-2 w-full">
                    {" "}
                    <div className="flex w-full mb-2">
                      <CircleCheck
                        size={24}
                        className="text-white fill-[#16826C] mr-2 flex-shrink-0"
                      />
                      <div className="flex flex-col w-full">
                        <h4 className="text-md font-bold font-poppins text-black pb-2 whitespace-nowrap overflow-hidden text-ellipsis">
                          {ele.amount === 9999
                            ? "Offerings + Value-Added Benefits"
                            : "Offerings"}
                        </h4>
                      </div>
                    </div>
                    <div className="flex flex-col items-start  px-7 w-full">
                      <div className="font-poppins text-[12.5px] flex flex-col items-start space-y-2 text-[#666666] description-container w-full">
                        {formatDescription(
                          ele?.description || "No description available."
                        ).map((sentence, index) => (
                          <div
                            key={index}
                            className="flex items-start space-x-2 mb-2 w-full"
                          >
                            <div className="flex-shrink-0 w-5 text-[#666666]">
                              -
                            </div>
                            <span className="text-sm font-poppins text-left text-gray-600 flex-grow">
                              {sentence}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="w-full absolute bottom-0 p-4 flex justify-center align">
                    {" "}
                    {/* Update 3 */}
                    <button className="w-full h-11 py-2 px-1 rounded-md bg-white border-black border-[2px] text-black text-base font-semibold hover:bg-black hover:text-white hover:border-black transition-all duration-150 ease-linear">
                      {ele?.subscription ? "Subscribed" : "Subscribe Now"}
                    </button>
                  </div>
                </div>
              ))
          ) : (
            <p className="text-center text-gray-600">
              No plans available for {activeCycle}.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSection;
