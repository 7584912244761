"use client";

import React, { useState, useEffect, useRef } from "react";
import { X } from "lucide-react";
import moment from "moment";
import server from "../../../utils/serverConfig";
import { encryptApiKey } from "../../../utils/cryptoUtils";

const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
const TableHead = ({ isAllSelected, handleSelectAllChange }) => {
  return (
    <thead className="bg-gray-100 sticky top-0 z-10">
      <tr>
        <th className="py-3 px-4 border-b text-left font-poppins">
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={handleSelectAllChange}
            className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
          />
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          ID
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          Symbol
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          Type
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          Strike
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          Option Type
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          Order Type
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          Lots
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          Date
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          Comments
        </th>
      </tr>
    </thead>
  );
};

export const PreviousDerivativeAdvices = ({ onClose, onSelect }) => {
  const [adviceData, setAdviceData] = useState([]);
  const [selectedAdvices, setSelectedAdvices] = useState(new Set());
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${server.ccxtServer.baseUrl}comms/reco/payload/advisor-name/${advisorTag}`,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        );
        const data = await response.json();
        const filteredData = (data.reco || []).filter(
          (advice) =>
            advice.basket === null &&
            (advice.exchange === "NFO" || advice.exchange === "BFO")
        );
        setAdviceData(filteredData);
        if (data.status === 0) {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCheckboxChange = (index) => {
    setSelectedAdvices((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(index)) {
        newSelected.delete(index);
      } else {
        newSelected.add(index);
      }
      setIsAllSelected(newSelected.size === adviceData.length);
      return newSelected;
    });
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedAdvices(new Set());
    } else {
      const allIndexes = new Set(adviceData.map((_, index) => index));
      setSelectedAdvices(allIndexes);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleSelectAdvices = () => {
    const selectedAdviceData = Array.from(selectedAdvices).map((index) => {
      const advice = adviceData[index];
      return {
        foType: advice.foType,
        searchSymbol: advice.symbol,
        strike: advice.strike,
        lotMultiplier: 1, // Default value, adjust as needed
        lots: advice.lots,
        optionType: advice.optionType,
        orderType: advice.orderType,
        order: advice.order || "MARKET",
        productType: advice.productType || "CARRYFORWARD",
        rationale: advice.rationale,
        comments: advice.comments,
        extendedComment: advice.extendedComment,
      };
    });
    onSelect(selectedAdviceData);
  };

  return (
    <div
      ref={modalRef}
      className="relative w-full max-w-[75rem] h-[88vh] bg-white rounded-xl shadow-xl overflow-hidden font-sans"
    >
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between p-6 border-b bg-gray-50 sticky top-0 z-10">
          <h2 className="text-2xl font-bold text-gray-800">
            Previous Derivative Advices
          </h2>
          <button
            onClick={onClose}
            className="p-2 text-gray-600 hover:text-gray-800 hover:bg-gray-200 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
        {loading ? (
          <div className="flex-grow">
            <table className="min-w-full bg-white">
              <TableHead
                isAllSelected={isAllSelected}
                handleSelectAllChange={handleSelectAllChange}
              />
            </table>
            <div className="h-full w-full flex items-center justify-center">
              <div className="w-10 h-10 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
            </div>
          </div>
        ) : adviceData?.length > 0 ? (
          <div className="flex-grow overflow-auto">
            <table className="min-w-full bg-white">
              <TableHead
                isAllSelected={isAllSelected}
                handleSelectAllChange={handleSelectAllChange}
              />
              <tbody>
                {adviceData.map((advice, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">
                      <input
                        type="checkbox"
                        checked={selectedAdvices.has(index)}
                        onChange={() => handleCheckboxChange(index)}
                        className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                      />
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {advice.advice_reco_id}
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {advice.symbol ? advice.symbol : "-"}
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {advice.type ? advice.type : "-"}
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {advice.strike ? advice.strike : "-"}
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {advice.optionType ? advice.optionType : "-"}
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {advice.order_type ? advice.order_type : "-"}
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {advice.lots ? advice.lots : "-"}
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {moment(advice.date).format("Do MMM YYYY")}
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {advice.comments ? advice.comments : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="flex-grow overflow-auto">
            <table className="min-w-full bg-white">
              <TableHead
                isAllSelected={isAllSelected}
                handleSelectAllChange={handleSelectAllChange}
              />
            </table>
            <div className="h-full w-full flex items-center justify-center text-2xl font-bold text-gray-800">
              No Data Available
            </div>
          </div>
        )}
        <div className="p-6 border-t bg-gray-50 sticky bottom-0 z-10">
          <button
            onClick={handleSelectAdvices}
            disabled={selectedAdvices.size === 0}
            className={`px-4 py-2 font-poppins text-white rounded-lg float-right ${
              selectedAdvices.size === 0
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-black hover:bg-gray-800"
            }`}
          >
            Add Selected Advices
          </button>
        </div>
      </div>
    </div>
  );
};
