import React,{useEffect,useState} from "react";
import Sbi from "./assests/sbi.png";
import Exp from "./assests/Experience.png";
import Advisor from "./assests/About/advisor.jpg";

import { encryptApiKey, decryptApiKey } from "../utils/cryptoUtils";

const encryptedKey = encryptApiKey(process.env.REACT_APP_AQ_KEYS,
    process.env.REACT_APP_AQ_SECRET);

const AboutSection = () => {
  const [advisorText, setAdvisorText] = useState('advisor');



   useEffect(() => {
      if (process.env.REACT_APP_ADVISOR_SPECIFIER === 'RA') {
        setAdvisorText('Research Analyst');
      }
    }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 py-16" id="aboutSection">
      <div className="flex flex-col lg:flex-row items-center lg:items-start gap-12 shadow-[0_-5px_15px_-3px_rgba(0,0,0,0.1),0_5px_15px_-3px_rgba(0,0,0,0.1),5px_0_15px_-3px_rgba(0,0,0,0.1)]">
        {/* Image Section */}
        <div className="w-full lg:w-[35%]">
          <img
            src={Advisor}
            alt="Sumit Sharma"
            className="w-full h-auto sm:rounded-none lg:rounded-l-2xl object-cover"
          />
        </div>

        {/* Content Section */}
        <div className="w-full lg:w-[55%] pt-4 text-center lg:text-left">
          <h3 className="text-gray-500 font-medium tracking-wide mb-2 font-montserrat">
            ABOUT ADVISOR
          </h3>
          <h2 className="text-4xl font-bold text-gray-900 mb-8 font-montserrat">
            SUMIT SHARMA
          </h2>

          {/* Bullet Points */}
          <div className="space-y-6">
            <div className="flex items-start gap-6">
              <div className="w-2 h-1 rounded-full bg-gray-400 mt-3 hidden lg:block" />
              <p className="text-[#808080] leading-relaxed font-poppins font-normal">
                Sumit Sharma is an experienced entrepreneur and seasoned
                financial expert with over 15 years of hands-on experience in
                the financial services industry.
              </p>
            </div>

            <div className="flex items-start gap-6">
              <div className="w-2 h-1 rounded-full bg-gray-400 mt-3 hidden lg:block" />
              <p className="text-[#808080] leading-relaxed font-poppins font-normal">
                His deep expertise spans across Equity and Derivative Analysis,
                Portfolio Management Services, Wealth Management and Equity &
                Debt Capital Markets.
              </p>
            </div>

            <div className="flex items-start gap-6">
              <div className="w-2 h-1 rounded-full bg-gray-400 mt-3 hidden lg:block" />
              <p className="text-[#808080] leading-relaxed font-poppins font-normal">
                With a postgraduate degree in Finance, Sumit's professional
                journey has included pivotal roles at ICICI direct, Indian
                Synthetic Rubber Limited and Axis Bank.
              </p>
            </div>
          </div>

          {/* Credentials */}
          {/* Credentials */}
          <div className="flex flex-wrap justify-center lg:justify-start gap-4 lg:gap-8 mt-8 lg:mt-12 mb-8 px-1">
            <div className="flex items-center gap-2 lg:gap-3">
              <div className="p-1 lg:p-2">
                <img
                  src={Exp}
                  alt="Experience Logo"
                  className="w-5 h-6 lg:w-10 lg:h-12"
                />
              </div>
              <div>
                <div className="font-bold text-base lg:text-xl">15+ years</div>
                <div className="text-gray-500 text-xs lg:text-base">
                  Years of Experience
                </div>
              </div>
            </div>

            <div className="flex items-center gap-2 lg:gap-3">
              <div className="p-1 lg:p-2">
                <img
                  src={Sbi}
                  alt="SEBI Logo"
                  className="w-6 h-6 lg:w-12 lg:h-12"
                />
              </div>
              <div>
                <div className="font-bold text-base lg:text-xl">
                  INH000016737
                </div>
                <div className="text-gray-500 text-xs lg:text-base">
                  SEBI Registered
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;