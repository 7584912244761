"use client"

import { useState } from "react"
import { PencilIcon, X } from "lucide-react"
import { DatePicker, Checkbox, Switch } from "antd"
import dayjs from "dayjs"
import server from "../../utils/serverConfig"
import EditNewStrategyModal from "./EditNewStrategyModal"
import axios from "axios"
import { encryptApiKey } from "../../utils/cryptoUtils"
import toast from "react-hot-toast"

const style = {
  inputBox:
    "mt-1 py-2 w-full flex items-center bg-gray-100 rounded-md text-[12px] lg:text-[16px] text-[#000000] font-poppins font-medium placeholder:font-normal col-span-3 lg:col-span-5 px-4 lg:mr-4",
  inputHeaderText: "text-[14px] font-normal text-[#000000]",
  selected: "px-4 py-2 text-[14px] font-medium text-[#000000] border-b-2 border-[#000000]",
  unSelected: "px-4 py-2 text-[14px] font-medium text-[#000000]/60",
}

const frequencyOptions = [
  { label: "Monthly", value: "monthly" },
  { label: "Quarterly", value: "quarterly" },
  { label: "Half Yearly", value: "half-yearly" },
  { label: "Yearly", value: "yearly" },
]
const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG

const EditPlanButton = ({ Plan, className = "", planType }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [selectHeader, setSelectHeader] = useState("Plan")

  const [formData, setFormData] = useState({
    model_name: "",
    planType: "",
    isOneTime: false,
    oneTimeAmount: "",
    frequency: [],
    pricing: {},
    minInvestment: "",
    maxNetWorth: "",
    nextRebalanceDate: "",
    isSIPEnabled: false,
    overView: "",
    validityDays: "",
  })
  const [isStrategyModalOpen, setIsStrategyModalOpen] = useState(false)
  const [isNameEditModalOpen, setIsNameEditModalOpen] = useState(false)
  const [newModelName, setNewModelName] = useState("")
  const [isUpdatingName, setIsUpdatingName] = useState(false)

  const fetchPlanData = async () => {
    setIsLoading(true)
    try {
      const isBespoke = planType === "bespoke"

      const endpoint = isBespoke ? `comms/get_bespoke` : `comms/get_model_portfolio`

      const paramName = isBespoke ? "plan_name" : "model_name"

      const response = await axios.get(
        `${server.ccxtServer.baseUrl}${endpoint}?${paramName}=${encodeURIComponent(Plan)}&advisor=${
          process.env.REACT_APP_ADVISOR_SPECIFIC_TAG
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(process.env.REACT_APP_AQ_KEYS, process.env.REACT_APP_AQ_SECRET),
          },
        },
      )

      const data = response.data
      const transformedData = {
        ...data,
        frequency: data.frequency ? [data.frequency] : [],
        pricing: data.pricing || {},
        isOneTime: data.isOneTime || false,
        oneTimeAmount: data.oneTimeAmount || "",
        validityDays: data.validityDays || "",
      }
      setFormData(transformedData)
      setNewModelName(isBespoke ? data.name : data.model_name)
    } catch (error) {
      console.error("Error fetching plan data:", error)
      setError("Failed to load plan data")
    } finally {
      setIsLoading(false)
    }
  }

  const handleEditClick = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsLoading(true)
    await fetchPlanData()
    setIsModalOpen(true)
  }

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleDateChange = (date, dateString) => {
    setFormData((prev) => ({ ...prev, nextRebalanceDate: dateString }))
  }

  const handleFrequencyChange = (checkedValues) => {
    setFormData((prev) => ({ ...prev, frequency: checkedValues }))
  }

  const handleOneTimeToggle = (checked) => {
    setFormData((prev) => ({
      ...prev,
      isOneTime: checked,
      frequency: checked ? [] : prev.frequency,
      pricing: checked ? {} : prev.pricing,
    }))
  }

  const handlePricingChange = (frequency, value) => {
    setFormData((prev) => ({
      ...prev,
      pricing: {
        ...prev.pricing,
        [frequency]: value,
      },
    }))
  }

  const handleUpdateModelName = async (oldName, newName) => {
    try {
      const response = await axios.post(
        `${server.ccxtServer.baseUrl}comms/update_model_portfolio_name`,
        {
          old_name: oldName,
          new_name: newName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(process.env.REACT_APP_AQ_KEYS, process.env.REACT_APP_AQ_SECRET),
          },
        },
      )

      if (response.status === 200) {
        toast.success("Strategy name updated successfully!")
        // Update the form data with the new name
        setFormData((prev) => ({
          ...prev,
          model_name: newName,
        }))
      } else {
        throw new Error("Failed to update strategy name")
      }
      return response.data
    } catch (error) {
      console.error("Error updating strategy name:", error)
      toast.error("Failed to update strategy name. Please try again.")
      throw error
    }
  }

  const handleSave = async () => {
    setIsLoading(true)
    setError(null)

    try {
      if (planType === "bespoke") {
        // Bespoke plan update
        const bespokePayload = {
          plan_name: formData.name,
          advisor: advisorTag,
          minInvestment: formData.minInvestment || "",
          maxInvestment: formData.maxInvestment || "",
          duration: formData.duration || "",
        }

        const response = await axios.put(`${server.ccxtServer.baseUrl}comms/update_bespoke`, bespokePayload, {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(process.env.REACT_APP_AQ_KEYS, process.env.REACT_APP_AQ_SECRET),
          },
        })

        if (response.status === 200) {
          toast.success("Bespoke plan updated successfully!")
          setIsModalOpen(false)
        } else {
          throw new Error("Failed to update bespoke plan")
        }
      } else {
        const response = await axios.post(`${server.ccxtServer.baseUrl}comms/update_model_portfolio`, formData, {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(process.env.REACT_APP_AQ_KEYS, process.env.REACT_APP_AQ_SECRET),
          },
        })

        if (response.status === 200) {
          toast.success("Plan updated successfully!")
          setIsModalOpen(false)
        } else {
          throw new Error("Failed to update plan")
        }
      }
    } catch (error) {
      console.error("Error updating plan:", error)
      setError("Failed to update plan. Please try again.")
      toast.error("Failed to update plan. Please try again.")
    } finally {
      setIsLoading(false)
    }
  }

  const handleContinue = () => {
    setIsModalOpen(false)
    setIsStrategyModalOpen(true)
  }

  const isValidMeta = () => {
    // Add validation logic here
    return true
  }

  return (
    <>
      <button
        onClick={handleEditClick}
        className={`inline-flex items-center px-3 py-1.5 text-sm font-medium text-white bg-[#2056DF] hover:bg-[#2056DF] hover:shadow-md rounded-lg transition-colors ${className}`}
      >
        <PencilIcon className="w-4 h-4 mr-1" />
        Edit Plan
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 px-2">
          <div className="flex flex-col w-[350px] h-[400px] lg:w-[820px] lg:h-auto bg-white border-[#000000]/20 rounded-md pt-6">
            <div className="flex flex-col w-full px-8 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)]">
              <div className="flex flex-row justify-between items-center relative">
                <div className="pb-4 text-[18px] lg:text-[22px] text-[#000000] font-sans font-bold">Edit Plan</div>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="absolute -right-4 top-0 text-gray-500 hover:text-gray-700 z-50"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              <div className="flex flex-wrap flex-row">
                <button
                  className={selectHeader === "Plan" ? style.selected : style.unSelected}
                  onClick={() => setSelectHeader("Plan")}
                  disabled={!isValidMeta()}
                >
                  Plan Details
                </button>
              </div>
            </div>

            {isLoading && <p className="text-center mt-4">Loading...</p>}
            {error && <p className="text-red-500 text-center mt-4 mb-4">{error}</p>}

            {formData && !isLoading && (
              <>
                <div className="px-5 sm:px-8 overflow-auto custom-scroll sm:w-full flex flex-col h-[450px] pt-4">
                  <div className="w-full flex-wrap lg:flex-nowrap flex flex-row gap-3">
                    <div className="w-full lg:w-[65%] space-y-4">
                      <div>
                        <div className={style.inputHeaderText}>Name</div>
                        <div className="flex items-center gap-2">
                          <input
                            type="text"
                            name="model_name"
                            value={planType === "bespoke" ? formData.name : formData.model_name}
                            readOnly
                            className={`${style.inputBox} bg-gray-100`}
                            placeholder="Enter Plan Name"
                          />
                          {planType !== "bespoke" && (
                            <button
                              type="button"
                              onClick={() => setIsNameEditModalOpen(true)}
                              className="px-3 py-2 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-md text-sm font-medium transition-colors"
                            >
                              Edit Name
                            </button>
                          )}
                        </div>
                      </div>

                      <div>
                        <div className={style.inputHeaderText}>Plan Type</div>
                        <input
                          type="text"
                          name="planType"
                          value={planType === "bespoke" ? formData.type : "model portfolio"}
                          readOnly
                          className={`${style.inputBox} bg-gray-100  text-gray-600`}
                        />
                      </div>

                      <div className="flex items-center ">
                        <Switch
                          checked={formData.isOneTime === true}
                          disabled={false}
                          className="pointer-events-none"
                        />
                        <span className={`${style.inputHeaderText} ml-2`}>One-Time Payment</span>
                      </div>
                      {formData.isOneTime ? (
                        <div>
                          <div className={style.inputHeaderText}>One-Time Amount</div>
                          <div className="mt-1 flex flex-row items-center border border-[#000000]/20 rounded-md">
                            <div className="text-base text-black px-3">₹</div>
                            <input
                              type="number"
                              name="oneTimeAmount"
                              value={formData.oneTimeAmount}
                              onChange={(e) => handleInputChange("oneTimeAmount", e.target.value)}
                              className={style.inputBox}
                              placeholder="Enter one-time amount"
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="mb-2 ">
                            <div className={style.inputHeaderText}>Billing Frequency</div>
                            <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-row sm:space-x-6">
                              <Checkbox.Group
                                options={frequencyOptions}
                                value={formData.frequency}
                                // disabled={true}
                                onChange={(checkedValues) => {
                                  setFormData({
                                    ...formData,
                                    frequency: checkedValues,
                                  })
                                }}
                                className="contents sm:flex sm:flex-row sm:space-x-6 "
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div>
                        <div className={style.inputHeaderText}>
                          {formData.isOneTime === true ? "Validity (days)" : "Subscription Period in Days"}
                        </div>
                        <input
                          type="number"
                          name={formData.isOneTime === true ? "validityDays" : "duration"}
                          value={formData.isOneTime === true ? formData.validityDays : formData.duration}
                          onChange={(e) => {
                            const fieldName = formData.isOneTime === true ? "validityDays" : "duration"
                            handleInputChange(fieldName, e.target.value)
                          }}
                          className={style.inputBox}
                          placeholder={
                            formData.isOneTime ? "Enter number of days" : "Enter Subscription Period in Days"
                          }
                          min="1"
                        />
                      </div>

                      {formData.type !== "bespoke" && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                          <div>
                            <div className={style.inputHeaderText}>Min. Investment Amount</div>
                            <div className="mt-1 flex flex-row items-center border border-[#000000]/20 rounded-md">
                              <div className="text-base text-black px-3">₹</div>
                              <input
                                type="number"
                                name="minInvestment"
                                value={formData.minInvestment}
                                onChange={(e) => handleInputChange("minInvestment", e.target.value)}
                                className={style.inputBox}
                                placeholder="Ex. 1,00,000"
                              />
                            </div>
                          </div>
                          <div>
                            <div className={style.inputHeaderText}>Max. Investment Amount</div>
                            <div className="mt-1 flex flex-row items-center border border-[#000000]/20 rounded-md">
                              <div className="text-base text-black px-3">₹</div>
                              <input
                                type="number"
                                name="maxNetWorth"
                                value={formData.maxNetWorth}
                                onChange={(e) => handleInputChange("maxNetWorth", e.target.value)}
                                className={style.inputBox}
                                placeholder="Ex. 1,00,000"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="opacity-70 cursor-not-allowed">
                        <div className={style.inputHeaderText}>Start Date</div>
                        <DatePicker
                          value={formData.nextRebalanceDate ? dayjs(formData.nextRebalanceDate) : null}
                          disabled={true}
                          className={`${style.inputBox} w-full bg-gray-100 text-gray-500`}
                          format="YYYY-MM-DD"
                          placeholder="Select Start Date"
                        />
                      </div>

                      {formData.type !== "bespoke" && (
                        <div className="flex items-center opacity-50 cursor-not-allowed">
                          <input
                            type="checkbox"
                            name="isSIPEnabled"
                            checked={formData.isSIPEnabled}
                            disabled={true}
                            className="mr-2"
                          />
                          <span className={style.inputHeaderText}>Enable SIP</span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="w-full pt-4">
                    <div>
                      <div className={style.inputHeaderText}>Plan Description</div>
                      <textarea
                        name="overView"
                        value={formData.overView}
                        onChange={(e) => handleInputChange("overView", e.target.value)}
                        className={`h-24 ${style.inputBox}`}
                        placeholder="Describe your strategy in 4-6 lines"
                      />
                    </div>
                  </div>

                  <div className="w-full pt-4"></div>
                </div>

                <div className="py-5 flex flex-row justify-end items-center px-4 lg:px-8 shadow-[4px_0px_6px_0px_rgba(0,0,0,0.06)]">
                  {formData.type === "bespoke" && (
                    <button
                      className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-8 py-2 ml-4 bg-black text-white text-base font-semibold rounded-md cursor-pointer"
                      onClick={handleSave}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <span className="flex items-center justify-center">
                          <svg
                            className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Saving...
                        </span>
                      ) : (
                        <span className="text-[16px] font-medium text-[#ffffff] font-poppins">Save Changes</span>
                      )}
                    </button>
                  )}
                  {formData.type !== "bespoke" && (
                    <button
                      disabled={!isValidMeta() || isLoading}
                      className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-8 py-2 ml-4 bg-blue-600 text-white text-base font-semibold rounded-md cursor-pointer"
                      onClick={handleContinue}
                    >
                      <span className="text-[16px] font-medium text-[#ffffff] font-poppins">Continue</span>
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {isNameEditModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-[60] px-2">
          <div className="relative animate-modal bg-white rounded-md w-full max-w-md p-6">
            <div className="absolute right-4 top-4 text-gray-600 hover:text-red-500 cursor-pointer">
              <X className="w-5 h-5" onClick={() => setIsNameEditModalOpen(false)} />
            </div>

            <h2 className="text-xl font-bold mb-4">Update Strategy Name</h2>

            <form
              onSubmit={async (e) => {
                e.preventDefault()

                if (!newModelName.trim()) {
                  toast.error("Please enter a valid name")
                  return
                }

                if (newModelName === formData.model_name) {
                  setIsNameEditModalOpen(false)
                  return
                }

                setIsUpdatingName(true)
                try {
                  await handleUpdateModelName(formData.model_name, newModelName)
                  // Update the form data with the new name
                  setFormData((prev) => ({
                    ...prev,
                    model_name: newModelName,
                  }))
                  setIsNameEditModalOpen(false)
                } catch (error) {
                  // Error is already handled in the handleUpdateModelName function
                } finally {
                  setIsUpdatingName(false)
                }
              }}
            >
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Current Name</label>
                <input
                  type="text"
                  value={formData.model_name}
                  disabled
                  className="w-full py-2 px-3 bg-gray-100 text-gray-700 border border-gray-300 rounded-md"
                />
              </div>

              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  New Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value={newModelName}
                  onChange={(e) => setNewModelName(e.target.value)}
                  className="w-full py-2 px-3 bg-white text-gray-900 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400"
                  placeholder="Enter new strategy name"
                />
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setIsNameEditModalOpen(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isUpdatingName}
                  className="px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 disabled:bg-gray-400"
                >
                  {isUpdatingName ? (
                    <span className="flex items-center">
                      <svg
                        className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Updating...
                    </span>
                  ) : (
                    "Update Name"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isStrategyModalOpen && (
        <EditNewStrategyModal
          setOpenStrategyModal={setIsStrategyModalOpen}
          getAllModalPortfolioList={() => {}}
          createPlan={() => {}}
          plan={false}
          loading={isLoading}
          setLoading={setIsLoading}
          initialData={formData}
          onClose={() => setIsStrategyModalOpen(false)}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  )
}

export default EditPlanButton

