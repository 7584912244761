import React, { useState, useEffect, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";
import { auth } from "../../firebase";
import server from "../../utils/serverConfig";
import moment from "moment";
import HistoryIcon from "../../assests/history_icon.svg";
import TradeHistoryTable from "./TradeHistoryTable";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

import { DatePicker } from "../../components/ui/date-picker";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { encryptApiKey } from "../../utils/cryptoUtils";
import appConfig from "../../utils/appConfig";

// Example data array

const TradeHistory = () => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;

  const [userDetails, setUserDetails] = useState();
  const getUserDetails = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      })
      .then((res) => {
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserDetails();
  }, [userEmail]);

  const broker = userDetails && userDetails?.user_broker;
  const [show, setShow] = useState(false);
  const [filterData, setFilterData] = useState("view-all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredeData, setFilteredData] = useState([]);
  const dropdownRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSkeletonVisible, setIsSkeletonVisible] = useState(false);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  }

  const getAllTrades = () => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/trade-history/trade-history-by-client?email=${userEmail}`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then((response) => {
        const stockData = response?.data?.trades;
        setData(stockData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllTrades();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!data) return;

    // Copy of data to avoid mutating the original array
    let raw = [...data];

    // Sort the trade history by date in descending order

    // Filter by profit, loss, or show all based on filterData
    let filteredTrades = [];
    if (filterData === "profit") {
      filteredTrades = raw.filter((trade) => trade.pnl > 0); // Profit trades
    } else if (filterData === "loss") {
      filteredTrades = raw.filter((trade) => trade.pnl < 0); // Loss trades
    } else {
      filteredTrades = raw; // View all
    }

    // Further filter by startDate and endDate if they are provided
    if (startDate) {
      filteredTrades = filteredTrades.filter((trade) =>
        moment(trade.sell[0]?.exitDate).isSameOrAfter(startDate, "day")
      );
    }
    if (endDate) {
      filteredTrades = filteredTrades.filter((trade) =>
        moment(trade.sell[0]?.exitDate).isSameOrBefore(endDate, "day")
      );
    }

    // Update the filtered data
    setFilteredData(filteredTrades);
  }, [filterData, startDate, endDate, data]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    // Show spinner for 1 second
    const spinnerTimer = setTimeout(() => {
      setIsLoading(false);
      setIsSkeletonVisible(true); // Show skeleton after spinner
    }, 1000); // Spinner visible for 1 second

    // Show skeleton for 2 seconds, then hide it
    const skeletonTimer = setTimeout(() => {
      setIsSkeletonVisible(false);
    }, 1300); // Skeleton visible for 1.5 seconds (after 1 second spinner)

    return () => {
      clearTimeout(spinnerTimer);
      clearTimeout(skeletonTimer);
    };
  }, []);

  const [selectedOption, setSelectedOption] = useState("View All Trades");
  const dropdownOption = ["View All Trades", "Profit Trades", "Loss Trades"];
  const handleSelectOption = (value) => {
    setSelectedOption(value);
    if (value === "View All Trades") {
      setFilterData("view-all");
    } else if (value === "Profit Trades") {
      setFilterData("profit");
    } else if (value === "Loss Trades") {
      setFilterData("loss");
    }
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setSelectedOption("View All Trades");
    setFilterData("view-all");
  };

  return (
    <>
      {isLoading === true ? (
        // <div className="flex flex-row justify-center items-center  w-full lg:min-h-screen  bg-[#f9f9f9]">
        //   <LoadingSpinner />
        // </div>
        <div className="flex flex-col relative  w-full min-h-screen  bg-[#f9f9f9]">
          <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
            <svg
              className="h-10 w-10 text-[#000000] animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      ) : data.length !== 0 ? (
        <div className="flex flex-col relative  w-full lg:min-h-screen  bg-[#f9f9f9] ">
          <div className="flex flex-col h-[240px] lg:h-[200px] ">
            <div className={`px-6   ${appConfig.colors.headerBlueTheme}`}>
              <div className="text-3xl font-bold mb-4 mt-8 text-white  ">Trade History</div>
              <div className="text-[11px] text-sm text-white/70  font-light pb-4 ">
                Easily track and assess closed trades and their outcomes. To view Open Positions check your live
                portfolio.
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:space-x-0 lg:items-center mt-1 px-4 lg:mt-6 lg:px-4 w-full  ">
              <div className="grid grid-cols-2 gap-2 lg:grid-cols-4 lg:gap-x-6 text-[14px] rounded-lg px-2 py-2 lg:px-4  lg:py-2   ">
                <div>
                  <DatePicker date={startDate} setDate={setStartDate} placeholder={"Start Date"} />
                </div>
                <div>
                  <DatePicker date={endDate} setDate={setEndDate} placeholder={"End Date"} />
                </div>
                <div className="flex space-x-2 lg:space-x-6 w-full col-span-2">
                  <div className="w-full">
                    <Select value={selectedOption} onValueChange={handleSelectOption}>
                      <SelectTrigger className="py-2.5 bg-[#F9F9F9]  text-[16px]  font-medium border-none ring-1 hover:ring-1 ring-gray-200  hover:ring-[#D9D9D9] focus:outline-none focus:ring-1 focus:ring-[#D9D9D9]">
                        <SelectValue placeholder={"Select Filter"} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {dropdownOption?.map((option, index) => (
                            <SelectItem className=" font-medium capitalize text-[14px] " key={index} value={option}>
                              {option}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>

                  <div
                    onClick={handleClear}
                    className="w-[100px] lg:w-[150px]  flex flex-row items-center justify-center px-6 py-2  lg:px-6 lg:py-1.5 bg-[#2056DF] text-white text-[16px]  font-medium rounded-md cursor-pointer"
                  >
                    Clear
                  </div>
                </div>
              </div>
            </div>
          </div>

          {data && data.length !== 0 ? <TradeHistoryTable data={filteredeData} broker={broker} /> : null}
        </div>
      ) : isSkeletonVisible ? (
        <div className="flex flex-col items-center justify-center w-full min-h-screen bg-[#f9f9f9] px-4">
          <Skeleton circle={true} height={150} width={150} />
          <br />
          <Skeleton height={20} width={300} />
          <br />
          <Skeleton height={15} width={500} />
          <Skeleton height={15} width={350} />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center space-y-4 lg:space-y-6 relative  w-full lg:min-h-screen  bg-[#f9f9f9] py-6 ">
          <div className="flex pl-[8px] items-center justify-center w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70 ">
            <img src={HistoryIcon || "/placeholder.svg"} alt="History Icon" className="w-[60px] lg:w-[80px]" />
          </div>
          <div className="flex flex-col space-y-3 lg:space-y-5 items-center px-6 lg:w-[800px]">
            <div className="text-black text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
              No Trade History
            </div>
            <div className="md:max-w-[650px] text-center text-[14px]  lg:text-[18px] text-[#000000]/60 lg:leading-[24px] font-medium  px-[10px] lg:px-[60px]">
              No Trades have been recorded yet. When you complete a trade, it will be listed here.
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default TradeHistory

