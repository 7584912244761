import React, { useEffect, useState, useMemo } from "react";
import { XIcon, ChevronLeft, ChevronRight, AlertCircle } from "lucide-react";
import { FaCheckCircle, FaCamera } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

import StockDistribution from "./StockDistribution";
import server from "../../utils/serverConfig";
import Preview from "../../assests/default_image.png";
import { DatePicker } from "../../components/ui/date-picker";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";

const style = {
  selected:
    "disabled:cursor-not-allowed px-4 py-2 text-base font-semibold text-black border-b-[3px] border-[#000000] cursor-pointer",
  unSelected:
    "disabled:cursor-not-allowed px-4 py-2 text-base text-[#000000]/50 font-normal cursor-pointer",
  inputBox:
    "w-full py-2 xl:px-6 md:py-2.5  bg-[#ffffff] text-[12px] md:text-[16px]  peer text-gray-900 placeholder-transparent font-poppins font-medium rounded-md  ring-1 hover:ring-1 ring-gray-200  hover:ring-[#D9D9D9] focus:outline-none focus:ring-1 focus:ring-[#D9D9D9]  transition ease-in duration-200 ",
  selectDiv:
    "flex items-center px-2 py-2  text-sm hover:rounded-md mx-3 mb-1 text-black  hover:bg-[#000000] hover:text-white cursor-pointer",
  inputHeaderText:
    "text-sm text-gray-900 text-left font-normal font-poppins sm:mb-[2px]",
  selectPreferenceDiv:
    "flex flex-row justify-center  rounded-[6px] py-3 items-center",
};

const FrequencyList = [
  {
    name: "Every Year",
  },
  {
    name: "Every Quarter",
  },
  {
    name: "Every Month",
  },
  {
    name: "Every Week",
  },
  {
    name: "Every Day",
  },
  {
    name: "Need Basis",
  },
];
const InvestmentStrategyList = [
  {
    name: "Asset Allocation",
  },
  {
    name: "Factor Investing",
  },
  {
    name: "Quantitative",
  },
  {
    name: "Technical",
  },
  {
    name: "Quality",
  },
  {
    name: "Growth",
  },
  {
    name: "Value",
  },
  {
    name: "Thematic",
  },
  {
    name: "Sector Tracker",
  },
  {
    name: "ESG",
  },
  {
    name: "Corporate Governance",
  },
  {
    name: "Quantamental",
  },
  {
    name: "Fundamental",
  },
  {
    name: "Momentum",
  },
  {
    name: "Goal Based",
  },
  {
    name: "Dividend",
  },
];

const MethodologyList = [
  {
    name: "Defining the universe",
    value: 1,
  },
  {
    name: "Research",
    value: 2,
  },
  {
    name: "Constituent Screening",
    value: 3,
  },
  {
    name: "Weighting",
    value: 4,
  },
  {
    name: "Rebalance",
    value: 5,
  },
  {
    name: "Asset Allocation",
    value: 6,
  },
];

const rationalePara =
  "This recommendation is based on a comprehensive analysis of the company's growth potential and value metrics. This recommendation also accounts for potential future risks, ensuring a balanced approach to maximizing returns while mitigating uncertainties. Please contact your advisor for any queries";
const AddNewStrategyModal = ({
  setOpenStrategyModal,
  getAllModalPortfolioList,
  createPlan,
  plan,
  loading,
  setLoading,
  adminId,
  image,
}) => {
  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const [selectHeader, setSelectHeader] = useState("Meta");

  const [overView, setOverView] = useState("");

  const [investmentStrategy, setInvestmentStrategy] = useState([]);

  const handleLike = (itemId) => {
    // Check if the item is already liked
    const isLiked = investmentStrategy.includes(itemId);

    if (isLiked) {
      // If liked, remove from likes
      setInvestmentStrategy((prevLikes) =>
        prevLikes.filter((id) => id !== itemId)
      );
    } else {
      // If not liked, check if less than 3 items are currently liked
      if (investmentStrategy.length < 3) {
        // Add to likes
        setInvestmentStrategy((prevLikes) => [...prevLikes, itemId]);
      } else {
        // Optionally show a message or handle the limit reached case
        toast.error("You can only select up to 3 items.!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    }
  };
  // Function to check if an item is liked
  const isLiked = (itemId) => investmentStrategy.includes(itemId);
  // rationale
  const [rationaleText, setRationaleText] = useState(rationalePara);
  const [blogLink, setBlogLink] = useState("");
  const [researchReport, setResearchReport] = useState("");
  //Methodology
  const [selectMethodology, setSelectMethodology] = useState(1);
  const [definingUniverse, setDefiningUniverse] = useState("");
  const [researchOverView, setResearchOverView] = useState("");
  const [constituentScreening, setConstitunetScreening] = useState("");
  const [weighting, setWeighting] = useState("");
  const [rebalanceMethodologyText, setRebalanceMethodologyText] = useState("");
  const [assetAllocationText, setAssetAllocationText] = useState("");

  const isFieldFilled = (methodologyValue) => {
    switch (methodologyValue) {
      case 1:
        return definingUniverse.trim().length > 0;
      case 2:
        return researchOverView.trim().length > 0;
      case 3:
        return constituentScreening.trim().length > 0;
      case 4:
        return weighting.trim().length > 0;
      case 5:
        return rebalanceMethodologyText.trim().length > 0;
      case 6:
        return assetAllocationText.trim().length > 0;
      default:
        return false;
    }
  };
  // rebalance
  const [frequency, setFrequency] = useState("");
  const [nextRebalanceDate, setNextRebalanceDate] = useState();

  // stock distribution
  const [adviceEntries, setAdviceEntries] = useState([
    {
      symbol: "",
      exchange: "",
      segment: "EQUITY",
      inputValue: "",
      symbols: [],
      value: "",
    },
  ]);

  const calculateTotalAllocation = () => {
    return adviceEntries.reduce((total, entry) => {
      return total + (parseFloat(entry.value) || 0);
    }, 0);
  };

  const totalAllocation = useMemo(
    () => calculateTotalAllocation(),
    [adviceEntries]
  );

  const isValidMeta = () => overView;

  const isValidRationale = () => rationaleText;

  const isValidMethodology = () =>
    definingUniverse.trim() !== "" &&
    researchOverView.trim() !== "" &&
    constituentScreening.trim() !== "" &&
    weighting.trim() !== "" &&
    rebalanceMethodologyText.trim() !== "";

  const isValidFrequency = () => frequency && nextRebalanceDate;

  const isValidAdviceEntries = () =>
    adviceEntries.length > 0 && totalAllocation === 100;

  let convertedData = { model_portfolio: [] };

  adviceEntries?.forEach((item) => {
    const symbol = item.symbol;
    let value = parseFloat(item?.value / 100).toFixed(2);
    const exchange = item.exchange;
    // value = parseFloat(value.toFixed(2)); // Crop value to 2 decimal places

    convertedData.model_portfolio.push({ symbol, value, exchange });
  });

  const [minimumPortfolioAmount, setMinimumPortfolioAmount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [saveButton, setSaveButton] = useState(false);
  const handleMinimumPortfolioAmount = () => {
    setIsLoading(true);
    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}rebalance/minimum-portfolio-amount-new`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
      data: convertedData,
    };

    axios
      .request(config)
      .then((response) => {
        setMinimumPortfolioAmount(response.data);
        setSaveButton(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error calculating minimum portfolio amount!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setIsLoading(false);
      });
  };

  const adminDetailsString = localStorage.getItem("planName");
  const planNameData = adminDetailsString
    ? JSON.parse(adminDetailsString)
    : null;
  const handleSubmit = () => {
    setLoading(true);
    // Format date to YYYY-MM-DD
    const formatDate = (date) => {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const day = String(d.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    // Transform adviceEntries
    const transformedAdviceEntries = adviceEntries.map((entry) => ({
      symbol: entry.symbol,
      value: entry.value / 100,
      exchange: entry.exchange,
      date: new Date().toISOString(),
    }));

    // Create payload as direct JSON object
    const payload = {
      advisor: advisorTag,
      modelName: planNameData.strategyName,
      minInvestment: planNameData?.minInvestment.toString(),
      maxNetWorth: planNameData?.maxNetWorth.toString(),
      overView: overView,
      investmentStrategy: investmentStrategy.join(","),
      whyThisStrategy: rationaleText,
      blogLink: blogLink,
      definingUniverse: definingUniverse,
      researchOverView: researchOverView,
      constituentScreening: constituentScreening,
      weighting: weighting,
      rebalanceMethodologyText: rebalanceMethodologyText,
      assetAllocationText: assetAllocationText,
      frequency: frequency,
      nextRebalanceDate: formatDate(nextRebalanceDate),
      totalInvestmentvalue:
        minimumPortfolioAmount.min_investment_amount.toString(),
      adviceEntries: transformedAdviceEntries,
    };

    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}rebalance/create-strategy`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        const data = response?.data.portfolio;
        const allRebalances = data?.model?.rebalanceHistory || [];

        const sortedRebalances = allRebalances?.sort(
          (a, b) => new Date(b.rebalanceDate) - new Date(a.rebalanceDate)
        );
        const latest = sortedRebalances[0];
        // console.log("latest", latest);

        if (image) {
          const formData = new FormData();
          formData.append("id", data?._id);
          formData.append("image", image);

          axios
            .post(
              `${server.server.baseUrl}api/model-portfolio/newPlanImage`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "X-Advisor-Subdomain": process.env.REACT_APP_URL,
                  "aq-encrypted-key": encryptApiKey(
                    process.env.REACT_APP_AQ_KEYS,
                    process.env.REACT_APP_AQ_SECRET
                  ),
                },
              }
            )
            .then((imageResponse) => {
              console.log("Image uploaded successfully", imageResponse.data);
            })
            .catch((imageError) => {
              console.error("Error uploading image:", imageError);
            });
        }

        if (researchReport) {
          const researchReportPayload = {
            link: researchReport,
            advisor: advisorTag,
            model_name: latest?.updatedModelName,
          };
          let config2 = {
            method: "post",
            url: `${server.ccxtServer.baseUrl}comms/mpf-research-report/${latest?.model_Id}`,
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
            data: researchReportPayload, // Send the JSON object directly
          };
          axios
            .request(config2)
            .then((response) => {
              console.log("res", response);
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
        getAllModalPortfolioList();
        if (plan === true) {
          createPlan();
        }

        setTimeout(() => {
          setAdviceEntries([]);
          setOpenStrategyModal(false);
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in creating Strategy!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setLoading(false);
      });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 px-2 ">
      <div className="relative animate-modal">
        <div className="absolute right-2 top-6  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer ">
          <XIcon
            className="w-6 h-6 mr-2  "
            onClick={() => setOpenStrategyModal(false)}
          />
        </div>

        <div className="flex flex-col w-full h-[600px] max-h-[600px] lg:w-[820px] lg:h-full   bg-white  border-[#000000]/20 rounded-md pt-6 overflow-hidden">
          <div className="flex flex-col  w-full px-8 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] ">
            <div className="pb-4 text-[18px] lg:text-[22px]  text-[#000000] font-sans font-bold ">
              Create New Model Portfolio
            </div>
            <div className="grid grid-cols-2 sm:flex sm:flex-row sm:flex-wrap gap-2 w-full">
              <button
                className={`px-2 py-1 text-xs sm:text-sm sm:px-3 sm:py-2 ${
                  selectHeader === "Meta" ? style.selected : style.unSelected
                }`}
                onClick={() => setSelectHeader("Meta")}
              >
                Meta
              </button>
              <button
                className={`px-2 py-1 text-xs sm:text-sm sm:px-3 sm:py-2 ${
                  selectHeader === "Rationale"
                    ? style.selected
                    : style.unSelected
                }`}
                disabled={!isValidMeta()}
                onClick={() => setSelectHeader("Rationale")}
              >
                Rationale
              </button>
              <button
                className={`px-2 py-1 text-xs sm:text-sm sm:px-3 sm:py-2 ${
                  selectHeader === "Methodology"
                    ? style.selected
                    : style.unSelected
                }`}
                onClick={() => setSelectHeader("Methodology")}
                disabled={!isValidRationale()}
              >
                Methodology
              </button>
              <button
                className={`px-2 py-1 text-xs sm:text-sm sm:px-3 sm:py-2 ${
                  selectHeader === "Stock Distribution"
                    ? style.selected
                    : style.unSelected
                }`}
                onClick={() => setSelectHeader("Stock Distribution")}
                disabled={!isValidMethodology()}
              >
                Stock Distribution
              </button>
              <button
                className={`px-2 py-1 text-xs sm:text-sm sm:px-3 sm:py-2 ${
                  selectHeader === "Rebalance"
                    ? style.selected
                    : style.unSelected
                }`}
                onClick={() => setSelectHeader("Rebalance")}
                disabled={!isValidAdviceEntries()}
              >
                Rebalance
              </button>
            </div>
          </div>
          {selectHeader === "Rationale" ? (
            <>
              <div className="px-8 w-full h-auto md:h-[500px] pt-8 space-y-3 md:space-y-8">
                <div>
                  <div className={style.inputHeaderText}>
                    Rationale <span className="text-red-500 ml-1">*</span>
                  </div>
                  <textarea
                    type="text"
                    value={rationaleText}
                    onChange={(e) => setRationaleText(e.target.value)}
                    className={`h-20 md:h-40 ${style.inputBox}  px-2 font-poppins placeholder:text-gray-400 placeholder:font-normal`}
                    placeholder="Rationale"
                  />
                </div>
                <div>
                  <div className={style.inputHeaderText}>
                    Blog Link (optional)
                  </div>
                  <input
                    type="text"
                    value={blogLink}
                    onChange={(e) => setBlogLink(e.target.value)}
                    className={`${style.inputBox}  px-2 font-poppins placeholder:text-gray-400 placeholder:font-normal`}
                    placeholder="Enter the link to your blog here"
                  />
                </div>
              </div>
              <div className="py-4 flex flex-row justify-end items-center px-4 lg:px-8 shadow-[4px_0px_6px_0px_rgba(0,0,0,0.06)]">
                <button
                  disabled={!isValidRationale()}
                  className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-8 py-2 ml-4 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
                  onClick={() => setSelectHeader("Methodology")}
                >
                  <span className="text-[16px] font-medium text-[#ffffff] font-poopins ">
                    Continue
                  </span>
                </button>
              </div>
            </>
          ) : selectHeader === "Methodology" ? (
            <>
              <div className="px-4 sm:px-8 w-full flex flex-wrap md:flex-nowrap flex-row h-auto lg:h-[500px] overflow-auto custom-scroll pt-8">
                <div className="flex flex-col w-full">
                  <div className="flex justify-between items-center mb-4">
                    <button
                      onClick={() =>
                        setSelectMethodology((prev) => Math.max(1, prev - 1))
                      }
                      disabled={selectMethodology === 1}
                      className="p-2 disabled:opacity-50"
                    >
                      <ChevronLeft className="w-6 h-6" />
                    </button>
                    <h3 className="text-lg font-semibold">
                      {MethodologyList[selectMethodology - 1].name}
                    </h3>
                    <button
                      onClick={() =>
                        setSelectMethodology((prev) =>
                          Math.min(MethodologyList.length, prev + 1)
                        )
                      }
                      disabled={selectMethodology === MethodologyList.length}
                      className="p-2 disabled:opacity-50"
                    >
                      <ChevronRight className="w-6 h-6" />
                    </button>
                  </div>
                  <div className="flex items-center space-x-2 bg-blue-50 p-1 rounded-md mb-4">
                    <AlertCircle className="h-4 w-4 text-blue-500" />
                    <p className="text-sm text-blue-700 font-poppins">
                      Please complete all the given Methodology to proceed.
                    </p>
                  </div>
                  {selectMethodology === 1 && (
                    <textarea
                      value={definingUniverse}
                      onChange={(e) => setDefiningUniverse(e.target.value)}
                      className="h-60 w-full p-4 border border-gray-300 rounded-md focus:outline-none placeholder-gray-400"
                      placeholder="Defining the universe in 2-3 lines"
                      maxLength={1000}
                    />
                  )}
                  {selectMethodology === 2 && (
                    <textarea
                      value={researchOverView}
                      onChange={(e) => setResearchOverView(e.target.value)}
                      className="h-60 w-full p-4 border border-gray-300 rounded-md focus:outline-none placeholder-gray-400"
                      placeholder="Research Overview"
                    />
                  )}
                  {selectMethodology === 3 && (
                    <textarea
                      value={constituentScreening}
                      onChange={(e) => setConstitunetScreening(e.target.value)}
                      className="h-60 w-full p-4 border border-gray-300 rounded-md focus:outline-none placeholder-gray-400"
                      placeholder="Constituent Screening Details"
                    />
                  )}
                  {selectMethodology === 4 && (
                    <textarea
                      value={weighting}
                      onChange={(e) => setWeighting(e.target.value)}
                      className="h-60 w-full p-4 border border-gray-300 rounded-md focus:outline-none placeholder-gray-400"
                      placeholder="Weighting Methodology"
                    />
                  )}
                  {selectMethodology === 5 && (
                    <textarea
                      value={rebalanceMethodologyText}
                      onChange={(e) =>
                        setRebalanceMethodologyText(e.target.value)
                      }
                      className="h-60 w-full p-4 border border-gray-300 rounded-md focus:outline-none placeholder-gray-400"
                      placeholder="Rebalance Methodology"
                    />
                  )}
                  {selectMethodology === 6 && (
                    <textarea
                      value={assetAllocationText}
                      onChange={(e) => setAssetAllocationText(e.target.value)}
                      className="h-60 w-full p-4 border border-gray-300 rounded-md focus:outline-none placeholder-gray-400"
                      placeholder="Asset Allocation Strategy"
                    />
                  )}
                </div>
              </div>
              <div className="py-4 flex justify-end items-center px-4 lg:px-8 shadow-[4px_0px_6px_0px_rgba(0,0,0,0.06)]">
                <div className="mr-4 text-sm text-gray-600">
                  {`${
                    Object.values(MethodologyList).filter((_, index) =>
                      isFieldFilled(index + 1)
                    ).length
                  } / ${MethodologyList.length} completed`}
                </div>
                <button
                  disabled={!isValidMethodology()}
                  className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-8 py-2 bg-black text-white text-base font-semibold rounded-md cursor-pointer"
                  onClick={() => setSelectHeader("Stock Distribution")}
                >
                  Continue
                </button>
              </div>
            </>
          ) : selectHeader === "Rebalance" ? (
            <>
              <div className="relative py-8 px-10 w-full flex flex-col h-[500px] overflow-auto custom-scroll">
                <div className="mt-2 flex flex-col space-y-10">
                  <div>
                    <div className={style.inputHeaderText}>Frequency</div>
                    <div className="mt-2 relative">
                      <div className="w-full md:w-[350px]">
                        <Select onValueChange={(value) => setFrequency(value)}>
                          <SelectTrigger className="py-2.5 bg-[#ffffff]  text-[16px] font-poppins font-medium border-none ring-1 hover:ring-1 ring-gray-200  hover:ring-[#D9D9D9] focus:outline-none focus:ring-1 focus:ring-[#D9D9D9]">
                            <SelectValue placeholder={"Select Frequency"} />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {FrequencyList?.map((option, index) => (
                                <SelectItem
                                  className="font-poppins font-medium capitalize text-[14px] "
                                  key={index}
                                  value={option.name}
                                >
                                  {option.name}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={style.inputHeaderText}>
                      Next Rebalance Date
                    </div>

                    <div className="relative mt-2 w-full md:w-[350px] text-[12px] md:text-[16px]">
                      <DatePicker
                        className="px-2 py-2 md:px-4 md:py-3 bg-[#ffffff] "
                        date={nextRebalanceDate}
                        setDate={setNextRebalanceDate}
                        placeholder={"Choose Rebalance Date"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-4 flex flex-row justify-end items-center px-4 lg:px-8 shadow-[4px_0px_6px_0px_rgba(0,0,0,0.06)]">
                <button
                  disabled={!isValidFrequency()}
                  className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-8 py-2 ml-4 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
                  onClick={handleSubmit}
                >
                  {loading === true ? (
                    <span className="flex flex-row justify-center">
                      <svg
                        className="h-6  w-6 text-[#ffffff] animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                  ) : (
                    <span className="text-[16px] font-medium text-[#ffffff] font-poppins ">
                      Create Model Portfolio
                    </span>
                  )}
                </button>
              </div>
            </>
          ) : selectHeader === "Stock Distribution" ? (
            <>
              <div className="px-8 w-full flex flex-col h-[500px] overflow-auto custom-scroll pt-4">
                <StockDistribution
                  setAdviceEntries={setAdviceEntries}
                  adviceEntries={adviceEntries}
                  minimumPortfolioAmount={minimumPortfolioAmount}
                  setSaveButton={setSaveButton}
                />
              </div>
              <div className="px-8 pb-3">
                <div className={style.inputHeaderText}>
                  Research Report (optional)
                </div>

                <input
                  type="text"
                  value={researchReport}
                  onChange={(e) => setResearchReport(e.target.value)}
                  className={`${style.inputBox}  px-2 font-poppins placeholder:text-gray-400 placeholder:font-normal`}
                  placeholder="Enter the link to your research point here"
                />
              </div>
              {totalAllocation > 100 && (
                <div className="text-rose-600 font-semibold px-8 py-1">
                  Total stock allocation cannot exceed 100%.
                </div>
              )}
              {adviceEntries?.length > 0 ? (
                <div className="py-4 flex flex-row justify-between items-center px-4 lg:px-8 shadow-[4px_0px_6px_0px_rgba(0,0,0,0.06)]">
                  {adviceEntries.length > 0 ? (
                    <div className="font-bold">
                      <span>Total Stocks : {adviceEntries?.length}</span>
                      {saveButton === true ? (
                        <span className="mx-3">|</span>
                      ) : null}
                      {saveButton === true ? (
                        <span>
                          {" "}
                          Total Investment : ₹
                          {parseFloat(
                            minimumPortfolioAmount?.min_investment_amount
                          ).toFixed(2)}
                        </span>
                      ) : null}
                    </div>
                  ) : null}
                  {saveButton === true ? (
                    <button
                      className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-8 py-2 ml-4 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
                      disabled={!isValidAdviceEntries()}
                      onClick={() => setSelectHeader("Rebalance")}
                    >
                      <span className="text-[16px] font-medium text-[#ffffff] font-poppins ">
                        Continue
                      </span>
                    </button>
                  ) : (
                    <button
                      className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-8 py-2 ml-4 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
                      disabled={!isValidAdviceEntries()}
                      onClick={handleMinimumPortfolioAmount}
                    >
                      {isLoading === true ? (
                        <span className="flex flex-row justify-center">
                          <svg
                            className="h-6  w-6 text-[#ffffff] animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </span>
                      ) : (
                        <span className="text-[16px] font-medium text-[#ffffff] font-poppins ">
                          Calculate Min. Investment Value
                        </span>
                      )}
                    </button>
                  )}
                </div>
              ) : (
                <div className="py-4 flex flex-row justify-end items-center px-4 lg:px-8 shadow-[4px_0px_6px_0px_rgba(0,0,0,0.06)]">
                  <button
                    className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-8 py-2 ml-4 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
                    disabled={!isValidAdviceEntries()}
                  >
                    <span className="text-[16px] font-medium text-[#ffffff] font-poppins ">
                      Save
                    </span>
                  </button>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="px-8 w-full flex flex-col  overflow-auto custom-scroll pt-4">
                <div className="w-full flex-wrap lg:flex-nowrap flex flex-row gap-3">
                  <div className="w-full lg:w-[65%] space-y-4">
                    <div>
                      <div className="flex items-center">
                        <div className={style.inputHeaderText}>Name </div>
                        <span className="text-red-500 ml-1">*</span>
                      </div>{" "}
                      <input
                        type="text"
                        value={planNameData.strategyName}
                        // onChange={(e) => setStrategyName(e.target.value)}
                        className={`${style.inputBox}  px-2 font-poppins placeholder:text-gray-400 placeholder:font-normal`}
                        placeholder="Enter Strategy Name"
                      />
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                      <div>
                        <div className="flex items-center">
                          <div className={style.inputHeaderText}>
                            Min. Investment Amount{" "}
                          </div>

                          <span className="text-red-500 ml-1">*</span>
                        </div>

                        <div className="relative mt-1 flex flex-row items-center ">
                          <div className="absolute rounded-l-md text-base text-black font-poppins  py-[10px]  px-2">
                            ₹
                          </div>
                          <input
                            type="number"
                            value={planNameData.minInvestment}
                            // onChange={(e) =>
                            //   setMinInvestmentAmount(e.target.value)
                            // }
                            className={`${style.inputBox}  pl-7 pr-3 sm:pl-8 xl:px-0 xl:pl-[44px] placeholder:text-gray-400 placeholder:font-normal`}
                            placeholder="Ex. 1,00,000"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center">
                          <div className={style.inputHeaderText}>
                            Max Networth
                          </div>

                          <span className="text-red-500 ml-1">*</span>
                        </div>

                        <div className="relative mt-1 flex flex-row items-center">
                          <div className="absolute rounded-l-md text-base text-black font-poppins  py-[10px]  px-2">
                            ₹
                          </div>
                          <input
                            type="number"
                            value={planNameData.maxNetWorth}
                            // onChange={(e) => setMaxNetWorth(e.target.value)}
                            className={`${style.inputBox} pl-7 pr-3 sm:pl-8 xl:px-0 xl:pl-[44px] placeholder:text-gray-400 placeholder:font-normal`}
                            placeholder="Ex. 1,00,000"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full pt-4 ">
                  <div>
                    <div
                      className={`${style.inputHeaderText} flex justify-between pr-1`}
                    >
                      <span>
                        Overview <span className="text-red-500 ml-1">*</span>
                      </span>
                      <span>150</span>
                    </div>
                    <textarea
                      type="text"
                      value={overView}
                      onChange={(e) => setOverView(e.target.value)}
                      className={`h-16 ${style.inputBox}`}
                      placeholder="Describe your strategy in 4-6 lines"
                      maxLength={150}
                    />
                  </div>
                </div>
                <div className="w-full pt-4 ">
                  <div>
                    <div className={`text-base ${style.inputHeaderText}`}>
                      Investment Strategy (Select upto 3)
                    </div>
                    <div className="flex flex-wrap my-4 gap-x-3 gap-y-3 ">
                      {InvestmentStrategyList.map((ele, i) => {
                        return (
                          <div
                            className={
                              isLiked(ele.name)
                                ? `text-[10px] sm:text-[12px] px-2 sm:px-3 group  py-1 sm:py-1.5 font-medium cursor-pointer group border-[1px] border-[#D9D9D9CC]/80  ${style.selectPreferenceDiv} shadow-md hover:shadow-2xl hover:scale-105`
                                : `bg-[#F7F7F7] text-[10px] sm:text-[12px] px-2 sm:px-3 group  py-1 sm:py-1.5 font-medium cursor-pointer group   ${style.selectPreferenceDiv} hover:scale-105`
                            }
                            key={i}
                            onClick={() => handleLike(ele.name)}
                          >
                            {isLiked(ele.name) ? (
                              <FaCheckCircle
                                size={16}
                                color="#18B13F"
                                className={`mr-1 sm:mr-1.5`}
                                onClick={() => handleLike(ele.name)}
                              />
                            ) : (
                              <FaCheckCircle
                                size={16}
                                className={`mr-1 sm:mr-1.5  text-[#000000]/10`}
                                onClick={() => handleLike(ele.name)}
                              />
                            )}
                            {ele.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-4 flex flex-row justify-end items-center px-4 lg:px-8 shadow-[4px_0px_6px_0px_rgba(0,0,0,0.06)]">
                <button
                  disabled={!isValidMeta()}
                  className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-8 py-2 ml-4 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
                  onClick={() => setSelectHeader("Rationale")}
                >
                  <span className="text-[16px] font-medium text-[#ffffff] font-poopins ">
                    Continue
                  </span>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewStrategyModal;
