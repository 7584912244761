import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { XIcon, Plus, AlertCircle, Trash2, Edit } from "lucide-react";
import { debounce } from "lodash";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import server from "../../utils/serverConfig";
import AdminDashboardLayout from "../AdminDashboardLayout";
import { getAdminDetails } from "../CommonApiCall/getAdminDetails";
import LoadingSpinner from "../../components/LoadingSpinner";
import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";
import EmojiPicker from "emoji-picker-react";
import CommentModal from "../AdminHomeSection/CommentModal";
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";

const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;

const PreviousStocksForPurchase = () => {
  const adminDetailsString = localStorage.getItem("userDetails");
  const adminDetails = adminDetailsString
    ? JSON.parse(adminDetailsString)
    : null;
  const adminEmail = adminDetails ? adminDetails.email : null;

  const [data, setData] = useState();
  const [activeTab, setActiveTab] = useState("older-advice");

  useEffect(() => {
    if (adminEmail) {
      getAdminDetails(adminEmail)
        .then((fetchedData) => {
          setData(fetchedData);
        })
        .catch((err) => console.log(err));
    }
  }, [adminEmail]);

  const userId = data && data?._id;

  const advisorSpecifier = data && data.advisorSpecifier;

  const showQuantityColumn = advisorSpecifier === "RIA";

  const [allStocks, setAllStocks] = useState([]);
  const [adviceEntries, setAdviceEntries] = useState([
    {
      symbol: "",
      exchange: "",
      orderType: "MARKET",
      productType: "DELIVERY",
      quantity: advisorSpecifier === "RIA" ? 1 : "",
      segment: "EQUITY",
      price: 0,
      type: "",
      adviceLower: 0,
      adviceHigher: 0,
      inputValue: "",
      symbols: [],
      comments: "",
      comment2: "",
      price_when_send_advice: 0,
      rationale:
        "This recommendation is based on a comprehensive analysis of the company's growth potential and value metrics. This recommendation also accounts for potential future risks, ensuring a balanced approach to maximizing returns while mitigating uncertainties. Please contact your advisor for any queries.",
    },
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const handleAddAdviceEntry = () => {
    const newEntry = {
      id: Date.now(),
      symbol: "",
      exchange: "",
      orderType: "MARKET",
      productType: "DELIVERY",
      quantity: advisorSpecifier === "RIA" ? 1 : "",
      segment: "EQUITY",
      price: 0,
      type: "",
      adviceLower: 0,
      adviceHigher: 0,
      inputValue: "",
      symbols: [],
      comments: "",
      comment2: "",

      advisorType: advisorSpecifier,
      price_when_send_advice: 0,
      rationale: "This recommendation is based on comprehensive analysis...",
    };

    setAdviceEntries([...adviceEntries, newEntry]);
  };

  const handleRemoveAdviceEntry = (index) => {
    const updatedEntries = adviceEntries.filter((_, i) => i !== index);
    setAdviceEntries(updatedEntries);
  };

  const numericFields = ["price", "anotherNumericField"];

  const handleAdviceChange = (index, field, value) => {
    let formattedValue = value;
    // Format value if the field is numeric
    if (numericFields?.includes(field)) {
      // Allow only valid numbers with up to two decimal places
      if (value) {
        const [integerPart, decimalPart] = value.split(".");
        if (decimalPart && decimalPart.length > 2) {
          formattedValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
        } else {
          formattedValue = value;
        }
      }
    }

    const updatedEntries = adviceEntries.map((entry, i) =>
      i === index ? { ...entry, [field]: formattedValue } : entry
    );
    setAdviceEntries(updatedEntries);
  };
  const { getLTPForSymbol } = useWebSocketCurrentPrice(adviceEntries);

  const handleSymbolSelect = (index, symbol, exchange) => {
    const updatedEntries = adviceEntries.map((entry, i) =>
      i === index
        ? {
            ...entry,
            symbol: symbol,
            symbols: [],
            inputValue: symbol,
            exchange: exchange,
          }
        : entry
    );
    setAdviceEntries(updatedEntries);
  };

  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
  const emojiPickerRef = useRef(null);

  const handleEmojiClick = (emojiObject, index, type) => {
    const updatedEntries = [...adviceEntries];
    if (type === "comment") {
      updatedEntries[index].comments += emojiObject.emoji;
    } else if (type === "comment2") {
      updatedEntries[index].comment2 += emojiObject.emoji;
    }
    setAdviceEntries(updatedEntries);
    // Close the emoji picker after selecting an emoji
    setActiveEmojiPicker(null);
  };

  const fetchSymbols = async (index, inputValue) => {
    const data = JSON.stringify({
      symbol: inputValue,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${server.ccxtServer.baseUrl}angelone/get-symbol-name-exchange`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, symbols: response.data.match } : entry
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedFetchSymbols = useCallback(
    debounce((index, value) => {
      fetchSymbols(index, value);
    }, 300),
    []
  );

  const handleInputChange = (index, value) => {
    setAdviceEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, inputValue: value, symbol: value } : entry
      )
    );

    if (value.length >= 3) {
      debouncedFetchSymbols(index, value);
      setIsOpen(true);
    }
  };

  const handleRationaleChange = (index, rationaleMsg) => {
    setAdviceEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, rationale: rationaleMsg } : entry
      )
    );
  };

  const handleCommentsChange = (index, commentsMsg) => {
    if (commentsMsg.length <= 256) {
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, comments: commentsMsg } : entry
        )
      );
    } else {
      // Optionally, you could handle this case (e.g., showing a message or preventing further input)

      toast.error("Comment exceeds the maximum length of 256 characters", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleComments2Change = (index, commentsMsg) => {
    if (commentsMsg.length <= 256) {
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, comment2: commentsMsg } : entry
        )
      );
    } else {
      toast.error(
        "Extended comment exceeds the maximum length of 256 characters",
        {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        }
      );
    }
  };

  const getAllStocksList = () => {
    axios
      .get(`${server.server.baseUrl}api/stocks-list/${advisorTag}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      })
      .then((response) => {
        setAllStocks(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllStocksList();
  }, []);

  const isValid = adviceEntries?.every((entry) => {
    const isPriceRequired =
      entry.orderType === "LIMIT" || entry.orderType === "STOP";
    return (
      entry.symbol &&
      entry.exchange &&
      entry.orderType &&
      entry.productType &&
      entry.segment &&
      entry.type &&
      entry.rationale && // Add rationale validation
      (!isPriceRequired || entry.price)
    );
  });

  const [loading, setLoading] = useState(false);
  const [activeCommentModal, setActiveCommentModal] = useState(null);
  const [modalPosition, setModalPosition] = useState({
    top: 0,
    left: 0,
  });

  const handleSendAdvice = () => {
    setLoading(true);
    const adviceData = adviceEntries.map((entry) => {
      return {
        advisor: advisorTag,
        trade_given_by: adminEmail,
        Symbol: entry.symbol,
        Exchange: entry.exchange,
        Type: entry.type,
        OrderType: entry.orderType,
        ProductType: entry.productType,
        Segment: entry.segment,
        Price: entry.orderType === "MARKET" ? 0 : entry.price,
        Quantity: advisorSpecifier === "RA" ? 0 : entry.quantity,
        Advised_Range_Lower: entry.adviceLower,
        Advised_Range_Higher: entry.adviceHigher,
        rationale: entry?.rationale,
        comments: entry?.comments,
        comment2: entry?.comment2,
        price_when_send_advice: getLTPForSymbol(entry?.symbol),
        group: "priorRecommendationPlan",
        advisorType: advisorSpecifier,
      };
    });
    let config = {
      method: "post",
      url: `${server.server.baseUrl}api/stocks-list`,
      data: adviceData,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        getAllStocksList();
        // processClientsAndSendNotifications();
        toast.success("Advice has been updated successfully", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        setTimeout(() => {
          setAdviceEntries([
            {
              symbol: "",
              exchange: "NSE",
              orderType: "MARKET",
              productType: "DELIVERY",
              quantity: 1,
              segment: "EQUITY",
              price: 0,
              type: "",
              adviceLower: 0,
              adviceHigher: 0,
              inputValue: "",
              symbols: [],
            },
          ]);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in sending Recommendation !", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setLoading(false);
      });
  };

  const [loadingStock, setLoadingStock] = useState(null);

  const handleDeleteAdvice = (symbol) => {
    setLoading(true);
    const adviceData = {
      advisor: advisorTag,
      Symbol: symbol,
    };
    let config = {
      method: "delete",
      url: `${server.server.baseUrl}api/stocks-list`,
      data: adviceData,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        getAllStocksList();
        toast.success("Advice has been updated successfully", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in updating Data !", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setLoading(false);
      });
  };

  const [selected, setSelected] = useState("older-advice");
  return (
    <AdminDashboardLayout>
      <div className="min-h-screen w-full items-center justify-center bg-[#F9F9F9]">
        <Toaster position="top-center" reverseOrder={true} />
        <div className="py-4 px-4 flex flex-row  justify-between border-b border-[#000000]/20 h-auto lg:h-[80px] font-poppins">
          <div className="flex flex-row space-x-6 lg:space-x-8 py-2 ">
            <div
              className={`flex items-center font-semibold font-poppins justify-center text-[18px] md:text-[22px] px-2`}
            >
              {/* Welcome your customer with initial recommendations */}
              Prior Recommendation list
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full bg-white rounded-lg shadow-md">
          <div className="flex border-b">
            <button
              className={`flex-1 py-4 px-6 text-center text-sm font-poppins font-medium ${
                activeTab === "older-advice"
                  ? "border-b-2 border-black text-black"
                  : "text-gray-500 hover:text-black"
              }`}
              onClick={() => setActiveTab("older-advice")}
            >
              {/* Older advice for one time purchase */}
              One time purchase
            </button>
            <button
              className={`flex-1 py-4 px-6 text-center text-sm font-poppins font-medium ${
                activeTab === "onboarding-advice"
                  ? "border-b-2 border-black text-black"
                  : "text-gray-500 hover:text-black"
              }`}
              onClick={() => setActiveTab("onboarding-advice")}
            >
              {/* Onboarding activities */}
              New Customer Onboarding
            </button>
          </div>

          {activeTab === "older-advice" && (
            <>
              <div className="p-6">
                <div className="flex flex-col h-[330px]  w-full">
                  <div className="w-full flex flex-col">
                    <div className="w-full flex flex-row items-center px-6 h-[50px] border-b-[1px] border-[#000000]/10">
                      <div className="w-1/3 text-left text-[#000000]/60 text-[14px] font-poppins font-medium">
                        Symbol
                      </div>
                      <div className="w-1/5 text-left text-[#000000]/60 text-[14px] font-poppins font-medium">
                        Order Type
                      </div>
                      <div className="w-1/6 text-left text-[#000000]/60 text-[14px] font-poppins font-medium">
                        Type
                      </div>
                      <div className="w-1/6 text-right text-[#000000]/60 text-[14px] font-poppins font-medium">
                        Date
                      </div>
                      <div className="w-1/4 text-center text-[#000000]/60 text-[14px] font-poppins font-medium">
                        Actions
                      </div>
                    </div>
                    <div className="flex flex-col w-full h-[calc(330px-50px)] overflow-auto custom-scroll">
                      {allStocks?.map((ele, i) => (
                        <div
                          className="w-full flex flex-row items-center hover:bg-[#000000]/5 px-6 py-[10px] border-b-[1px] border-[#000000]/10"
                          key={i}
                        >
                          <div className="w-1/3 text-left text-[15px] font-medium text-[#000000] font-poppins capitalize">
                            {ele?.Symbol}
                          </div>
                          <div className="w-1/5 text-left text-[15px] font-medium text-[#000000] font-poppins">
                            {ele?.OrderType || "N/A"}
                          </div>
                          <div className="w-1/6 text-left text-[15px] font-medium text-[#000000] font-poppins">
                            {ele?.Type || "N/A"}
                          </div>
                          <div className="w-1/6 text-right text-[15px] font-medium text-[#000000] font-poppins">
                            {ele?.date
                              ? moment(ele?.date).format("Do MMM YYYY")
                              : "No Date"}
                          </div>
                          <div className="w-1/4 flex flex-row gap-4 items-center justify-center">
                            {/* <button className="py-1 px-5 bg-white text-black hover:bg-black font-poppins font-medium border-[1px] border-[#1D1D1F]/20 hover:text-white rounded-md flex flex-row justify-center items-center cursor-pointer transition-all duration-300 ease-linear" > */}
                            <button className=" text-black font-poppins font-medium hover:text-green-600 rounded-md flex flex-row justify-center items-center cursor-pointer transition-all duration-300 ease-linear">
                              <Edit className="w-5 h-5 mr-2" />
                            </button>

                            <button
                              // className="py-1 px-2 bg-white text-black hover:bg-black font-poppins font-medium border-[1px] border-[#1D1D1F]/20 hover:text-white rounded-md flex flex-row justify-center items-center cursor-pointer transition-all duration-300 ease-linear"
                              className=" text-black hover:text-red-600 font-poppins font-medium   rounded-md flex flex-row justify-center items-center cursor-pointer transition-all duration-300 ease-linear"
                              onClick={() => handleDeleteAdvice(ele.Symbol)}
                              disabled={loadingStock === ele.Symbol}
                            >
                              {/* {loadingStock === ele.Symbol ? <LoadingSpinner /> : "Delete"} */}

                              {loadingStock === ele.Symbol ? (
                                <LoadingSpinner />
                              ) : (
                                <>
                                  <Trash2 className="w-5 h-5 mr-2" />
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="overflow-auto custom-scroll px-6 py-4"
                style={{ maxHeight: "calc(92vh - 180px)" }}
              >
                <div className="mb-4 flex flex-row justify-between items-center space-y-2 sm:space-y-0">
                  <button
                    onClick={handleAddAdviceEntry}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors"
                  >
                    <Plus className="w-4 h-4 mr-2" />
                    Add Entry
                  </button>
                  <span className="text-sm text-gray-500">
                    {adviceEntries.length} entr
                    {adviceEntries.length === 1 ? "y" : "ies"}
                  </span>
                </div>

                <div className="overflow-x-auto custom-scroll rounded-lg border border-gray-200 shadow-sm">
                  <table className="min-w-full divide-y divide-gray-200">
                    <colgroup>
                      <col className="w-[250px]" />{" "}
                      {/* Symbol - increased width */}
                      <col className="w-[150px]" /> {/* Order Type */}
                      <col className="w-[150px]" /> {/* Product Type */}
                      <col className="w-[120px]" /> {/* Segment */}
                      <col className="w-[180px]" />{" "}
                      {/* Type - increased width */}
                      {showQuantityColumn && <col className="w-[100px]" />}{" "}
                      {/* Quantity */}
                      <col className="w-[100px]" /> {/* LTP */}
                      <col className="w-[150px]" /> {/* Price */}
                      <col className="w-[200px]" /> {/* Advised Range */}
                      <col className="w-[200px]" /> {/* Comments */}
                      <col className="w-[300px]" /> {/* Rationale */}
                      <col className="w-[80px]" /> {/* Actions */}
                    </colgroup>

                    <thead className="bg-gray-50">
                      <tr className="h-16">
                        {" "}
                        {/* Increased header height */}
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Symbol <span className="text-red-500">*</span>
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Order Type <span className="text-red-500">*</span>
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Product Type <span className="text-red-500">*</span>
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Segment <span className="text-red-500">*</span>
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Type <span className="text-red-500">*</span>
                        </th>
                        {showQuantityColumn && (
                          <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Quantity
                          </th>
                        )}
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          LTP
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Price <span className="text-red-500">*</span>
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Advised Range
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Rationale <span className="text-red-500">*</span>
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Comments
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Extended Comment
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>

                    <tbody className="bg-white divide-y divide-gray-200">
                      {adviceEntries.map((entry, index) => (
                        <tr
                          key={entry.id}
                          className="hover:bg-gray-50 transition-colors min-h-[120px]"
                        >
                          {" "}
                          {/* Increased row height */}
                          {/* Symbol */}
                          <td className="px-4 py-4">
                            {" "}
                            {/* Increased cell padding */}
                            <div className="relative">
                              <input
                                type="text"
                                value={entry.inputValue}
                                onChange={(e) =>
                                  handleInputChange(index, e.target.value)
                                }
                                className="w-full min-w-[220px] px-3 py-2.5 border border-gray-300 rounded-md"
                                placeholder="Enter symbol"
                              />
                              {entry.symbols.length > 0 && isOpen && (
                                <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg">
                                  {entry.symbols.map((symbol, idx) => (
                                    <div
                                      key={idx}
                                      onClick={() =>
                                        handleSymbolSelect(
                                          index,
                                          symbol.symbol,
                                          symbol.segment
                                        )
                                      }
                                      className="px-4 py-2.5 hover:bg-gray-100 cursor-pointer"
                                    >
                                      {symbol.symbol} - {symbol.segment}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </td>
                          {/* Order Type */}
                          <td className="px-4 py-4">
                            <select
                              value={entry.orderType}
                              onChange={(e) =>
                                handleAdviceChange(
                                  index,
                                  "orderType",
                                  e.target.value
                                )
                              }
                              className="w-full min-w-[140px] font-poppins text-base px-3 py-2.5 border border-gray-300 rounded-md"
                            >
                              {["MARKET", "LIMIT", "STOP"].map((type) => (
                                <option key={type} value={type}>
                                  {type}
                                </option>
                              ))}
                            </select>
                          </td>
                          {/* Product Type */}
                          <td className="px-4 py-4">
                            <select
                              value={entry.productType}
                              onChange={(e) =>
                                handleAdviceChange(
                                  index,
                                  "productType",
                                  e.target.value
                                )
                              }
                              className="w-full min-w-[140px] font-poppins text-base px-3 py-2.5 border border-gray-300 rounded-md"
                            >
                              {["DELIVERY", "INTRADAY"].map((type) => (
                                <option key={type} value={type}>
                                  {type}
                                </option>
                              ))}
                            </select>
                          </td>
                          {/* Segment */}
                          <td className="px-4 py-4">
                            <select
                              value={entry.segment}
                              onChange={(e) =>
                                handleAdviceChange(
                                  index,
                                  "segment",
                                  e.target.value
                                )
                              }
                              className="w-full min-w-[120px] font-poppins text-base px-3 py-2.5 border border-gray-300 rounded-md"
                            >
                              <option value="EQUITY">EQUITY</option>
                            </select>
                          </td>
                          {/* Type */}
                          <td className="px-4 py-4">
                            <select
                              value={entry.type}
                              onChange={(e) =>
                                handleAdviceChange(
                                  index,
                                  "type",
                                  e.target.value
                                )
                              }
                              className="w-full min-w-[150px] font-poppins text-base px-3 py-2.5 border border-gray-300 rounded-md"
                            >
                              <option value="">Select Type</option>
                              {["BUY", "SELL"].map((type) => (
                                <option key={type} value={type}>
                                  {type}
                                </option>
                              ))}
                            </select>
                          </td>
                          {/* Quantity */}
                          {showQuantityColumn && (
                            <td className="px-4 py-4">
                              <input
                                type="number"
                                value={entry.quantity}
                                onChange={(e) =>
                                  handleAdviceChange(
                                    index,
                                    "quantity",
                                    e.target.value
                                  )
                                }
                                className="w-full  font-poppins text-basepx-3 py-2.5 border border-gray-300 rounded-md"
                                min="1"
                              />
                            </td>
                          )}
                          {/* LTP */}
                          <td className="px-4 py-4">
                            <div className="text-right">
                              {entry.symbol && getLTPForSymbol(entry.symbol)}
                            </div>
                          </td>
                          {/* Price */}
                          <td className="px-4 py-4 min-w-[150px]">
                            {" "}
                            {/* Added minimum width to td */}
                            {entry.orderType === "MARKET" ? (
                              <div className="text-right w-full min-w-[10px]">
                                {entry.symbol && getLTPForSymbol(entry.symbol)}
                              </div>
                            ) : (
                              <input
                                type="number"
                                value={entry.price || ""}
                                onChange={(e) =>
                                  handleAdviceChange(
                                    index,
                                    "price",
                                    e.target.value
                                  )
                                }
                                className="w-full min-w-[10px] px-3 py-2.5 border border-gray-300 rounded-md"
                                step="0.01"
                              />
                            )}
                          </td>
                          {/* Advised Range */}
                          <td className="px-4 py-4">
                            <div className="flex space-x-2">
                              <input
                                type="number"
                                value={entry.adviceLower || ""}
                                onChange={(e) =>
                                  handleAdviceChange(
                                    index,
                                    "adviceLower",
                                    e.target.value
                                  )
                                }
                                className="w-24 px-3 py-2.5 border border-gray-300 rounded-md"
                                placeholder="Low"
                              />
                              <span className="self-center">-</span>
                              <input
                                type="number"
                                value={entry.adviceHigher || ""}
                                onChange={(e) =>
                                  handleAdviceChange(
                                    index,
                                    "adviceHigher",
                                    e.target.value
                                  )
                                }
                                className="w-24 px-3 py-2.5 border border-gray-300 rounded-md"
                                placeholder="High"
                              />
                            </div>
                          </td>
                          {/* Rationale */}
                          <td className="px-4 py-4">
                            <textarea
                              value={entry.rationale}
                              onChange={(e) =>
                                handleRationaleChange(index, e.target.value)
                              }
                              className="w-full min-w-[280px] font-poppins px-3 py-2.5 border border-gray-300 rounded-md"
                              rows="3"
                              placeholder="Enter rationale for this trade..."
                            />
                          </td>
                          {/* Comments */}
                          <td className="relative px-4 py-4">
                            <div className="relative w-full min-w-[250px]">
                              <textarea
                                value={entry.comments}
                                readOnly
                                placeholder="Click to edit comment"
                                onClick={(e) => {
                                  setActiveCommentModal({
                                    index,
                                    type: "comments",
                                  });
                                }}
                                className="w-full px-3 py-2.5 border border-gray-300 rounded-md pr-10"
                                rows={3}
                              />

                              <CommentModal
                                isOpen={
                                  activeCommentModal?.index === index &&
                                  activeCommentModal?.type === "comments"
                                }
                                onClose={() => setActiveCommentModal(null)}
                                initialValue={entry.comments}
                                onSave={(value) =>
                                  handleCommentsChange(index, value)
                                }
                                position={modalPosition}
                                maxLength={256}
                              />
                            </div>
                          </td>
                          {/* Comments2 */}
                          <td className="realtive px-4 py-4">
                            <div className="relative w-full min-w-[250px]">
                              <textarea
                                value={entry.comment2}
                                readOnly
                                placeholder="Click to edit extended comment"
                                onClick={(e) => {
                                  setActiveCommentModal({
                                    index,
                                    type: "comment2", // Changed this to differentiate from comments
                                  });
                                }}
                                className="w-full min-w-[200px] px-3 py-2.5 border border-gray-300 rounded-md"
                                rows="3"
                              />

                              <CommentModal
                                isOpen={
                                  activeCommentModal?.index === index &&
                                  activeCommentModal?.type === "comment2" // Match with the type above
                                }
                                onClose={() => setActiveCommentModal(null)}
                                initialValue={entry.comment2}
                                onSave={(value) =>
                                  handleComments2Change(index, value)
                                }
                                position={modalPosition}
                                maxLength={256}
                              />
                            </div>
                          </td>
                          {/* Actions */}
                          <td className="px-4 py-4">
                            {adviceEntries.length > 1 && (
                              <button
                                onClick={() => handleRemoveAdviceEntry(index)}
                                className="text-red-500 hover:text-red-700"
                              >
                                <XIcon className="h-5 w-5" />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {activeEmojiPicker && (
                <div
                  ref={emojiPickerRef}
                  className="absolute z-20"
                  style={{
                    right:
                      activeEmojiPicker.type === "comment" ? "120px" : "395px",
                    bottom: "20px",
                  }}
                >
                  <EmojiPicker
                    onEmojiClick={(emojiObject) =>
                      handleEmojiClick(
                        emojiObject,
                        activeEmojiPicker.index,
                        activeEmojiPicker.type
                      )
                    }
                    width={280}
                    height={350}
                  />
                </div>
              )}
              {/* Footer */}
              <div className="sticky bottom-0 z-10 bg-gray-50 px-6 py-4 border-t border-gray-200">
                <div className="flex flex-col sm:flex-row items-center justify-between">
                  {!isValid && (
                    <div className="flex items-center text-red-500">
                      <AlertCircle className="w-4 h-4 mr-2" />
                      <span className="text-sm">
                        Please fill in all required fields
                      </span>
                    </div>
                  )}
                  <div className="flex space-x-3">
                    <button
                      onClick={handleSendAdvice}
                      disabled={!isValid || loading}
                      className="inline-flex items-center px-4 py-2  text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors"
                    >
                      {loading ? (
                        <span className="flex items-center">
                          <LoadingSpinner className="w-4 h-4 mr-2" />
                          Sending...
                        </span>
                      ) : (
                        "Save Advice"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {activeTab === "onboarding-advice" && (
            <div className="flex flex-col items-center justify-center h-[600px] w-full">
              <span className={`text-3xl font-semibold font-poppins`}>
                Coming soon...
              </span>
            </div>
          )}
        </div>
      </div>
    </AdminDashboardLayout>
  );
};

export default PreviousStocksForPurchase;
