import React from "react";

const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
const termsData = [
  {
    heading: "Information We Collect",
    text: (
      <>
        <ul>
          <li>
            <p className="font-semibold">1.1. Personal Information </p>
            <p>
              We may collect personal information that you provide directly to
              us, such as your name, email address, phone number, financial
              information, and other details necessary for you to use our
              Service.
            </p>
          </li>
          <li>
            <p className="font-semibold">1.2. Usage Data </p>
            <p>
              We automatically collect information about your interactions with
              the Service, including IP addresses, browser types, operating
              systems, pages visited, and the time spent on the Service. This
              data helps us understand how users engage with our Service and
              improve its performance.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              1.3. Cookies and Tracking Technologies{" "}
            </p>
            <p>
              We use cookies and similar tracking technologies to enhance your
              experience on our Service. Cookies are small files stored on your
              device that help us remember your preferences and provide a more
              personalized experience. You can control cookies through your
              browser settings, but disabling cookies may affect the
              functionality of the Service.
            </p>
          </li>
        </ul>
      </>
    ),
  },
  {
    heading: "How We Use Your Information",
    text: (
      <>
        <ul>
          <li>
            <p className="font-semibold">
              2.1. To Provide and Improve the Service
            </p>
            <p>
              We use your information to deliver, maintain, and improve the
              Service. This includes processing your requests, sending you
              updates, and ensuring that the Service operates smoothly.
            </p>
          </li>
          <li>
            <p className="font-semibold">2.2. To Communicate with You</p>
            <p>
              We may use your contact information to communicate with you about
              your account, respond to your inquiries, and provide you with
              important information regarding the Service.
            </p>
          </li>
          <li>
            <p className="font-semibold">2.3. For Analytics and Research</p>
            <p>
              We use aggregated and anonymized data to analyze usage patterns
              and improve the Service. This helps us understand user behavior
              and make data-driven decisions.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              2.4. For Marketing and Promotional Purposes{" "}
            </p>
            <p>
              With your consent, we may use your information to send you
              promotional materials and updates about our products and services.
              You can opt-out of receiving these communications at any time by
              following the instructions provided in the communication.
            </p>
          </li>
        </ul>
      </>
    ),
  },

  {
    heading: "How We Share Your Information",
    text: (
      <>
        <ul>
          <li>
            <p className="font-semibold">3.1. Service Providers</p>
            <p>
              We may share your information with third-party service providers
              who assist us in operating the Service, such as hosting providers,
              payment processors, and email service providers. These third
              parties are contractually obligated to protect your information
              and use it only for the purposes for which it was shared.
            </p>
          </li>
          <li>
            <p className="font-semibold">3.2. Legal Requirements</p>
            <p>
              We may disclose your information if required to do so by law or in
              response to valid requests by public authorities, such as in
              connection with legal proceedings, regulatory requirements, or to
              protect our rights, property, or safety.
            </p>
          </li>
          <li>
            <p className="font-semibold">3.3. Business Transfers</p>
            <p>
              In the event of a merger, acquisition, or any form of asset sale,
              your information may be transferred as part of the business
              transaction. We will notify you of any such change in ownership or
              control of your information.
            </p>
          </li>
        </ul>
      </>
    ),
  },
  {
    heading: "Data Security",
    text: (
      <>
        <ul>
          <li>
            <p className="font-semibold">4.1. Protection Measures</p>
            <p>
              We implement reasonable security measures to protect your
              information from unauthorized access, use, or disclosure. This
              includes using encryption, secure servers, and access controls.
            </p>
          </li>
          <li>
            <p className="font-semibold">4.2. Limitations </p>
            <p>
              Despite our efforts, no security system is impenetrable. We cannot
              guarantee the security of your information transmitted to or from
              our Service, however best industry standards as per our understand are utilized for security.
            </p>
          </li>
        </ul>
      </>
    ),
  },
  {
    heading: "Your Rights and Choices",
    text: (
      <>
        <ul>
          <li>
            <p className="font-semibold">5.1. Access and Correction</p>
            <p>
              You have the right to access and update your personal information.
              If you need to correct or update your information, please contact
              us at{" "}
              <a href="hello@alphaquark.in" className="text-blue-500 underline">
                hello@alphaquark.in
              </a>
            </p>
          </li>
          <li>
            <p className="font-semibold">5.2. Data Deletion</p>
            <p>
              You may request the deletion of your personal information. We will
              comply with such requests, subject to any legal or contractual
              obligations we may have.
            </p>
          </li>
          <li>
            <p className="font-semibold">5.3. Opt-Out</p>
            <p>
              You can opt-out of receiving marketing communications from us by
              following the unsubscribe instructions provided in those
              communications or by contacting us directly.
            </p>
          </li>
        </ul>
      </>
    ),
  },
  {
    heading: "Data Retention",
    text: "We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, to comply with legal obligations, or to enforce our agreements. When your information is no longer needed, we will securely delete or anonymize it.",
  },
  {
    heading: "Third-Party Links",
    text: "Our Service may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties. We encourage you to review the privacy policies of any third-party sites you visit.",
  },
  {
    heading: "Children’s Privacy",
    text: "Our Service is not intended for children under the age of 18. We do not knowingly collect or solicit personal information from children. If we become aware that we have collected personal information from a child without parental consent, we will take steps to delete such information.",
  },
  {
    heading: "International Transfers",
    text: "If you are located outside India, please be aware that your information may be transferred to and processed in India. By using our Service, you consent to the transfer of your information to India and other countries where our service providers may operate.",
  },
  {
    heading: "Changes to This Privacy Policy",
    text: "We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.",
  },
  {
    heading: "Contact Us",
    text: (
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at{" "}
        <a href="hello@alphaquark.in" className="text-blue-500 underline">
          hello@alphaquark.in
        </a>
      </p>
    ),
  },
  {
    heading: "Limitation of Liability",
    text: "To the fullest extent permitted by law, {whiteLabelText} will not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or other intangible losses, arising out of or in connection with our handling of your personal information.",
  },
];

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col relative  w-full lg:min-h-screen  bg-[#f9f9f9] ">
      <div className="border-b-[1px] border-[#000000]/10 px-[20px]  sm:px-[30px] py-[14px] lg:px-20 lg:py-3 flex items-center justify-between">
        <h2 className=" font-sans text-[18px] sm:text-[24px] md:text-[28px] font-bold md:leading-[60px] text-left ">
          Privacy & Policy
        </h2>
      </div>
      <div className="mt-6 space-y-3 list-decimal list-inside px-[20px]  sm:px-[30px] pb-[10px] lg:px-20  lg:pb-3">
        <p className="font-poppins text-[16px] font-normal leading-[28px] text-left mt-2">
          Last Updated: 1 Feb 2025
        </p>
        <p className="font-poppins text-[16px] font-normal leading-[28px] text-left">
          Welcome to {whiteLabelText}. This Privacy Policy describes how we
          collect, use, disclose, and protect your personal information when you
          use our software and services (collectively, the “Service”). By using
          our Service, you consent to the practices described in this Privacy
          Policy.
        </p>
      </div>

      <ul className="mt-6 space-y-6 list-decimal list-inside px-[20px]  sm:px-[30px] pb-[10px] lg:px-20  lg:pb-3">
        {termsData.map((term, index) => (
          <li key={index} className="font-semibold text-[#161515CC]">
            <span className="font-sans text-[18px] font-bold leading-[40px] text-left text-[#161515CC]">
              {term.heading}
            </span>
            <p className="font-poppins text-[16px] font-normal leading-[28px] text-left mt-2">
              {term.text}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
