import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import axios from "axios";
import CryptoJS from "crypto-js";
import RejectedTradesInfoBanner from "./RejectedTradesInfoBanner"; // add this import
import {
  TrendingUp,
  EyeIcon,
  EyeOffIcon,
  Info,
  X,
  CandlestickChartIcon,
} from "lucide-react";

import { auth } from "../../firebase";
import server from "../../utils/serverConfig";
import PlaceOrders from "./PlaceOrders";
import NewStockCard from "../StockRecommendation/NewStockCard";
import ConnectBroker from "../LivePortfolioSection/connectBroker";
import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";
import { fetchFunds } from "../../FunctionCall/fetchFunds";

import ReviewTradeModel from "../StockRecommendation/ReviewTradeModel";
import ZerodhaReviewModal from "../StockRecommendation/ZerodhaReviewModal";
import UpdateUserDeatils from "../LivePortfolioSection/UpdateUserDetails";
import RecommendationSuccessModal from "../StockRecommendation/RecommendationSuccessModal";
import { TokenExpireModal } from "../RootSection/TokenExpireModal";
import { IgnoreTradeModal } from "../StockRecommendation/IgnoreTradeModal";
import IsMarketHours from "../../utils/isMarketHours";
import { motion, AnimatePresence } from "framer-motion";
import DdpiModal from "../StockRecommendation/DdpiModal";
import { AngleOneTpinModal } from "../StockRecommendation/DdpiModal";
import { DhanTpinModal } from "../StockRecommendation/DdpiModal";
import { OtherBrokerModel } from "../StockRecommendation/DdpiModal";
import { encryptApiKey } from "../../utils/cryptoUtils";
import appConfig from "../../utils/appConfig";

import { createPlaceOrderFunction } from "../ProcessTrades/ProcessTrades";
import TokenExpireBrokarModal from "../RootSection/TokenExpireBrokarModal";
const BROKER_ENDPOINTS = {
  "IIFL Securities": "iifl",
  Kotak: "kotak",
  Upstox: "upstox",
  "ICICI Direct": "icici",
  "Angel One": "angelone",
  Zerodha: "zerodha",
  Fyers: "fyers",
  AliceBlue: "aliceblue",
  Dhan: "dhan",
};

const style = {
  selected:
    "flex items-center text-[15px] leading-[40px] lg:text-[16px]   text-white font-bold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected:
    "flex items-center text-[15px] leading-[25px] font-medium  lg:text-[16px]  text-white/40 lg:leading-[42px] cursor-pointer",
  firstHeading: "text-sm text-[#00000099] text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-[18px]  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-[1px] hover:ring-[2px] ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-[16px] peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full  ",
};

const defaultRationale = `This recommendation is based on a comprehensive analysis of the
        company's growth potential and value metrics. This recommendation
        also accounts for potential future risks, ensuring a balanced
        approach to maximizing returns while mitigating uncertainties.
        Please contact your advisor for any queries.`;

const showAdviceStatusDays = process.env.REACT_APP_ADVICE_SHOW_LATEST_DAYS;
const appURL = process.env.REACT_APP_URL;
const zerodhaApiKey = process.env.REACT_APP_ZERODHA_API_KEY;
const angelOneApiKey = process.env.REACT_APP_ANGEL_ONE_API_KEY;
const brokerConnectRedirectURL =
  process.env.REACT_APP_BROKER_CONNECT_REDIRECT_URL;

const OrderBookDetails = ({ getAllTradesUpdate }) => {
  // user details fetch
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [userDetails, setUserDetails] = useState();

  const getUserDetails = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      })
      .then((res) => {
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserDetails();
  }, [userEmail, server.server.baseUrl]);

  const [brokerModel, setBrokerModel] = useState(null);
  const [brokerStatus, setBrokerStatus] = useState(
    userDetails ? userDetails.connect_broker_status : null
  );

  const [updateUserDetails, setUpdateUserDetails] = useState(false);
  useEffect(() => {
    if (userDetails && userDetails.user_broker !== undefined) {
      setBrokerStatus(userDetails && userDetails.connect_broker_status);
    }
  }, [userDetails, brokerStatus]);

  // all recommendation
  const [allTrades, setAllTrades] = useState([]);
  const [rejectedTrades, setRejectedTrades] = useState([]);
  const [setExecutedTrades] = useState([]);
  const getAllTrades = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/user/trade-reco-for-user?user_email=${userEmail}`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };
    axios
      .request(config)
      .then((response) => {
        setAllTrades(response.data.trades);
        const rejectedTradesWithoutRebalance = response?.data?.trades.filter(
          (trade) => {
            const tradeDate = new Date(trade?.purchaseDate);
            return (
              (trade.trade_place_status === "rejected" || "REJECTED") &&
              (trade.rebalance_status === undefined ||
                trade.rebalance_status === null) &&
              tradeDate >= showAdviceStatusDays
            );
          }
        );
        setRejectedTrades(rejectedTradesWithoutRebalance);
        // Update executedTrades
        const executedTradesFiltered = response.data.trades.filter((trade) => {
          return (
            trade.trade_place_status !== "recommend" &&
            trade.trade_place_status !== "ignored"
          );
        });
        setExecutedTrades(executedTradesFiltered);
      })
      .catch((error) => {
        console.log("Error fetching trades:", error);
      });
  };
  useEffect(() => {
    getAllTrades();
  }, []);

  // executed tardes trades
  const filterExecutedTrades = () => {
    const filteredTrades = allTrades.filter((trade) => {
      return (
        trade.trade_place_status !== "recommend" &&
        trade.trade_place_status !== "ignored"
        // trade.trade_place_status !== "rejected"
      );
    });
    return filteredTrades;
  };
  const executedTrades = filterExecutedTrades();

  const [selectedRecommendation, setSelectedRecommendation] =
    useState("orders-placed");
  const [stockDetails, setStockDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const clientCode = userDetails && userDetails.clientCode;
  const apiKey = userDetails && userDetails.apiKey;
  const jwtToken = userDetails && userDetails.jwtToken;
  const secretKey = userDetails && userDetails.secretKey;
  const viewToken = userDetails && userDetails?.viewToken;
  const sid = userDetails && userDetails?.sid;
  const serverId = userDetails && userDetails?.serverId;
  const mobileNumber = userDetails && userDetails?.phone_number;
  const panNumber = userDetails && userDetails?.panNumber;

  const [broker, setBroker] = useState("");
  const [openReviewTrade, setOpenReviewTrade] = useState(false);
  const [openSuccessModal, setOpenSucessModal] = useState(false);
  const [orderPlacementResponse, setOrderPlacementResponse] = useState();
  const [openTokenExpireModel, setOpenTokenExpireModel] = useState(null);
  const [types, setTypes] = useState([]);
  const [showAngleOneTpinModel, setShowAngleOneTpinModel] = useState(false);
  const [showDhanTpinModel, setShowDhanTpinModel] = useState(false);
  const [showDdpiModal, setShowDdpiModal] = useState(false);

  const [edisStatus, setEdisStatus] = useState(null);
  const [dhanEdisStatus, setDhanEdisStatus] = useState(null);

  const [showDhanTpinModal, setShowDhanTpinModal] = useState(false);
  const [singleStockTypeAndSymbol, setSingleStockTypeAndSymbol] =
    useState(null);

  const handleCloseDdpiModal = () => {
    setShowDdpiModal(false);
  };

  const handleProceedWithTpin = () => {
    setShowDdpiModal(false);
    setOpenZerodhaModel(true);
  };

  const handleOpenDhanTpinModal = (stockTypeAndSymbol) => {
    setSingleStockTypeAndSymbol(stockTypeAndSymbol);
    setShowDhanTpinModal(true);
  };

  const [isReturningFromOtherBrokerModal, setIsReturningFromOtherBrokerModal] =
    useState(false);

  useEffect(() => {
    if (userDetails) {
      setBroker(userDetails.user_broker);
    }
  }, [userDetails]);

  const dateString = userDetails && userDetails.token_expire;
  // Format the moment object as desired
  const expireTokenDate = moment(dateString).format("YYYY-MM-DD HH:mm:ss");

  const today = new Date();
  const todayDate = moment(today).format("YYYY-MM-DD HH:mm:ss");
  const userId = userDetails && userDetails._id;

  const checkValidApiAnSecret = (details) => {
    try {
      const bytesKey = CryptoJS.AES.decrypt(details, "ApiKeySecret");
      const Key = bytesKey.toString(CryptoJS.enc.Utf8); // Convert to UTF-8 string

      if (Key) {
        return Key;
      } else {
        throw new Error("Decryption failed or invalid key.");
      }
    } catch (error) {
      console.error("Error during decryption:", error.message);
      return null;
    }
  };

  const updatePortfolioData = async (brokerName, userEmail) => {
    try {
      const endpoint = BROKER_ENDPOINTS[brokerName];
      if (!endpoint) {
        console.error(`Unsupported broker: ${brokerName}`);
        return;
      }

      const config = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}${endpoint}/user-portfolio`,
        data: JSON.stringify({ user_email: userEmail }),
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      };

      return await axios.request(config);
    } catch (error) {
      console.error(`Error updating portfolio for ${brokerName}:`, error);
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // ignore trade
  const [openIgnoreTradeModel, setOpenIgnoreTradeModel] = useState(false);
  const [stockIgnoreId, setStockIgnoreId] = useState();
  const [ignoreLoading, setIgnoreLoading] = useState(false);
  const [ignoreText, setIgnoreText] = useState("");

  const handleIgnoredTrades = (id) => {
    setIgnoreLoading(true);
    let data = JSON.stringify({
      uid: id,
      trade_place_status: "ignored",
      reason: ignoreText,
    });

    // Second API request to place the order
    let orderConfig = {
      method: "put",
      url: `${server.server.baseUrl}api/recommendation`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(orderConfig)
      .then((response) => {
        toast.success("You have successfully ignored your trade", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        setIgnoreLoading(false);
        setOpenIgnoreTradeModel(false);
        getAllTrades();
      })
      .catch((error) => {
        console.error(`Error placing order for stock `, error);
        setLoading(false);
      });
  };

  const { getLTPForSymbol } = useWebSocketCurrentPrice(rejectedTrades);

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    stockDetails.forEach((ele) => {
      if (ele.transactionType === "BUY") {
        const ltp = getLTPForSymbol(ele.tradingSymbol); // Get LTP for current symbol
        if (ltp !== "-") {
          totalAmount += parseFloat(ltp) * ele.quantity; // Calculate total amount for this trade
        }
      }
    });
    return totalAmount.toFixed(2); // Return total amount formatted to 2 decimal places
  };

  const [openZerodhaModel, setOpenZerodhaModel] = useState(false);
  const [selectedLength, setSelectedLength] = useState();
  const [singleStockSelectState, setSingleStockSelectState] = useState(false);
  const [showOtherBrokerModel, setShowOtherBrokerModel] = useState(false);

  const getCartAllStocks = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/cart/${userEmail}?trade_place_status=REJECTED,rejected,Rejected`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then((response) => {
        const transformedStockDetails = response?.data
          .filter(
            (stock) =>
              (stock.trade_place_status === "rejected" || "REJECTED") &&
              (stock.rebalance_status === undefined ||
                stock.rebalance_status === null)
          )
          .map((stock) => ({
            user_email: stock.user_email,
            trade_given_by: stock.trade_given_by,
            tradingSymbol: stock.Symbol,
            transactionType: stock.Type,
            exchange: stock.Exchange,
            segment: stock.Segment,
            productType:
              stock.Exchange === "NFO" || stock.Exchange === "BFO"
                ? "CARRYFORWARD"
                : stock.ProductType,
            orderType: stock.OrderType,
            price: stock.Price,
            quantity: stock.Quantity,
            priority: stock.Priority,
            tradeId: stock.tradeId,
            user_broker: broker,
            trade_place_status: stock.trade_place_status,
            rebalance_status: stock.rebalance_status,
          }));
        // Extract and store all Types to use this in handletrade

        const extractedTypes = response?.data?.map((stock) => stock.Type);
        setTypes(extractedTypes);

        //  console.log("setTypes",extractedTypes)
        setStockDetails(transformedStockDetails);
        setSelectedLength(transformedStockDetails);
        const hasSell = extractedTypes.some((type) => type === "SELL");
        const hasBuy = extractedTypes.some((type) => type === "BUY");
        const allSell = hasSell && !hasBuy;
        const allBuy = hasBuy && !hasSell;
        const isMixed = hasSell && hasBuy;

        setTradeType({
          allSell: allSell,
          allBuy: allBuy,
          isMixed: isMixed,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (userEmail) {
      getCartAllStocks();
    }
  }, [userEmail]);

  const [isSelectAllLoading, setIsSelectAllLoading] = useState(false);
  const handleSelectAllStocks = async () => {
    const newStockDetails = rejectedTrades?.reduce((acc, stock) => {
      const isSelected = stockDetails.some(
        (selectedStock) => selectedStock.tradeId === stock.tradeId
      );

      if (!isSelected) {
        const ltp = getLTPForSymbol(stock.Symbol);
        const advisedRangeLower = stock.Advised_Range_Lower;
        const advisedRangeHigher = stock.Advised_Range_Higher;

        const shouldDisableTrade =
          (advisedRangeHigher === 0 && advisedRangeLower === 0) ||
          (advisedRangeHigher === null && advisedRangeLower === null) ||
          (advisedRangeHigher > 0 &&
            advisedRangeLower > 0 &&
            parseFloat(advisedRangeHigher) >= parseFloat(ltp) &&
            parseFloat(ltp) >= parseFloat(advisedRangeLower)) ||
          (advisedRangeHigher > 0 &&
            advisedRangeLower === 0 &&
            advisedRangeLower === null &&
            parseFloat(advisedRangeHigher) >= parseFloat(ltp)) ||
          (advisedRangeLower > 0 &&
            advisedRangeHigher === 0 &&
            advisedRangeHigher === null &&
            parseFloat(advisedRangeLower) <= parseFloat(ltp));

        if (shouldDisableTrade) {
          const newStock = {
            user_email: stock.user_email,
            trade_given_by: stock.trade_given_by,
            tradingSymbol: stock.Symbol,
            transactionType: stock.Type,
            exchange: stock.Exchange,
            segment: stock.Segment,
            productType: stock.ProductType,
            orderType: stock.OrderType,
            price: stock.Price,
            quantity: stock.Quantity,
            priority: stock.Priority,
            tradeId: stock.tradeId,
            user_broker: broker,
          };
          acc.push(newStock);
        }
      }
      setIsSelectAllLoading(false);

      return acc;
    }, []);

    if (newStockDetails.length > 0) {
      setIsSelectAllLoading(true);

      try {
        await axios.post(
          `${server.server.baseUrl}api/cart/add/add-multiple-to-cart`,
          {
            stocks: newStockDetails,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        );
        // Optionally, update the state to reflect the changes in the UI
        getCartAllStocks();
      } catch (error) {
        console.error("Error adding stocks to cart", error);
      } finally {
        setIsSelectAllLoading(false);
      }
    }
  };

  const handleRemoveAllSelectedStocks = async () => {
    setIsSelectAllLoading(true);
    try {
      // Use all stock details in the cart for removal
      const stocksToRemove = [...stockDetails];

      if (stocksToRemove.length > 0) {
        await axios.post(
          `${server.server.baseUrl}api/cart/cart-items/remove/multiple/remove-multiple-from-cart`,
          {
            stocks: stocksToRemove,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        );

        // Clear stockDetails since all stocks are removed
        setStockDetails([]);
        getCartAllStocks(); // Refresh the cart
      }
    } catch (error) {
      console.error("Error removing stocks from cart", error);
    } finally {
      setIsSelectAllLoading(false);
    }
  };

  // zerodha start
  const handleZerodhaRedirect = async () => {
    localStorage.setItem(
      "stockDetailsZerodhaOrder",
      JSON.stringify(stockDetails)
    );

    const apiKey = zerodhaApiKey;

    const basket = stockDetails.map((stock) => {
      let baseOrder = {
        variety: "regular",
        tradingsymbol: stock.tradingSymbol,
        exchange: stock.exchange,
        transaction_type: stock.transactionType,
        order_type: stock.orderType,
        quantity: stock.quantity,
        readonly: false,
        price: stock.price,
        tag: stock.zerodhaTradeId ? stock.zerodhaTradeId : "NA",
      };

      // Get the LTP for the current stock
      const ltp = getLTPForSymbol(stock.tradingSymbol);

      // If LTP is available and not '-', use it as the price
      if (ltp !== "-") {
        baseOrder.price = parseFloat(ltp);
      }

      // If it's a LIMIT order, use the LTP as the price
      if (stock.orderType === "LIMIT") {
        // For LIMIT orders, always use the limit price specified
        baseOrder.price = parseFloat(stock.price || 0);
      } else if (stock.orderType === "MARKET") {
        // For MARKET orders, get LTP
        const ltp = getLTPForSymbol(stock.tradingSymbol);
        if (ltp !== "-") {
          baseOrder.price = parseFloat(ltp);
        } else {
          baseOrder.price = 0; // Default price for market orders
        }
      }

      if (stock.quantity > 100) {
        baseOrder.readonly = true;
      }

      return baseOrder;
    });

    const form = document.createElement("form");
    form.method = "POST";

    form.action = `https://kite.zerodha.com/connect/basket`;

    // form.target = "_blank";

    const apiKeyInput = document.createElement("input");
    apiKeyInput.type = "hidden";
    apiKeyInput.name = "api_key";
    apiKeyInput.value = apiKey;

    const dataInput = document.createElement("input");
    dataInput.type = "hidden";
    dataInput.name = "data";
    dataInput.value = JSON.stringify(basket);

    const redirectParams = document.createElement("input");
    redirectParams.type = "hidden";
    redirectParams.name = "redirect_params";
    redirectParams.value = `${process.env.REACT_APP_BROKER_CONNECT_REDIRECT_URL.replace(
      "https://",
      ""
    )}=true`;

    form.appendChild(apiKeyInput);
    form.appendChild(dataInput);
    form.appendChild(redirectParams);

    document.body.appendChild(form);

    const currentISTDateTime = new Date();
    try {
      // Update the database with the current IST date-time
      await axios.put(
        `${server.server.baseUrl}api/zerodha/update-trade-reco`,
        {
          stockDetails: stockDetails,
          leaving_datetime: currentISTDateTime,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      // Submit the form after the database is updated
      form.submit();
    } catch (error) {
      console.error("Failed to update trade recommendation:", error);
    }
  };

  const todaysDate = new Date().toLocaleDateString();

  const filterTodaysRejectedTrades = rejectedTrades
    .map((trade) => ({
      ...trade,
      date: new Date(trade.date).toLocaleDateString(),
    }))
    .filter(
      (trade) =>
        trade.date === todaysDate &&
        (trade.trade_place_status === "rejected" ||
          trade.trade_place_status === "REJECTED") &&
        (trade.rebalance_status === undefined ||
          trade.rebalance_status === null)
    );

  const filterTodaysExecutedTrades = executedTrades
    .map((trade) => ({
      ...trade,
      date: new Date(trade.purchaseDate || trade.exitDate).toLocaleDateString(),
    }))
    .filter((trade) => trade.date === todaysDate);

  const [funds, setFunds] = useState(null);
  const getAllFunds = async () => {
    const fetchedFunds = await fetchFunds(
      broker,
      clientCode,
      apiKey,
      jwtToken,
      secretKey
    );
    if (fetchedFunds) {
      setFunds(fetchedFunds);
    } else {
      console.error("Failed to fetch funds.");
    }
  };

  useEffect(() => {
    // Call the function when the component mounts or when relevant props change
    if (broker && (clientCode || jwtToken)) {
      getAllFunds();
    }
  }, [broker, clientCode, apiKey, jwtToken, secretKey]);

  const [storedTradeType, setStoredTradeType] = useState(() => {
    const savedTradeType = localStorage.getItem("storedTradeType");
    return savedTradeType
      ? JSON.parse(savedTradeType)
      : { allSell: false, allBuy: false, isMixed: false };
  });

  const [tradeType, setTradeType] = useState({
    allSell: false,
    allBuy: false,
    isMixed: false,
  });

  const updateTradeType = (newTradeType) => {
    setTradeType(newTradeType);
    setStoredTradeType(newTradeType);
    localStorage.setItem("storedTradeType", JSON.stringify(newTradeType));
  };

  useEffect(() => {
    if (types.length > 0) {
      const hasSell = types.some((type) => type === "SELL");
      const hasBuy = types.some((type) => type === "BUY");
      const allSell = hasSell && !hasBuy;
      const allBuy = hasBuy && !hasSell;
      const isMixed = hasSell && hasBuy;

      const newTradeType = {
        allSell: allSell,
        allBuy: allBuy,
        isMixed: isMixed,
      };

      updateTradeType(newTradeType);
    } else {
      updateTradeType(storedTradeType);
    }
  }, [types]);

  const hasBuy = types.every((type) => type === "BUY");
  const hasSell = types.every((type) => type === "SELL");
  const allSell = hasSell && !hasBuy;
  const allBuy = hasBuy && !hasSell;
  const isMixed = hasSell && hasBuy;

  const handleTrade = () => {
    getAllFunds();
    const isFundsEmpty =
      funds?.status === 1 || funds?.status === 2 || funds === null;

    const isMarketHours = IsMarketHours();

    // if (!isMarketHours) {
    //   toast.error("Orders cannot be placed outside Market hours.", {
    //     duration: 3000,
    //     style: {
    //       background: "white",
    //       color: "#1e293b",
    //       maxWidth: "500px",
    //       fontWeight: 600,
    //       fontSize: "13px",
    //       padding: "10px 20px",
    //     },
    //     iconTheme: {
    //       primary: "#e43d3d",
    //       secondary: "#FFFAEE",
    //     },
    //   });
    //   return;
    // }

    if (broker === "Zerodha") {
      if (isFundsEmpty) {
        setOpenTokenExpireModel(true);
        return; // Exit as funds are empty
      } else if (brokerStatus === null) {
        setBrokerModel(true);
        return;
      }
      // If not funds empty, proceed with Zerodha-specific logic
      if (allBuy) {
        setOpenZerodhaModel(true);
      } else if (tradeType?.allSell || tradeType?.isMixed) {
        // Handle DDPI modal logic for SELL or mixed trades
        if (
          !userDetails?.ddpi_status ||
          userDetails?.ddpi_status === "empty" ||
          (!["consent", "physical"].includes(userDetails?.ddpi_status) &&
            !userDetails?.is_authorized_for_sell)
        ) {
          setShowDdpiModal(true); // Show DDPI Modal for invalid or missing status
          setOpenZerodhaModel(false); // Ensure Zerodha modal is closed
        } else {
          setShowDdpiModal(false); // Hide DDPI Modal
          setOpenZerodhaModel(true); // Proceed with Zerodha modal
        }
      }
    } else if (broker === "Angel One") {
      if (isFundsEmpty) {
        setOpenTokenExpireModel(true);
        return; // Exit as funds are empty
      } else if (brokerStatus === null) {
        setBrokerModel(true);
        return;
      } else if (edisStatus && edisStatus.edis === true) {
        setOpenReviewTrade(true); // Open review trade modal for all cases
      } else if (
        edisStatus &&
        edisStatus.edis === false &&
        (allSell || isMixed) &&
        !userDetails?.is_authorized_for_sell
      ) {
        setShowAngleOneTpinModel(true); // Show TPIN modal for invalid edis
      } else {
        setOpenReviewTrade(true);
      }
    } else if (broker === "Dhan") {
      // Check if DDPI status is null or not present
      if (isFundsEmpty) {
        setOpenTokenExpireModel(true);
        return; // Exit as funds are empty
      } else if (brokerStatus === null) {
        setBrokerModel(true);
        return;
      } else if (dhanEdisStatus && dhanEdisStatus.data[0].edis === true) {
        setOpenReviewTrade(true); // Open review trade modal for all cases
      } else if ((allSell || isMixed) && !userDetails?.is_authorized_for_sell) {
        setShowDhanTpinModel(true);
      } else {
        setOpenReviewTrade(true);
      }
    } else if (broker === "Fyers") {
      if (isFundsEmpty) {
        setOpenTokenExpireModel(true);
        return; // Exit as funds are empty
      } else if (brokerStatus === null) {
        setBrokerModel(true);
        return;
      }
      // setShowFyersTpinModal(true);
    } else {
      if (isFundsEmpty) {
        setOpenTokenExpireModel(true);
        return; // Exit as funds are empty
      } else if (brokerStatus === null) {
        setBrokerModel(true);
        return;
      } else {
        setOpenReviewTrade(true);
      }
      // Fallback for brokers not mentioned above
    }
  };

  // place order
  const placeOrder = createPlaceOrderFunction({
    setLoading,
    userDetails,
    getAllTrades,
    getAllTradesUpdate,
    getCartAllStocks,
    stockDetails,
    broker,
    clientCode,
    apiKey,
    secretKey,
    jwtToken,
    viewToken,
    sid,
    serverId,
    server,
    setShowOtherBrokerModel,
    setOpenReviewTrade,
    setOrderPlacementResponse,
    setOpenSucessModal,
    updatePortfolioData,
    isReturningFromOtherBrokerModal,
    setIsReturningFromOtherBrokerModal,
  });

  const handleCloseTradeSuccessModal = () => {
    if (broker === "Zerodha") {
      setOpenSucessModal(false);
      window.history.pushState({}, "", "/stock-recommendation");
    } else if (
      userDetails?.user_broker === "Angel One" &&
      userDetails?.ddpi_enabled === true
    ) {
      setOpenSucessModal(false);
    } else {
      setOpenSucessModal(false);
    }
  };

  const handleOpenReviewModal = () => {
    setOpenReviewTrade(false);
    setSingleStockSelectState(false);
    getCartAllStocks();
  };

  const handleOpenZerodhaModal = () => {
    setOpenZerodhaModel(false);
    setSingleStockSelectState(false);
    getCartAllStocks();
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
          <div className="w-10 h-10 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
        </div>
      ) : (
        <div className="flex flex-col relative  w-full max-h-[calc(100vh-60px)] md:min-h-screen  bg-[#f9f9f9]">
          <div className="flex flex-col  w-full min-h-screen  bg-[#f9f9f9] ">
            <Toaster position="top-center" reverseOrder={true} />
            <div className={`px-4 lg:px-[50px]  flex flex-row  justify-between border-b ${appConfig.colors.headerBlueTheme} border-white  h-[100px] lg:h-[110px] `}>
              <div className="flex flex-row space-x-6 lg:space-x-8 mt-10">
                <div
                  className={
                    selectedRecommendation === "orders-placed"
                      ? ` ${style.selected}`
                      : ` ${style.unselected}`
                  }
                  onClick={() => {
                    setSelectedRecommendation("orders-placed");
                    getAllTrades();
                  }}
                >
                  Orders Placed
                  {filterTodaysExecutedTrades &&
                    filterTodaysExecutedTrades.length > 0 && (
                      <span
                        className={`ml-2 pt-[1px] px-[4px] flex items-center text-[11px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[28px] h-5 rounded-full ${
                          selectedRecommendation !== "orders-placed"
                            ? "bg-[#000000]/30"
                            : filterTodaysExecutedTrades.length === 0
                            ? "bg-[#000000]/30"
                            : "bg-[#E43D3D]"
                        }`}
                      >
                        {filterTodaysExecutedTrades
                          ? filterTodaysExecutedTrades.length
                          : ""}
                      </span>
                    )}
                </div>
                <div
                  className={
                    selectedRecommendation === "rejected-orders"
                      ? ` ${style.selected}`
                      : ` ${style.unselected}`
                  }
                  onClick={() => {
                    setSelectedRecommendation("rejected-orders");
                    getAllTrades();
                    getAllFunds();
                  }}
                >
                  Rejected Orders
                  {filterTodaysRejectedTrades &&
                    filterTodaysRejectedTrades.length > 0 && (
                      <span
                        className={` ml-2 pt-[1px] px-[4px] flex items-center text-[11px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[28px] h-5 rounded-full ${
                          selectedRecommendation !== "rejected-orders"
                            ? "bg-[#000000]/30"
                            : filterTodaysRejectedTrades.length === 0
                            ? "bg-[#000000]/30"
                            : "bg-[#E43D3D]"
                        }`}
                      >
                        {filterTodaysRejectedTrades
                          ? filterTodaysRejectedTrades.length
                          : ""}
                      </span>
                    )}
                </div>
              </div>

              {selectedRecommendation === "rejected-orders" ? (
                <div className="hidden md:flex flex-row items-center py-3">
                  {rejectedTrades.length !== 0 && (
                    <>
                      {rejectedTrades.length === stockDetails.length ? (
                        <div
                          onClick={handleRemoveAllSelectedStocks}
                          className=" text-xs md:text-lg py-2 hidden md:block md:px-6 text-white font-medium  border-[1px] border-white/20 rounded-md cursor-pointer"
                        >
                          <span className="">Deselect All</span>
                        </div>
                      ) : (
                        <div
                          onClick={handleSelectAllStocks}
                          className="text-xs md:text-lg py-2 hidden md:block md:px-6 text-white font-medium  border-[1px] border-white/20 rounded-md cursor-pointer"
                        >
                          <span className="">Select All</span>
                        </div>
                      )}
                    </>
                  )}

                  <button
                    className="w-[80px] lg:w-[150px]   disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-2 py-1.5 bg-black text-[14px] lg:ml-6 lg:text-lg lg:px-4 lg:py-2 text-white font-medium rounded-md cursor-pointer"
                    onClick={handleTrade}
                    disabled={stockDetails?.length === 0}
                  >
                    <div className="flex flex-row justify-center items-center space-x-2">
                      {" "}
                      <TrendingUp className=" w-4 h-4 lg:h-5 lg:w-5" />
                      <span>Trade</span>
                      {singleStockSelectState === true ? (
                        <span className="font-medium mt-0.5 md:mt-1">
                          (
                          {(stockDetails?.length || 0) +
                            (selectedLength?.length || 0)}
                          )
                        </span>
                      ) : (
                        stockDetails?.length > 0 && (
                          <span className="font-medium mt-0.5 md:mt-1">
                            ({stockDetails?.length})
                          </span>
                        )
                      )}
                    </div>
                  </button>
                </div>
              ) : null}
            </div>

            {selectedRecommendation === "orders-placed" && (
              <PlaceOrders
                executedTrades={executedTrades}
                userEmail={userEmail}
              />
            )}

            {selectedRecommendation === "rejected-orders" && (
              <>
                <div className="h-[calc(150vh-220px)] overflow-auto w-full">
                  {rejectedTrades?.length > 0 ? (
                    <AnimatePresence mode="wait">
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="w-full grid gap-y-6 py-4 px-2 pb-[40px] md:pb-0 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-4 lg:pt-6 lg:pb-12 lg:px-[50px]"
                      >
                        {rejectedTrades.map((ele, i) => (
                          <NewStockCard
                            key={i}
                            id={ele._id}
                            isSelected={stockDetails.some(
                              (stock) =>
                                stock.tradingSymbol === ele.Symbol &&
                                stock.tradeId === ele.tradeId
                            )}
                            symbol={ele.Symbol}
                            Price={ele.Price}
                            date={ele.date}
                            Quantity={ele.Quantity}
                            action={ele.Type}
                            orderType={ele.OrderType}
                            exchange={ele.Exchange}
                            segment={ele.Segment}
                            cmp={ele.CMP}
                            tradeId={ele.tradeId}
                            rationale={
                              ele?.rationale ? ele.rationale : defaultRationale
                            }
                            recommendationStock={rejectedTrades}
                            setRecommendationStock={setRejectedTrades}
                            setStockDetails={setStockDetails}
                            stockDetails={stockDetails}
                            setOpenReviewTrade={setOpenReviewTrade}
                            setOpenIgnoreTradeModel={setOpenIgnoreTradeModel}
                            setStockIgnoreId={setStockIgnoreId}
                            getLTPForSymbol={getLTPForSymbol}
                            setOpenTokenExpireModel={setOpenTokenExpireModel}
                            setOpenZerodhaModel={setOpenZerodhaModel}
                            todayDate={todayDate}
                            expireTokenDate={expireTokenDate}
                            brokerStatus={brokerStatus}
                            setBrokerModel={setBrokerModel}
                            funds={funds?.data?.availablecash}
                            advisedRangeLower={ele.Advised_Range_Lower}
                            advisedRangeHigher={ele.Advised_Range_Higher}
                            setSingleStockSelectState={
                              setSingleStockSelectState
                            }
                            getCartAllStocks={getCartAllStocks}
                            getAllFunds={getAllFunds}
                            broker={broker}
                            edisStatus={edisStatus}
                            setShowAngleOneTpinModel={setShowAngleOneTpinModel}
                            dhanEdisStatus={dhanEdisStatus}
                            setShowDhanTpinModel={setShowDhanTpinModel}
                            setShowDdpiModal={setShowDdpiModal}
                            setTradeType={setTradeType}
                            onOpenDhanTpinModal={handleOpenDhanTpinModal}
                            userDetails={userDetails}
                          />
                        ))}
                      </motion.div>
                    </AnimatePresence>
                  ) : (
                    <div className="flex flex-col space-y-4 lg:space-y-6 lg:pt-[20px] items-center justify-center h-[calc(100vh-240px)] lg:h-full">
                      <div className="flex items-center justify-center w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70 ">
                        <CandlestickChartIcon className="w-[50px] h-[50px] lg:w-[60px] lg:h-[60px]" />
                      </div>
                      <div className="flex flex-col space-y-3 lg:space-y-3 items-center">
                        <div className="text-balck text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
                          No Rejected Trades
                        </div>
                        <div className="text-center text-[14px] lg:text-[18px] text-[#000000]/60 lg:leading-[30px] font-normal  px-[10px] lg:px-[60px]">
                          No rejected trades from the last 7 days
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            {selectedRecommendation === "rejected-orders" &&
              rejectedTrades.length > 0 && (
                <div className="absolute  bottom-0 bg-[#f9f9f9] shadow-[rgba(0,_0,_0,_0.4)_0px_30px_90px] border-[1px] border-[#000000]/10 flex flex-row items-center justify-between  h-[65px] px-4 space-x-4 w-full md:hidden">
                  {rejectedTrades.length === stockDetails.length ? (
                    <div
                      onClick={handleRemoveAllSelectedStocks}
                      className=" w-full flex items-center justify-center  text-[15px] py-3 px-2   text-[#000000] font-medium  border-[1px] border-[#000000]/20 rounded-md cursor-pointer"
                    >
                      <span className="">Deselect All</span>
                    </div>
                  ) : (
                    <div className=" w-full flex items-center justify-center text-[15px] py-3 px-2   text-[#000000] font-medium  border-[1px] border-[#000000]/20 rounded-md cursor-pointer">
                      <span className="">Select All</span>
                    </div>
                  )}
                  <button
                    className="w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-2 py-3 bg-black text-[15px]  text-white font-medium rounded-md cursor-pointer"
                    onClick={handleTrade}
                    disabled={stockDetails?.length === 0}
                  >
                    <div className="flex flex-row justify-center items-center space-x-2">
                      {" "}
                      <TrendingUp className=" w-4 h-4 lg:h-5 lg:w-5" />
                      <span>Trade</span>
                      {singleStockSelectState === true ? (
                        <span className="font-medium mt-0.5 md:mt-1">
                          (
                          {(stockDetails?.length || 0) +
                            (selectedLength?.length || 0)}
                          )
                        </span>
                      ) : (
                        stockDetails?.length > 0 && (
                          <span className="font-medium mt-0.5 md:mt-1">
                            ({stockDetails?.length})
                          </span>
                        )
                      )}
                    </div>
                  </button>
                </div>
              )}

            {brokerModel === true ? (
              <ConnectBroker
                uid={userDetails && userDetails._id}
                userDetails={userDetails && userDetails}
                setBrokerStatus={setBrokerStatus}
                setUpdateUserDetails={setUpdateUserDetails}
                setBrokerModel={setBrokerModel}
                getUserDetails={getUserDetails}
                broker={broker}
                setBroker={setBroker}
              />
            ) : null}

            {updateUserDetails === true ? (
              <UpdateUserDeatils
                setUpdateUserDetails={setUpdateUserDetails}
                userEmail={userDetails?.email}
                advisorName={process.env.REACT_APP_ADVISOR_SPECIFIC_TAG}
                userDetails={userDetails}
              />
            ) : null}

            {/* IIFL Login  Modal with Mobile Drawer    */}
            {openTokenExpireModel === true ? (
              <TokenExpireBrokarModal
                openTokenExpireModel={openTokenExpireModel}
                setOpenTokenExpireModel={setOpenTokenExpireModel}
                userId={userId && userId}
                apiKey={apiKey}
                secretKey={secretKey}
                checkValidApiAnSecret={checkValidApiAnSecret}
                clientCode={clientCode}
                panNumber={panNumber}
                mobileNumber={mobileNumber}
                broker={broker}
                getUserDetails={getUserDetails}
              />
            ) : null}
            {/* Ignore Trade Model with Mobile Drawer */}

            {openIgnoreTradeModel === true ? (
              <IgnoreTradeModal
                openIgnoreTradeModel={openIgnoreTradeModel}
                setOpenIgnoreTradeModel={setOpenIgnoreTradeModel}
                handleIgnoredTrades={handleIgnoredTrades}
                stockIgnoreId={stockIgnoreId}
                ignoreText={ignoreText}
                setIgnoreText={setIgnoreText}
                ignoreLoading={ignoreLoading}
                style={style}
              />
            ) : null}

            {stockDetails.length !== 0 && openReviewTrade === true ? (
              <ReviewTradeModel
                calculateTotalAmount={calculateTotalAmount}
                getLTPForSymbol={getLTPForSymbol}
                stockDetails={stockDetails}
                setStockDetails={setStockDetails}
                setOpenReviewTrade={handleOpenReviewModal}
                placeOrder={placeOrder}
                loading={loading}
                funds={funds?.data?.availablecash}
                openReviewTrade={openReviewTrade}
                getCartAllStocks={getCartAllStocks}
              />
            ) : stockDetails.length !== 0 &&
              broker === "Zerodha" &&
              openZerodhaModel === true ? (
              <ZerodhaReviewModal
                getLTPForSymbol={getLTPForSymbol}
                stockDetails={stockDetails}
                setStockDetails={setStockDetails}
                calculateTotalAmount={calculateTotalAmount}
                funds={funds?.data?.availablecash}
                setOpenZerodhaModel={handleOpenZerodhaModal}
                handleZerodhaRedirect={handleZerodhaRedirect}
                openZerodhaModel={openZerodhaModel}
                getCartAllStocks={getCartAllStocks}
              />
            ) : null}

            {openSuccessModal && (
              <RecommendationSuccessModal
                setOpenSucessModal={handleCloseTradeSuccessModal}
                orderPlacementResponse={orderPlacementResponse}
                setStockDetails={setStockDetails}
                openSuccessModal={openSuccessModal}
                userDetails={userDetails}
                tradeType={tradeType}
              />
            )}

            {showDdpiModal && (
              <DdpiModal
                isOpen={showDdpiModal}
                setIsOpen={handleCloseDdpiModal}
                proceedWithTpin={handleProceedWithTpin}
                userDetails={userDetails && userDetails}
                setOpenReviewTrade={setOpenReviewTrade}
                getUserDetails={getUserDetails}
              />
            )}
            {showAngleOneTpinModel && (
              <AngleOneTpinModal
                isOpen={showAngleOneTpinModel}
                setIsOpen={setShowAngleOneTpinModel}
                userDetails={userDetails}
                edisStatus={edisStatus}
                tradingSymbol={stockDetails.map((stock) => stock.tradingSymbol)}
              />
            )}

            {showDhanTpinModel && (
              <DhanTpinModal
                isOpen={showDhanTpinModel}
                setIsOpen={setShowDhanTpinModel}
                userDetails={userDetails}
                dhanEdisStatus={dhanEdisStatus}
                singleStockTypeAndSymbol={singleStockTypeAndSymbol}
              />
            )}

            {showOtherBrokerModel && (
              <OtherBrokerModel
                userDetails={userDetails}
                onContinue={() => {
                  setIsReturningFromOtherBrokerModal(true);
                  setShowOtherBrokerModel(false);
                }}
                setShowOtherBrokerModel={setShowOtherBrokerModel}
                setOpenReviewTrade={setOpenReviewTrade}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OrderBookDetails;
