import React, { useState, useEffect } from "react";
import EquityAdvice from "./EquityAdvice/EquityAdvice";
import { Toaster } from "react-hot-toast";
import DerivativeAdvice from "./DerivativeAdvice/DerivativeAdvice";
import BasketAdvice from "./DerivativeAdvice/BasketAdvice";
import server from "../../../utils/serverConfig";

import { encryptApiKey } from "../../../utils/cryptoUtils";

const PreviousAdviceLogs = () => {
  const [activeMainTab, setActiveMainTab] = useState("equity");
  const [activeDerivativeTab, setActiveDerivativeTab] =
    useState("derivative-advice");

  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [errorMessage, setErrorMessage] = useState(null);
  const [combinedAdviceData, setCombinedAdviceData] = useState([]);
  const [emailData, setEmailData] = useState([]);

  const [fnocombinedAdviceData, setFNOCombinedAdviceData] = useState([]);
  const [fnoemailData, setFNOEmailData] = useState([]);

  const [tabData, setTabData] = useState({
    currentPage: 1,
    totalPages: 1,
    limit: 20,
  });

  const [fnoTabData, setFNOTabData] = useState({
    currentPage: 1,
    totalPages: 1,
    limit: 20,
  });

  const fetchAdviceData = async (pageNumber = 1, limit = 20) => {
    try {
      setIsLoading(true); // Start loading
      const response = await fetch(
        `${server.ccxtServer.baseUrl}comms/reco/payload/advisor-name/${process.env.REACT_APP_ADVISOR_SPECIFIC_TAG}/${pageNumber}/${limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );
      const data = await response.json();
      const advices = data.reco || [];
      const allEmails = data?.recoEmails || [];
      const groupedAdvices = advices.reduce((acc, advice) => {
        if (!acc[advice.advice_reco_id]) {
          acc[advice.advice_reco_id] = [];
        }
        acc[advice.advice_reco_id].push(advice);
        return acc;
      }, {});

      const convertedData = Object.keys(groupedAdvices)
        .map((id) => {
          const adviceArray = groupedAdvices[id];
          const latestDate = adviceArray.reduce((maxDate, advice) => {
            const adviceDate = new Date(advice.date);
            return adviceDate > maxDate ? adviceDate : maxDate;
          }, new Date(0));

          return {
            id,
            advices: adviceArray,
            date: latestDate.toUTCString(),
          };
        })
        .sort((a, b) => new Date(b.date) - new Date(a.date));
      setCombinedAdviceData(convertedData);
      setEmailData(allEmails);
      setTabData({
        currentPage: data?.pagination?.currentPage || pageNumber,
        totalPages: data?.pagination?.totalPages || 1,
        limit: data?.pagination?.rowsPerPage,
      });
    } catch (error) {
      console.error("Error fetching advice data:", error);
      // setErrorMessage("Failed to fetch advice data. Please try again later.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  const fetchFNOAdviceData = async (pageNumber = 1, limit = 20) => {
    try {
      setIsLoading(true); // Start loading
      const response = await fetch(
        `${server.ccxtServer.baseUrl}comms/reco/payload/advisor-name/${process.env.REACT_APP_ADVISOR_SPECIFIC_TAG}/${pageNumber}/${limit}/fno`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );
      const data = await response.json();
      const advices = data.reco || [];
      const allEmails = data?.recoEmails || [];
      const groupedAdvices = advices.reduce((acc, advice) => {
        if (!acc[advice.advice_reco_id]) {
          acc[advice.advice_reco_id] = [];
        }
        acc[advice.advice_reco_id].push(advice);
        return acc;
      }, {});

      const convertedData = Object.keys(groupedAdvices)
        .map((id) => {
          const adviceArray = groupedAdvices[id];
          const latestDate = adviceArray.reduce((maxDate, advice) => {
            const adviceDate = new Date(advice.date);
            return adviceDate > maxDate ? adviceDate : maxDate;
          }, new Date(0));

          return {
            id,
            advices: adviceArray,
            date: latestDate.toUTCString(),
          };
        })
        .sort((a, b) => new Date(b.date) - new Date(a.date));
      setFNOCombinedAdviceData(convertedData);
      setFNOEmailData(allEmails);
      setFNOTabData({
        currentPage: data?.pagination?.currentPage || pageNumber,
        totalPages: data?.pagination?.totalPages || 1,
        limit: data?.pagination?.rowsPerPage,
      });
    } catch (error) {
      console.error("Error fetching advice data:", error);
      // setErrorMessage("Failed to fetch advice data. Please try again later.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchAdviceData();
    fetchFNOAdviceData();
  }, []);

  const getPageNumbers = () => {
    const pages = [];
    const { currentPage, totalPages } = tabData;
    const totalVisible = 10;

    if (totalPages <= totalVisible) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pages.push(1, "...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < totalPages) pages.push("...", totalPages);
    }

    return pages;
  };

  // Function to navigate to a different page
  const goToPage = (page) => {
    if (page > 0 && page <= tabData.totalPages) {
      fetchAdviceData(page, tabData.limit);
    }
  };

  // fonoData

  const getFNOPageNumbers = () => {
    const pages = [];
    const { currentPage, totalPages } = fnoTabData;
    const totalVisible = 10;

    if (totalPages <= totalVisible) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pages.push(1, "...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < totalPages) pages.push("...", totalPages);
    }

    return pages;
  };

  // Function to navigate to a different page
  const goToFNOPage = (page) => {
    if (page > 0 && page <= fnoTabData.totalPages) {
      fetchFNOAdviceData(page, fnoTabData.limit);
    }
  };

  const basketAdviceData = fnocombinedAdviceData?.filter((advice) =>
    advice.advices?.some((a) => a.basket === "true")
  );

  const [basketPage, setBasketPage] = useState(1);
  const itemsPerPage = 10; // Adjust as needed

  // Get total pages
  const totalBasketPages = Math.ceil(basketAdviceData.length / itemsPerPage);

  // Generate pagination numbers
  const getBasketPageNumbers = () => {
    const pages = [];
    const totalVisible = 10;

    if (totalBasketPages <= totalVisible) {
      for (let i = 1; i <= totalBasketPages; i++) {
        pages.push(i);
      }
    } else {
      const startPage = Math.max(1, basketPage - 2);
      const endPage = Math.min(totalBasketPages, basketPage + 2);

      if (startPage > 1) pages.push(1, "...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < totalBasketPages) pages.push("...", totalBasketPages);
    }

    return pages;
  };

  // Navigate to a different page
  const goToBasketPage = (page) => {
    if (page > 0 && page <= totalBasketPages) {
      setBasketPage(page);
    }
  };
  const renderContent = () => {
    switch (activeMainTab) {
      case "equity":
        return (
          <div className="flex-1 overflow-auto">
            <EquityAdvice
              combinedAdviceData={combinedAdviceData}
              emailData={emailData}
              fetchAdviceData={fetchAdviceData}
              isLoading={isLoading}
              errorMessage={errorMessage}
              setCombinedAdviceData={setCombinedAdviceData}
              exchange={["NSE", "BSE"]}
              getPageNumbers={getPageNumbers}
              goToPage={goToPage}
              tabData={tabData}
            />
          </div>
        );
      case "derivatives":
        return (
          <div className="flex-1 overflow-auto">
            <div className="flex border-b bg-white">
              <button
                className={`px-4 py-2 font-poppins ${
                  activeDerivativeTab === "derivative-advice"
                    ? "border-b-2 border-black text-black"
                    : "text-gray-500 hover:text-black"
                }`}
                onClick={() => setActiveDerivativeTab("derivative-advice")}
              >
                Derivative Advice
              </button>
              <button
                className={`px-4 py-2 font-poppins ${
                  activeDerivativeTab === "basket-advice"
                    ? "border-b-2 border-black text-black"
                    : "text-gray-500 hover:text-black"
                }`}
                onClick={() => setActiveDerivativeTab("basket-advice")}
              >
                Basket Advice
              </button>
            </div>
            <div className="p-4">
              {activeDerivativeTab === "derivative-advice" ? (
                <div className="flex-1 overflow-auto">
                  <DerivativeAdvice
                    combinedAdviceData={fnocombinedAdviceData}
                    emailData={fnoemailData}
                    fetchAdviceData={fetchFNOAdviceData}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    setCombinedAdviceData={setFNOCombinedAdviceData}
                    exchange={["NFO", "BFO"]}
                    getPageNumbers={getFNOPageNumbers}
                    goToPage={goToFNOPage}
                    tabData={fnoTabData}
                  />
                </div>
              ) : (
                <div className="flex-1 overflow-auto">
                  <BasketAdvice
                    combinedAdviceData={basketAdviceData}
                    emailData={fnoemailData}
                    fetchAdviceData={fetchFNOAdviceData}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    setCombinedAdviceData={setFNOCombinedAdviceData}
                    exchange={["NFO", "BFO"]}
                    getPageNumbers={getBasketPageNumbers}
                    goToPage={goToBasketPage}
                    tabData={fnoTabData}
                  />
                </div>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col h-full w-full">
      <Toaster position="top-center" reverseOrder={true} />
      <div className="bg-white border-b">
        <button
          className={`px-6 py-3 font-poppins ${
            activeMainTab === "equity"
              ? "border-b-2 border-black text-black"
              : "text-gray-500 hover:text-black"
          }`}
          onClick={() => setActiveMainTab("equity")}
        >
          Equity
        </button>
        <button
          className={`px-6 py-3 font-poppins ${
            activeMainTab === "derivatives"
              ? "border-b-2 border-black text-black"
              : "text-gray-500 hover:text-black"
          }`}
          onClick={() => setActiveMainTab("derivatives")}
        >
          Derivatives
        </button>
      </div>
      {renderContent()}
    </div>
  );
};

export default PreviousAdviceLogs;
